import {BaseModal} from "../../alertmodal/BaseModal";
import {useEffect, useRef, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import {Chronos} from "../../../chronos/Chronos";
import WindowUtil from "../../../util/WindowUtil";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import HTMLReactParser from "html-react-parser";

export const OrganisationPolicyAcceptanceActions = {
    SIGN_OUT : "orgpolicyaccept.sign_out"
};

export const OrganisationPolicyAcceptanceModal = (props) => {

    const {policies} = props;
    const {callback} = props;

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [forceDismiss, setForceDismiss] = useState(false);

    const callbackData = useRef();

    useEffect(() => {
        if (policies && policies.length > 0) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            callback.current = undefined;
            setForceDismiss(false);
        }
    }, [policies])

    function handleCallback(action, data) {
        if (callback) {
            if (data === undefined && callbackData.current !== undefined) {
                data = callbackData.current;
            }
            callback(action, data);
        }
    }

    function handlePolicyClick(policy) {
        if (policy.url !== null) {
            window.open(policy.url);
        } else if (policy.filePath !== null) {
            window.open(policy.filePath);
        } else if (policy.description !== null) {
            AlertModal.showModal(
                policy.title,
                HTMLReactParser(policy.description),
                [
                    AlertModal.button(
                        Rosetta.string("common.close"),
                        () => {
                            AlertModal.dismissModal();
                        }
                    )
                ]
            );
        }
    }

    function acceptPoliciesOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const organisationId = DataManager.getOrganisationId();
        const policyIds = policies.map((policy) => policy.id);

        const formData = new FormData();
        if (organisationId) {
            formData.append("organisationId", organisationId);
        }
        formData.append("organisationPolicyIds", JSON.stringify(policyIds));

        Axios.post(ENDPOINTS.organisation.acceptOrganisationPolicies, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    callbackData.current = resp.data.data;
                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("AOP1000C"));
            });
    }

    // RENDER

    if (!policies || policies.length === 0) return [];

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <h5>{Rosetta.string("organisation.policy_accept_title")}</h5>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-warning"}>
                        {Rosetta.string("organisation.policy_accept_notice")}
                    </div>
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ul className={"list-group"}>
                        {policies.map((policy) => (
                            <li className={"list-group-item clickable"} onClick={() => handlePolicyClick(policy)}>
                                <div><strong>{policy.title}</strong></div>
                                <div>{Rosetta.string("organisation.policy_accept_updated_on", { date : Chronos.withTimestampSeconds(policy.dateUpdated ? policy.dateUpdated : policy.dateCreated).format("dd/MM/yyyy")})}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className={"alert-modal-dialog-buttons"}>
                <button className={"alert-dialog-button success"} onClick={() => acceptPoliciesOverNetwork()}>{Rosetta.string("organisation.policy_accept_submit")}</button>
                <button className={"alert-dialog-button danger"} onClick={() => handleCallback(OrganisationPolicyAcceptanceActions.SIGN_OUT)}>{Rosetta.string("organisation.policy_accept_decline")}</button>
            </div>

        </BaseModal>
    )

}