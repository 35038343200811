import {Chronos} from "../chronos/Chronos";
import {TimeUtil} from "./TimeUtil";
import Rosetta from "../rosetta/Rosetta";
import {ImageUtil} from "./ImageUtil";
import IconGood from "../assets/images/icon_good.svg";
import IconImprove from "../assets/images/icon_improve.svg";
import IconError from "../assets/images/error.svg";
import HTMLReactParser from "html-react-parser";
import {CommonUtil} from "./CommonUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../network/API";
import parseUri from "parse-uri";
import {Navigator} from "./Navigator";

export const CaseRecordUtil = {
    actions : {
        CLINICAL_STANDARD_CLICK : "caseRecord.viewClinicalStandard",
        REFLECT_CLICK : "caseRecord.reflect",
        ACTION_CLICK : "caseRecord.actionClicked"
    },
    itemTypes : {
        FIELD : 1,
        RESULT : 2
    },
    itemOutcomeTypes : {
        POSITIVE: 1,
        NEGATIVE : 2,
        NEUTRAL : 3,
        INCOMPLETE : 4,
        ERROR : 5
    },
    filterTypes : {
        EQUALS_EXACTLY : 1,
        INCLUDES : 2,
        GREATER_THAN : 3,
        LESS_THAN : 4,
        DATE_BETWEEN : 5
    },
    _currentCaseRecords : [], // Hold Case Record here in memory. DO NOT PERSIST TO LOCAL STORAGE!
    findCaseRecordInCache : (caseRecordId) => {
        let out = null;

        caseRecordId = parseInt(caseRecordId);

        for (let i = 0; i < CaseRecordUtil._currentCaseRecords.length; i++) {
            let cCR = CaseRecordUtil._currentCaseRecords[i];
            if (parseInt(cCR.id) === caseRecordId) {
                out = cCR;
                break;
            }
        }

        return out;
    },
    removeCaseRecordFromCache : (caseRecordId) => {
        for (let i = 0; i < CaseRecordUtil._currentCaseRecords.length; i++) {
            let caseRecord = CaseRecordUtil._currentCaseRecords[i];
            if (parseInt(caseRecord.id) === caseRecordId) {
                CaseRecordUtil._currentCaseRecords.splice(i, 1);
                return true;
            }
        }
        return false;
    },
    putCaseRecordIntoCache : (caseRecord) => {
        // Remove any current Case Record of this ID from the cache
        CaseRecordUtil.removeCaseRecordFromCache(caseRecord.id);
        // Insert this new record.
        CaseRecordUtil._currentCaseRecords.push(caseRecord);

        return true;
    },
    clearCaseRecordCache : () => {
        CaseRecordUtil._currentCaseRecords = [];
    },
    getTimeDisplay : (caseRecord) => {
        return CaseRecordUtil.formatTimeDisplay(caseRecord.recordDate, "case_record.list_item_received");
    },
    formatTimeDisplay : (date, stringRes, dateFormat) => {
        if (!dateFormat) {
            dateFormat = "dd/MM/yy";
        }

        let timeAgo = Chronos.now().seconds() - date;
        if (timeAgo <= TimeUtil.DAY) {
            timeAgo = Rosetta.string("time.today");
        } else if (timeAgo <= (TimeUtil.DAY * 2)) {
            timeAgo = Rosetta.string("time.yesterday");
        } else {
            timeAgo = Rosetta.string("case_record.list_item_ago", {
                time : TimeUtil.prettyFormatTimeDuration(timeAgo, {
                    top : 1
                })
            });
        }

        return Rosetta.string(stringRes, {
            date: Chronos.withTimestampSeconds(date).format(dateFormat),
            timeAgo
        });
    },
    generateItemView : (item, callback) => {
        const ignoreCheckFields = [
            item.title,
            item.body,
            item.value
        ];

        for (let i = 0; i < ignoreCheckFields.length; i++) {
            let target = ignoreCheckFields[i];
            if (target) {
                target = target.trim();
                if (target === "@ignore") {
                    return [];
                }
            }
        }

        let classExtra = "";

        // Outcome Icon
        let itemIcon = [];
        if (parseInt(item.caseRecordSegmentSectionItemOutcomeTypeId) === CaseRecordUtil.itemOutcomeTypes.POSITIVE) {
            itemIcon = (
                <div className={"outcome-status badge-all-good"}>
                    <div className={"outcome-icon"} style={{ backgroundImage : ImageUtil.background(IconGood) }} />
                </div>
            );

            classExtra += " section-item-success";
        } else if (parseInt(item.caseRecordSegmentSectionItemOutcomeTypeId) === CaseRecordUtil.itemOutcomeTypes.NEGATIVE) {
            itemIcon = (
                <div className={"outcome-status badge-improve"}>
                    <div className={"outcome-icon"} style={{ backgroundImage : ImageUtil.background(IconImprove) }} />
                </div>
            );

            classExtra += " section-item-warning";
        } else if (parseInt(item.caseRecordSegmentSectionItemOutcomeTypeId) === CaseRecordUtil.itemOutcomeTypes.ERROR) {
            itemIcon = (
                <div className={"outcome-status"} style={{backgroundImage : ImageUtil.background(IconError)}}>
                    <div className={"outcome-icon"} />
                </div>
            )

            classExtra += " section-item-error";
        }

        // BUTTONS
        let buttons = [];

        if (item.actions && item.actions.trim() !== "") {
            try {
                let itemActions = JSON.parse(item.actions);
                if (itemActions) {
                    if (!Array.isArray(itemActions)) {
                        itemActions = [itemActions];
                    }

                    itemActions.forEach((action) => {
                        let actionUri = CommonUtil.getOrDefault(action, "action", null);

                        buttons.push((
                            <button className={"btn btn-primary mt-1 me-1"} onClick={() => { if (callback) callback(CaseRecordUtil.actions.ACTION_CLICK, actionUri) }}>
                                {CommonUtil.getOrDefault(action, "label", "#ERR!")}
                            </button>
                        ))
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }

        if (parseInt(item.caseRecordSegmentSectionItemTypeId) === CaseRecordUtil.itemTypes.RESULT) {
            buttons.push(
                <button className={"btn btn-primary mt-1"} onClick={() => { if (callback) callback(CaseRecordUtil.actions.REFLECT_CLICK, item) }}>{Rosetta.string("case_record.section_item_reflect")}</button>
            );
        }

        if (item.clinicalStandard && item.clinicalStandard.trim() !== "") {
            buttons.unshift(
                <button className={"btn btn-primary mt-1 me-1"} onClick={() => { if (callback) callback(CaseRecordUtil.actions.CLINICAL_STANDARD_CLICK, item)}}>{Rosetta.string("case_record.section_item_clinical_standards")}</button>
            );
        }

        if (buttons.length > 0) {
            buttons = (
                <div className={"text-end mt-2"}>
                    {buttons}
                </div>
            );
        }

        let subtitleElem = [];
        if (CommonUtil.getOrDefault(item, "text", "").trim() !== "") {
            subtitleElem = (
                <div>{HTMLReactParser(item.text)}</div>
            )
        }

        let itemValue = "";
        if (item.value && item.value !== "") {
            itemValue = HTMLReactParser(item.value)
        }

        return (
            <li className={"list-group-item section-item" + classExtra}>
                <span className={"number-tag"}>
                    <span className={"text"}>{item.number}</span>
                    {itemIcon}
                </span>
                <span className={"item-content"}>
                    <div className={"section-title"}>{item.title}</div>
                    {subtitleElem}
                    <div className={"section-value"}>{itemValue}</div>
                    {buttons}
                </span>
            </li>
        );
    },
    itemActions : {
        OPEN_URL : "itemAction.open_url",
        MESSAGE : "itemAction.message",
        "OPEN_DIALOG" : "itemAction.open_dialog"
    },
    itemDialogs : {
        ASA : 1,
        URGENCY : 2,
        CLINICAL_FRAILTY : 3
    },
    handleItemAction : (action, caseRecord, callback) => {
        const url = parseUri(action);

        console.log("CR: ", caseRecord);

        if (url.protocol === "qinotify") {
            if (url.host === "view-asa") {
                if (callback) {
                    callback(CaseRecordUtil.itemActions.OPEN_DIALOG, CaseRecordUtil.itemDialogs.ASA);
                }
                return;
            } else if (url.host === "view-urgency" || url.host === "view-ncepod-urgency") {
                if (callback) {
                    callback(CaseRecordUtil.itemActions.OPEN_DIALOG, CaseRecordUtil.itemDialogs.URGENCY);
                }
                return;
            } else if (url.host === "view-clinical-frailty-scale") {
                if (callback) {
                    callback(CaseRecordUtil.itemActions.OPEN_DIALOG, CaseRecordUtil.itemDialogs.CLINICAL_FRAILTY);
                }
                return;
            } else if (
                url.host === "view-all-looking-good"
                || url.host === "view-opportunities-to-improve"
                || url.host === "view-unable-to-evaluate"
                || url.host === "view-non-benchmarked"
                || url.host === "view-error-list"
            ) {
                let outcomeTypeId = CaseRecordUtil.itemOutcomeTypes.INCOMPLETE;
                if (url.host === "view-all-looking-good") {
                    outcomeTypeId = CaseRecordUtil.itemOutcomeTypes.POSITIVE
                } else if (url.host === "view-opportunities-to-improve") {
                    outcomeTypeId = CaseRecordUtil.itemOutcomeTypes.NEGATIVE;
                } else if (url.host === "view-error-list") {
                    outcomeTypeId = CaseRecordUtil.itemOutcomeTypes.ERROR;
                }

                Navigator.navigate("/caseRecordOverview/" + caseRecord.departmentId + "/" + caseRecord.id + "/" + outcomeTypeId);

                return;
            }

            const formData = new FormData();
            formData.append("caseRecordId", caseRecord.id);
            formData.append("action", url.host);

            Axios.post(ENDPOINTS.caseRecord.handleAction, formData)
                .then((r) => {
                    const resp = API.parse(r);
                    if (resp.success) {
                        if (resp.data.type === "url") {
                            if (callback) {
                                callback(CaseRecordUtil.itemActions.OPEN_URL, resp.data.data);
                            }
                        } else {
                            // Action not supported
                        }
                    } else {
                        if (callback) {
                            callback(CaseRecordUtil.itemActions.MESSAGE, API.formatError(resp));
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);

                    if (callback) {
                        callback(CaseRecordUtil.itemActions.MESSAGE, API.defaultError("CRA1000C"));
                    }
                })
        } else {
            if (callback) {
                callback(CaseRecordUtil.itemActions.OPEN_URL, action);
            }
        }
    }
}