import {useEffect, useState} from "react";
import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {QISwitch} from "../../switch/QISwitch";
import WindowUtil from "../../../util/WindowUtil";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";

import "./ReflectionSettingsModal.css";

export const ReflectionSettingsModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    const [preferences, setPreferences] = useState([]);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            fetchPreferencesOverNetwork();
        } else {
            WindowUtil.unlockBodyScroll();

            setPreferences([]);
            setForceDismiss(false);
            setNetworkInFlight(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function handlePreferenceChange(preference, value) {
        const prefs = [...preferences];

        preferences.forEach((pref) => {
            if (pref.caseRecordSegmentId === preference.caseRecordSegmentId) {
                pref.preference = value ? 1 : 0;
            }
        })

        setPreferences(prefs);
    }

    function fetchPreferencesOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const user = DataManager.getUser();

        const formData = new FormData();
        if (user.departmentId) {
            formData.append("departmentId", user.departmentId);
        }

        Axios.post(ENDPOINTS.reflection.getReflectionSuggestionPreferences, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPreferences(resp.data.preferences);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    API.defaultError("PSS1000C"),
                    Toast.ERROR,
                    Toast.LONG
                );
            })
    }

    function submitPreferencesOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const data = {};

        preferences.forEach((pref) => {
            data[pref.caseRecordSegmentId] = pref.preference;
        });

        const user = DataManager.getUser();

        const formData = new FormData();
        if (user.departmentId) {
            formData.append("departmentId", user.departmentId);
        }
        formData.append("data", JSON.stringify(data));

        Axios.post(ENDPOINTS.reflection.updateReflectionSuggestionPreferences, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPreferences(resp.data.preferences);
                    setForceDismiss(true);

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("settings.reflection_settings_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    API.defaultError("PSS1000C"),
                    Toast.ERROR,
                    Toast.LONG
                );
            })
    }

    // RENDER

    if (!shown) return [];

    let mainContent = [];
    let submitButton = [];

    if (!networkInFlight) {
        mainContent = preferences.map((preference) => {
            return (
                <li className={"list-group-item setting-option"}>
                    <div className={"preference-switch"}>
                        <QISwitch value={parseInt(preference.preference) === 1} onChange={(value) => handlePreferenceChange(preference, value)} />
                    </div>
                    <div className={"preference-content"}>
                        <div className={"flex-fill"}>{preference.segmentName}</div>
                    </div>
                </li>
            )
        });

        submitButton = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <button className={"btn btn-primary full-width"} onClick={() => submitPreferencesOverNetwork()}>{Rosetta.string("common.save")}</button>
                </div>
            </div>
        )
    } else {
        for (let i = 0; i < 5; i++) {
            mainContent.push(
                <li className={"list-group-item"}>
                    <PlaceholderText />
                </li>
            )
        }
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("settings.reflection_settings")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    {Rosetta.string("settings.reflection_settings_explain")}
                </div>
            </div>

            <div className={"row mt-4 reflection-settings-modal"}>
                <div className={"col-12"}>
                    <ul className={"list-group"}>
                        {mainContent}
                    </ul>
                </div>
            </div>

            {submitButton}

        </Offcanvas>
    )

}