import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {MainComponent} from "./components/MainComponent";
import {FirebaseUtil} from "./util/FirebaseUtil";

function App() {
  // Initialise Firebase for FCM now
  FirebaseUtil.init();

  return (
    <MainComponent />
  );
}

export default App;
