import {DataManager} from "../data/DataManager";

export const AppConfig = {
    _appConfig : null,
    keys : {
        criticalSafetyScaleUrl : "critical_safety_scale_url",
        asaClassificationUrl : "asa_classification_url",
        nelaUrgencyUrl : "nela_urgency_url"
    },
    get : (key, defaultValue) => {
        if (AppConfig._appConfig == null) {
            AppConfig._appConfig = DataManager.getAppConfig();
        }
        console.log(AppConfig._appConfig);

        if (AppConfig._appConfig) {
            if (AppConfig._appConfig.hasOwnProperty(key)) {
                return AppConfig._appConfig[key];
            }
        }
        return defaultValue;
    },
    set : (appConfig) => {
        AppConfig._appConfig = appConfig;
        DataManager.setAppConfig(appConfig);
    }
}