import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import Axios from "axios";
import {API, ENDPOINTS} from "../network/API";
import {DataManager} from "../data/DataManager";
import Rosetta from "../rosetta/Rosetta";
import {Toast} from "../components/toast/TokyoToaster";
import {Navigator} from "./Navigator";
import {CaseRecordUtil} from "./CaseRecordUtil";

export const FirebaseUtil = {
    _app : null,
    _messaging : null,
    _uploadInFlight : false,
    init : () => {
        const firebaseConfig = FirebaseUtil.getConfig();
        console.log(firebaseConfig);
        try {
            FirebaseUtil._app = initializeApp(firebaseConfig);
            FirebaseUtil._messaging = getMessaging(FirebaseUtil._app);
            onMessage(FirebaseUtil._messaging, FirebaseUtil.onMessageReceived);
        } catch (e) {
            console.log(e);
        }
    },
    getConfig : () => {
        return {
            apiKey: process.env.REACT_APP_FIREBASE_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        };
    },
    getFCMToken : (callback) => {
        if (!FirebaseUtil._messaging) {
            return;
        }

        Notification.requestPermission()
            .then((permission) => {
                if (permission === "granted") {
                    getToken(FirebaseUtil._messaging, { vapidKey : process.env.REACT_APP_FIREBASE_WEB_PUSH_KEY})
                        .then((token) => {
                            if (token) {
                                FirebaseUtil.uploadFCMToken(token);

                                if (callback) {
                                    callback(token);
                                }
                            } else {
                                console.log("Failed to obtain token. Permission missing?");
                                if (callback) {
                                    callback(false);
                                }
                            }
                        })
                        .catch((e) => {
                            console.log("FAILED TO RETRIEVE FCM TOKEN");
                            console.log(e);
                            if (callback) {
                                callback(false);
                            }
                        });
                }
            })
            .catch((e) => {
                console.log(e);
                console.log("Failed to obtain Notification permission.");
            });
    },
    onMessageReceived : (payload) => {
        console.log("FCM Message Received");
        console.log(payload);

        let notificationTitle = Rosetta.string("common.notification_title");
        let notificationMessage = "";
        let notificationActions = undefined;
        if (payload.notification) {
            notificationTitle = payload.notification.title;
            notificationMessage = payload.notification.body;
        }

        if (payload.data) {
            const type = parseInt(payload.data.type);

            if (payload.data.title) {
                notificationTitle = payload.data.title;
            }

            if (payload.data.message) {
                notificationMessage = payload.data.message;
            }

            if (type === 102) {
                if (payload.data.caseRecordId) {
                    notificationActions = [
                        Toast.action(
                            Rosetta.string("common.view"),
                            () => {
                                // Clear the Case Record Cache now so we load the Notification Case Record anew on load
                                CaseRecordUtil.clearCaseRecordCache();
                                Navigator.navigate("/caseRecord/1/" + payload.data.caseRecordId);
                            }
                        )
                    ];
                }
            }
        }

        Toast.show(
            notificationTitle,
            notificationMessage,
            Toast.INFO,
            Toast.INFINITE,
            notificationActions
        );
    },
    uploadFCMToken : (token) => {
        if (FirebaseUtil._uploadInFlight) return;
        FirebaseUtil._uploadInFlight = true;

        const formData = new FormData();
        formData.append("pushTokenTypeId", 3);
        formData.append("token", token);

        Axios.post(ENDPOINTS.user.registerPushNotificationToken, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    console.log("Uploaded FCM token: " + token);
                    DataManager.setFCMTokenUploaded(true);
                } else {
                    console.log(API.formatError(resp));
                }
                FirebaseUtil._uploadInFlight = false;
            })
            .catch((e) => {
                console.log(e);
                FirebaseUtil._uploadInFlight = false;
            });
    }
}