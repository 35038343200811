import "./NavigationList.css";
import {NavLink} from "react-router-dom";
import ArrayUtil from "../../util/ArrayUtil";
import {NavigationUtil} from "../../util/NavigationUtil";
import {useEffect, useRef, useState} from "react";

export const NavList = {
    item : (sectionId, title, url, icon, badge, items) => {
        return {
            sectionId,
            title,
            url,
            icon,
            badge,
            items
        };
    }
}

export const NavigationList = (props) => {

    const [sectionId, setSectionId] = useState(null);
    const [badgeCounts, setBadgeCounts] = useState(null);

    const navCallback = useRef();

    useEffect(() => {
        navCallback.current = (action, data) => {
            if (action === NavigationUtil.ACTIONS.SECTION_CHANGE) {
                setSectionId(data);
            } else if (action === NavigationUtil.ACTIONS.BADGE_COUNTS_CHANGE) {
                setBadgeCounts(data);
            }
        };
        NavigationUtil.addCallback(navCallback.current);

        return () => {
            NavigationUtil.removeCallback(navCallback.current);
        }
    }, []);

    function handleCallback(action, item) {
        if (props.callback !== undefined) {
            props.callback(action, item);
        }
    }

    function generateItems(items) {
        let out = [];

        for (let item of items) {
            let subItems = [];
            if (item.hasOwnProperty("items") && item.items) {
                subItems = generateItems(item.items);

                if (subItems.length > 0) {
                    subItems = (
                        <span className={"navigation-child"}>
                            {subItems}
                        </span>
                    );
                }
            }

            if (item.hasOwnProperty("url")) {
                let activeClass = "";

                if (item.hasOwnProperty("sectionId")) {
                    if (sectionId === parseInt(item.sectionId)) {
                        activeClass = " active";
                    }
                }

                let badgeElem = [];
                if (badgeCounts) {
                    if (badgeCounts.hasOwnProperty(item.sectionId) && badgeCounts[item.sectionId] > 0) {
                        badgeElem = (
                            <span className={"badge text-bg-danger"}>{badgeCounts[item.sectionId]}</span>
                        )
                    }
                }

                out.push(
                    <div className={"navigation-contain"}>
                        <NavLink
                            className={"navigation-item clickable" + activeClass}
                            end to={item.url}
                            onClick={() => handleCallback("click", item)}>

                            <span className={"text"}>{item.title}</span>
                            {badgeElem}
                        </NavLink>

                        {subItems}
                    </div>
                );
            } else {
                out.push(
                    <div className={"navigation-contain"}>
                        <div
                            className={"navigation-item"}
                            onClick={() => handleCallback("click", item)}>

                            {item.title}
                        </div>

                        {subItems}
                    </div>
                )
            }
        }

        return out;
    }

    let mainContent = [];

    if (props.items !== undefined) {
        for (let i = 0; i < props.items.length; i++) {
            let header = props.items[i];
            if (header.title) {
                mainContent.push(
                    <div className={"navigation-heading"}>{header.title}</div>
                );
            }

            ArrayUtil.appendArray(mainContent, generateItems(header.items));
        }
    }

    return (
        <div className={"tokyo-navigation-list"}>
            {mainContent}
        </div>
    )

}