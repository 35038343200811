import {BaseModal, BaseModalActions} from "../../alertmodal/BaseModal";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import WindowUtil from "../../../util/WindowUtil";

export const ForgottenPasswordModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll()

            setEmail("");
            setError(null);
            setNetworkInFlight(false);
        }
    }, [shown])

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function submitForgottenPasswordOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let formData = new FormData();
        formData.append("emailAddress", email);
        formData.append("source", 2); // Signal the origin being from the Web App

        Axios.post(ENDPOINTS.auth.requestPasswordReset, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    handleCallback(BaseModalActions.CLOSE);

                    AlertModal.showModal(
                        Rosetta.string("common.success"),
                        Rosetta.string("login.forgotten_password_success"),
                        [
                            AlertModal.button(
                                Rosetta.string("common.ok"),
                                () => {
                                    AlertModal.dismissModal();
                                }
                            )
                        ]
                    );
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                setError(API.defaultError("FORG1000C"));
            });
    }

    // RENDER

    if (!shown) return [];

    let submitButton = (<button className={"btn btn-primary full-width"} onClick={() => submitForgottenPasswordOverNetwork()}>{Rosetta.string("common.submit")}</button>);
    if (networkInFlight) {
        submitButton = (<button className={"btn btn-primary full-width"}>{Rosetta.string("common.submit")}</button>);
    }

    let errorElem = [];
    if (error) {
        errorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>
                        {error}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <BaseModal
            shown={true}
            callback={callback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    {Rosetta.string("login.forgotten_password_message")}
                </div>
            </div>

            {errorElem}

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("login.username")}</label>
                    <input type={"text"} className={"form-control"} value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {submitButton}
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12 text-center"} onClick={() => handleCallback(BaseModalActions.CLOSE)}>
                    <button className={"btn full-width"}>{Rosetta.string("common.cancel")}</button>
                </div>
            </div>

        </BaseModal>
    )

}