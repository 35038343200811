import {useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import {Chronos} from "../../../chronos/Chronos";

import "./ReflectionDetailScreen.css";
import {ReflectionUtil} from "../../../util/ReflectionUtil";
import {DateUtil} from "../../../util/DateUtil";
import {ReflectionReminderModal} from "./ReflectionReminderModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";
import {NavigationUtil} from "../../../util/NavigationUtil";
import {CommonUtil} from "../../../util/CommonUtil";
import HTMLReactParser from "html-react-parser";
import {Toast} from "../../toast/TokyoToaster";
import {DataManager} from "../../../data/DataManager";
import {Navigator} from "../../../util/Navigator";

export const ReflectionDetailScreen = (props) => {

    const {reflectionId} = useParams();

    const [reflection, setReflection] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [reminderModalShown, setReminderModalShown] = useState(false);
    const [reminderModalReminder, setReminderModalReminder] = useState(undefined);

    const [exportNetworkInFlight, setExportNetworkInFlight] = useState(false);

    const casualCategories = useRef([]);

    useEffect(() => {
        NavigationUtil.setSection(NavigationUtil.SECTIONS.REFLECTIONS);

        Navigator.scrollTop();

        casualCategories.current = ReflectionUtil.getCasualCategories();

        if (reflectionId) {
            fetchReflectionFromNetwork();
        }
    }, []);

    function summonReminderModal(reminder) {
        setReminderModalReminder(reminder);
        setReminderModalShown(true);
    }

    function reminderModalDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setReminderModalShown(false);
            setReminderModalReminder(undefined);

            fetchReflectionFromNetwork();
        }
    }

    function fetchReflectionFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("reflectionId", reflectionId);

        Axios.post(ENDPOINTS.reflection.fetchReflection, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setReflection(resp.data.reflection);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("RFF1000C"));
            });
    }

    function exportReflectionOverNetwork() {
        if (exportNetworkInFlight) return;
        setExportNetworkInFlight(true);

        const formData = new FormData();
        formData.append("reflectionId", reflectionId);

        Axios.post(ENDPOINTS.reflection.exportReflection, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("common.download_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    if (resp.data.url !== null) {
                        let authToken = DataManager.getSessionToken();
                        window.open(resp.data.url + "?at=" + authToken);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setExportNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setExportNetworkInFlight(false);
                AlertModal.showError(API.defaultError("REX1000C"));
            })
    }

    // RENDER

    // const user = DataManager.getUser();

    let mainContent = [];

    let exportButton = [];

    if (reflection) {
        let reflectionElems = [];

        exportButton = (
            <button className={"btn btn-primary full-width"} onClick={() => exportReflectionOverNetwork()}>{Rosetta.string("reflection.action_export")}</button>
        );
        if (exportNetworkInFlight) {
            exportButton = (
                <button className={"btn btn-primary full-width disabled"}>{Rosetta.string("common.please_wait")}</button>
            );
        }

        exportButton = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    {exportButton}
                </div>
            </div>
        );

        if (reflection.data === null) {
            // V1 Reflection
            let typeTitle = null;
            if (parseInt(reflection.reflectionFeedbackTypeId) === ReflectionUtil.feedbackTypes.POSITIVE) {
                typeTitle = Rosetta.string("reflection.detail_type_positive");
            } else if (parseInt(reflection.reflectionFeedbackTypeId) === ReflectionUtil.feedbackTypes.NEGATIVE) {
                typeTitle = Rosetta.string("reflection.detail_type_negative");
            }

            let typeElem = [];
            if (typeTitle !== null) {
                typeElem = (<strong>{typeTitle}</strong>);
            }

            let influenceElem = [];
            if (reflection.influence !== null) {
                influenceElem = (
                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            <div><strong>{Rosetta.string("reflection.detail_influence_title")}</strong></div>
                            <div className={"form-control"}>{reflection.influence}</div>
                        </div>
                    </div>
                )
            }

            let privateFeedbackElem = [];
            if (reflection.privateFeedback !== null) {
                privateFeedbackElem = (
                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            <div><strong>{Rosetta.string("reflection.detail_title_private")}</strong></div>
                            <div className={"form-control"}>{reflection.privateFeedback}</div>
                        </div>
                    </div>
                )
            }

            let openFeedbackElem = [];
            if (reflection.openFeedback !== null) {
                openFeedbackElem = (
                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            <div><strong>{Rosetta.string("reflection.detail_title_public")}</strong></div>
                            <div className={"form-control"}>{reflection.openFeedback}</div>
                        </div>
                    </div>
                )
            }

            reflectionElems = [
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"info-widget"}>
                            <div>
                                {
                                    Rosetta.string("reflection.detail_info", {
                                        date: Chronos.withTimestampSeconds(reflection.dateCreated).format("dd/MM/yy"),
                                        recordId: reflection.recordId ? reflection.recordId : ""
                                    })
                                }
                            </div>
                            <div>{typeElem}</div>
                        </div>
                    </div>
                </div>,
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"section-info"}>
                            <div className={"number"}>{reflection.measureNumber}</div>
                            <div className={"text"}>{reflection.measureTitle}</div>
                        </div>
                    </div>
                </div>,
                influenceElem,
                privateFeedbackElem,
                openFeedbackElem
            ]
        } else {
            // V2 Reflection
            const reflectionData = JSON.parse(reflection.data);
            if (reflectionData) {
                if ([
                    ReflectionUtil.reflectionTypes.WHAT_WORKED_WELL,
                    ReflectionUtil.reflectionTypes.OPPORTUNITY_TO_IMPROVE,
                    ReflectionUtil.reflectionTypes.SERVICE_IMPROVEMENT_SUGGESTION
                ].includes(parseInt(reflection.reflectionTypeId))) {
                    let reflectionTitle = Rosetta.string("reflection.editor_title_what_worked_well");
                    let privacyLabel1 = Rosetta.string("reflection.editor_reflection_privacy_1");
                    let privacyLabel2 = Rosetta.string("reflection.editor_reflection_privacy_2");
                    let casualCategoryLabel = Rosetta.string("reflection.editor_casual_categories_label");
                    let feedbackLabel = Rosetta.string("reflection.editor_feedback_label_well");

                    if (reflection.reflectionTypeId === ReflectionUtil.reflectionTypes.OPPORTUNITY_TO_IMPROVE) {
                        reflectionTitle = Rosetta.string("reflection.editor_title_opportunity_to_improve");
                        feedbackLabel = Rosetta.string("reflection.editor_feedback_label_improve");
                    } else if (reflection.reflectionTypeId === ReflectionUtil.reflectionTypes.SERVICE_IMPROVEMENT_SUGGESTION) {
                        reflectionTitle = Rosetta.string("reflection.editor_title_service_improvement");
                        privacyLabel1 = Rosetta.string("reflection.editor_reflection_privacy_service_improvement_1");
                        privacyLabel2 = Rosetta.string("reflection.editor_reflection_privacy_service_improvement_2");
                        casualCategoryLabel = Rosetta.string("reflection.editor_casual_categories_service_improvement");
                        feedbackLabel = Rosetta.string("reflection.editor_feedback_label_service_improvement");
                    }

                    reflectionElems = [
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"info-widget"}>
                                    <div>
                                        {
                                            Rosetta.string("reflection.detail_info", {
                                                date: Chronos.withTimestampSeconds(reflection.dateCreated).format("dd/MM/yy HH:mm"),
                                                recordId: reflection.recordId ? reflection.recordId : ""
                                            })
                                        }
                                    </div>
                                    <div>{reflectionTitle}</div>
                                </div>
                            </div>
                        </div>,
                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <div className={"card"}>
                                    <div className={"card-body text-center"}>
                                        {Rosetta.string("reflection.editor_reflection_date", { date : Chronos.withTimestampSeconds(reflection.dateCreated).format("dd/MM/yyyy HH:mm")})}
                                    </div>
                                </div>
                            </div>
                        </div>,
                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <div className={"alert alert-info"}>
                                    <p>{privacyLabel1}</p>
                                    <p>{privacyLabel2}</p>
                                </div>
                            </div>
                        </div>,
                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                {Rosetta.string("reflection.editor_journey_prelude")}
                            </div>
                        </div>,
                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("reflection.editor_journey_label")}</label>
                                <div className={"form-control"}>{CommonUtil.getOrDefault(reflectionData, "patient_journey", "")}</div>
                            </div>
                        </div>,
                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{casualCategoryLabel}</label>
                                <ul className={"list-group"}>
                                    {
                                        CommonUtil.getOrDefault(reflectionData, "casual_category", []).map((category) => (
                                            <li className={"list-group-item"}>{category}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>,
                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                {Rosetta.string("reflection.editor_feedback_prelude")}
                            </div>
                        </div>,
                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{feedbackLabel}</label>
                                <div className={"form-control"}>{CommonUtil.getOrDefault(reflectionData, "feedback", "")}</div>
                            </div>
                        </div>
                    ]
                } else if (parseInt(reflection.reflectionTypeId) === ReflectionUtil.reflectionTypes.RECORD_PRIVATE_REFLECTION) {
                    reflectionElems = [
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"card"}>
                                    <div className={"card-body"}>
                                        {Rosetta.string("reflection.editor_reflection_date", { date : Chronos.now().format("dd/MM/yyyy HH:mm")})}
                                    </div>
                                </div>
                            </div>
                        </div>,

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <div className={"alert alert-info"}>
                                    {Rosetta.string("reflection.editor_writing_template_prelude")}
                                </div>
                            </div>
                        </div>,

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <strong>{Rosetta.string("reflection.editor_writing_template_background_info")}</strong><br />
                                <p>{Rosetta.string("reflection.editor_writing_template_background_info_1")}</p>
                                <p>{Rosetta.string("reflection.editor_writing_template_background_info_2")}</p>
                            </div>
                        </div>,

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <div className={"form-control"}>{Rosetta.string("reflection.editor_writing_template_pathway_display", {value : CommonUtil.getOrDefault(reflectionData, "pathway", "")})}</div>
                            </div>
                        </div>,

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <div className={"form-control"}>{Rosetta.string("reflection.editor_writing_template_clinical_practice_display", {value : CommonUtil.implode(CommonUtil.getOrDefault(reflectionData, "clinical_practice", []), ", ")})}</div>
                            </div>
                        </div>,

                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <h5>{Rosetta.string("reflection.editor_writing_template_rolfe_framework_1")}</h5>
                                {HTMLReactParser(Rosetta.string("reflection.editor_what_list_html"))}
                            </div>
                        </div>,

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("reflection.editor_what_label")}</label>
                                <div className={"form-control"}>{CommonUtil.getOrDefault(reflectionData, "input_what", "")}</div>
                            </div>
                        </div>,

                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <h5>{Rosetta.string("reflection.editor_writing_template_rolfe_framework_2")}</h5>
                                {HTMLReactParser(Rosetta.string("reflection.editor_so_what_list_html"))}
                            </div>
                        </div>,

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("reflection.editor_so_what_label")}</label>
                                <div className={"form-control"}>{CommonUtil.getOrDefault(reflectionData, "input_so_what", "")}</div>
                            </div>
                        </div>,

                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <h5>{Rosetta.string("reflection.editor_writing_template_rolfe_framework_3")}</h5>
                                {HTMLReactParser(Rosetta.string("reflection.editor_now_what_list_html"))}
                            </div>
                        </div>,

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("reflection.editor_now_what_label")}</label>
                                <div className={"form-control"}>{CommonUtil.getOrDefault(reflectionData, "input_now_what", "")}</div>
                            </div>
                        </div>
                    ];
                }
            } else {
                console.log("Error: Could not parse Reflection Data");
            }
        }

        let reminderElem = [];
        if (reflection.reminder !== null) {
            let reminderDate = parseInt(reflection.reminder.reminderDate);
            if (reflection.reminder.reminderTime !== null) {
                reminderDate += DateUtil.secondStringToDate(reflection.reminder.reminderTime);
            }

            reminderElem = (
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"section-info"}>
                            <div className={"flex-fill"}>
                                <div className={"number"}>{Rosetta.string("reflection.detail_reminder_title")}</div>
                                <div>{Rosetta.string("reflection.detail_reminder_next")}</div>
                                <div>{Chronos.withTimestampSeconds(reminderDate).format("dd/MM/yy HH:mm")}</div>
                                <div>{ReflectionUtil.getNameForReminderType(reflection.reminder.reminderTypeId)}</div>
                            </div>

                            <div>
                                <button className={"btn btn-outline-light"} onClick={() => summonReminderModal(reflection.reminder)}>{Rosetta.string("common.edit")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            reminderElem = (
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <button className={"btn btn-primary full-width"} onClick={() => summonReminderModal()}>{Rosetta.string("reflection.detail_reminder_add")}</button>
                    </div>
                </div>
            )
        }

        mainContent = (
            <div className={"animate-screen-content"}>
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-lg-10 col-xl-8"}>

                        {reflectionElems}

                        {reminderElem}

                        {exportButton}

                    </div>
                </div>
            </div>
        );
    } else if (networkInFlight) {
        // TODO
    }

    return (
        <div className={"reflection-detail-screen app-screen"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("reflection.detail_title")} />
                </div>
            </div>

            {mainContent}

            <ReflectionReminderModal
                shown={reminderModalShown}
                reflection={reflection}
                reminder={reminderModalReminder}
                callback={reminderModalDidCallback} />

        </div>
    )

}