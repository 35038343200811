import Rosetta from "../rosetta/Rosetta";
import {Chronos} from "../chronos/Chronos";
import {DataManager} from "../data/DataManager";

export const CommonUtil = {
    getOrDefault : (obj, keyName, defaultValue, allowNulls) => {
        if (allowNulls === undefined) {
            allowNulls = false;
        }

        if (obj !== undefined && obj !== null && typeof obj === 'object') {
            if (obj.hasOwnProperty(keyName)) {
                if ((!allowNulls && obj[keyName] != null) || allowNulls) {
                    return obj[keyName];
                }
            }
        }
        return defaultValue;
    },
    cloneObject : (object) => {
        return Object.assign({}, object)
    },
    isObject : (object) => {
        return typeof(object) === "object";
    },
    mergeObjects : (o1, o2) => {
        let newFormData = CommonUtil.cloneObject(o1);

        Object.keys(o2).forEach((key) => {
            if (key !== "step") {
                newFormData[key] = o2[key];
            }
        });

        return newFormData;
    },
    getGoogleMapsLink : (addressParts) => {
        let searchQuery = "";
        if (Array.isArray(addressParts)) {
            addressParts.forEach((part) => {
                if (part !== undefined) {
                    if (searchQuery !== "") {
                        searchQuery += "+";
                    }
                    searchQuery += encodeURIComponent(part.toLowerCase().replaceAll(" ", "+"));
                }
            });
        } else {
            searchQuery = encodeURIComponent(addressParts.replaceAll(" ", "+"));
        }

        return "https://www.google.com/maps/search/" + searchQuery + "/";
    },
    implode : (data, delimiter) => {
        if (delimiter === undefined) {
            delimiter = ", ";
        }

        let out = "";

        for (let i = 0; i < data.length; i++) {
            if (data[i] !== null && data[i] !== "") {
                if (out !== "") {
                    out += delimiter;
                }
                out += data[i];
            }
        }

        return out;
    },
    generateFilterSelect : (label, options, value, callback, idKey, labelKey, defaultId, defaultLabel) => {
        if (idKey === undefined) {
            idKey = "id";
        }

        if (labelKey === undefined) {
            labelKey = "name";
        }

        if (defaultLabel === undefined) {
            defaultLabel = Rosetta.string("common.any");
        }

        if (defaultId === undefined) {
            defaultId = "-1";
        }

        return (
            <>
                <label>{label}</label>
                <select className={"form-select"} value={value} onChange={callback}>
                    <option value={defaultId}>{defaultLabel}</option>
                    {
                        options.map((option) => (
                            <option value={option[idKey]}>{option[labelKey]}</option>
                        ))
                    }
                </select>
            </>
        )
    },
    generateFilterPayload : (properties) => {
        let tablePayload = {};

        for (const prop of properties) {
            let allowMinusOne = false;
            if (prop.hasOwnProperty("allowMinusOne")) {
                allowMinusOne = prop.allowMinusOne;
            }

            if (prop.value && prop.value !== "" && (allowMinusOne || prop.value !== "-1")) {
                if (prop.hasOwnProperty("isArray") && prop.isArray) {
                    tablePayload[prop.key] = JSON.stringify([prop.value]);
                } else if (prop.hasOwnProperty("isDate") && prop.isDate) {
                    tablePayload[prop.key] = Chronos.with(new Date(prop.value)).seconds();
                } else {
                    tablePayload[prop.key] = prop.value;
                }
            }
        }

        return tablePayload;
    },
    objectToFormData : (object) => {
        let out = new FormData();

        let keys = Object.keys(object);

        keys.forEach((key) => {
            if (object[key] !== null && object[key] !== undefined) {
                out.append(key, object[key]);
            }
        });

        return out;
    },
    toUint8Array : (input) => {
        const out = [];
        (new Uint8Array(input)).forEach((v) => {
            out.push(v);
        });
        return out;
    },
    reloadApp : () => {
        DataManager.setAppLoadDate(0);
        DataManager.setAppWasReloaded(true);
        window.location.reload(true);
    }
}