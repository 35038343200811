import {Offcanvas, OffcanvasActions, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import {useEffect, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {ReflectionListItem} from "../reflections/ReflectionListItem";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import ArrayUtil from "../../../util/ArrayUtil";
import WindowUtil from "../../../util/WindowUtil";

export const CaseRecordReflectionsModal = (props) => {

    const {shown} = props;
    const {caseRecordId} = props;
    const {callback} = props;

    const [reflections, setReflections] = useState([]);
    const [page, setPage] = useState(1);

    const [forceDismiss, setForceDismiss] = useState(false);

    const [shouldLoadMore, setShouldLoadMore] = useState(true);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const limit = 30;

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            fetchReflectionsFromNetwork();
        } else {
            WindowUtil.unlockBodyScroll();

            setReflections([])
            setPage(1);
            setForceDismiss(false);
            setShouldLoadMore(true);
            setNetworkInFlight(false);
        }
    }, [shown]);

    useEffect(() => {
        if (shown) {
            fetchReflectionsFromNetwork();
        }
    }, [page]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function listItemWasClicked(reflection) {
        handleCallback(OffcanvasActions.CLOSE, reflection);
    }

    function fetchReflectionsFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("caseRecordId", caseRecordId);
        formData.append("page", page);

        Axios.post(ENDPOINTS.reflection.fetchReflectionsForCaseRecord, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.reflections.length > 0) {
                        const mergedArray = ArrayUtil.appendArray(reflections, resp.data.reflections, true);
                        setReflections(mergedArray);
                    }

                    if (resp.data.reflections.length < limit) {
                        setShouldLoadMore(false);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("CRF1000C"));
            })
    }

    // RENDER

    if (!shown) return [];

    let listContent = [];

    if (reflections.length > 0) {
        reflections.forEach((reflection) => (
            listContent.push(
                <ReflectionListItem
                    reflection={reflection}
                    callback={() => listItemWasClicked(reflection)} />
            )
        ));

        if (networkInFlight) {
            listContent.push(
                <li className={"list-group-item text-center"}>
                    <LoadingSpinner inline={true} small={true} />
                </li>
            );
        } else if (shouldLoadMore) {
            listContent.push(
                <li className={"list-group-item text-center"}>
                    <button className={"btn btn-primary"} onClick={() => setPage(page + 1)}>{Rosetta.string("common.load_more")}</button>
                </li>
            );
        }
    } else if (networkInFlight) {
        for (let i = 0; i < 5; i++) {
            listContent.push(
                <li className={"list-group-item"}>
                    <PlaceholderText/>
                </li>
            );
        }
    } else {
        listContent.push(
            <li className={"list-group-item text-center"}>
                {Rosetta.string("common.empty_message")}
            </li>
        )
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("reflection.list_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <ul className={"list-group"}>
                {listContent}
            </ul>

        </Offcanvas>
    )

}