import {useEffect, useState} from "react";
import {BaseModal, BaseModalSizes} from "../../../alertmodal/BaseModal";
import Rosetta from "../../../../rosetta/Rosetta";
import WindowUtil from "../../../../util/WindowUtil";

export const UrgencyDialog = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    if (!shown) return [];

    return (
        <BaseModal
            shown={true}
            size={BaseModalSizes.LARGE}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <table>
                        <thead>
                        <tr>
                            <th>{Rosetta.string("urgency_info.title")}</th>
                            <th>{Rosetta.string("urgency_info.table_title_heading_1")}</th>
                            <th>{Rosetta.string("urgency_info.table_title_heading_2")}</th>
                        </tr>
                        <tr>
                            <th colSpan={3}>{Rosetta.string("urgency_info.table_subtitle")}</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr>
                            <td>{Rosetta.string("urgency_info.table_urgency_1_title")}</td>
                            <td>{Rosetta.string("urgency_info.table_urgency_1_time")}</td>
                            <td>
                                <p><strong>{Rosetta.string("urgency_info.table_urgency_1_item")}</strong></p>
                                <ol>
                                    <li>
                                        {Rosetta.string("urgency_info.table_urgency_1_item_1")}
                                        <ul>
                                            <li>{Rosetta.string("urgency_info.table_urgency_1_item_1a")}</li>
                                            <li>{Rosetta.string("urgency_info.table_urgency_1_item_1b")}</li>
                                            <li>{Rosetta.string("urgency_info.table_urgency_1_item_1c")}</li>
                                        </ul>
                                    </li>
                                    <li>{Rosetta.string("urgency_info.table_urgency_1_item_2")}</li>
                                    <li>{Rosetta.string("urgency_info.table_urgency_1_item_3")}</li>
                                </ol>
                            </td>
                        </tr>

                        <tr>
                            <td>{Rosetta.string("urgency_info.table_urgency_2_title")}</td>
                            <td>{Rosetta.string("urgency_info.table_urgency_2_time")}</td>
                            <td>
                                <p><strong>{Rosetta.string("urgency_info.table_urgency_2_item")}</strong></p>
                                <ol>
                                    <li>
                                        {Rosetta.string("urgency_info.table_urgency_2_item_1")}
                                        <ul>
                                            <li>{Rosetta.string("urgency_info.table_urgency_2_item_1a")}</li>
                                            <li>{Rosetta.string("urgency_info.table_urgency_2_item_1b")}</li>
                                        </ul>
                                    </li>
                                    <li>
                                        {Rosetta.string("urgency_info.table_urgency_1_item_2")}
                                        <ul>
                                            <li>{Rosetta.string("urgency_info.table_urgency_2_item_2a")}</li>
                                        </ul>
                                    </li>
                                </ol>
                            </td>
                        </tr>

                        <tr>
                            <td>{Rosetta.string("urgency_info.table_urgency_3_title")}</td>
                            <td>{Rosetta.string("urgency_info.table_urgency_3_time")}</td>
                            <td>
                                <p><strong>{Rosetta.string("urgency_info.table_urgency_3_item")}</strong></p>
                                <p>{Rosetta.string("urgency_info.table_urgency_3_item_1")}</p>
                            </td>
                        </tr>

                        <tr>
                            <td>{Rosetta.string("urgency_info.table_urgency_4_title")}</td>
                            <td>{Rosetta.string("urgency_info.table_urgency_4_time")}</td>
                            <td>
                                <p><strong>{Rosetta.string("urgency_info.table_urgency_4_item")}</strong></p>
                                <p>{Rosetta.string("urgency_info.table_urgency_4_item_1")}</p>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert alert-info"}>
                        {Rosetta.string("urgency_info.table_urgency_info")}
                    </div>
                </div>
            </div>

            <div className={"row mt-2 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6 col-xl-4"}>
                    <button className={"btn btn-primary"} onClick={() => setForceDismiss(true)}>
                        {Rosetta.string("common.close")}
                    </button>
                </div>
            </div>

        </BaseModal>
    );

}