import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {DataManager} from "../../../data/DataManager";

import IconIndicator from "../../../assets/images/icon_list_indicator.svg";
import {ImageUtil} from "../../../util/ImageUtil";
import {useEffect, useRef, useState} from "react";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";
import {ChangePasswordModal} from "./ChangePasswordModal";
import {AlertModal} from "../../alertmodal/AlertModal";
import {AppUser} from "../../../util/AppUser";
import {ReflectionSettingsModal} from "./ReflectionSettingsModal";
import {NavigationUtil} from "../../../util/NavigationUtil";
import {Navigator} from "../../../util/Navigator";
import {AuthUtil} from "../../../util/AuthUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {CommonUtil} from "../../../util/CommonUtil";
import {Toast} from "../../toast/TokyoToaster";
import HTMLReactParser from "html-react-parser";
import {AccountDeactivationPromptModal} from "./AccountDeactivationPromptModal";
import {BaseModalActions} from "../../alertmodal/BaseModal";
import {FirebaseUtil} from "../../../util/FirebaseUtil";
import {Chronos} from "../../../chronos/Chronos";
import {BuildUtil} from "../../../util/BuildUtil";

export const SettingsScreen = (props) => {

    const [user, setUser]= useState(DataManager.getUser());
    const [changePasswordModalShown, setChangePasswordModalShown] = useState(false);
    const [reflectionSettingsModalShown, setReflectionSettingsModalShown] = useState(false);

    const [twoFactorNetworkInFlight, setTwoFactorNetworkInFlight] = useState(false);
    const [notificationNetworkInFlight, setNotificationNetworkInFlight] = useState(false);

    const [supportsSecureLogin, setSupportsSecureLogin] = useState(false);
    const [secureLoginData, setSecureLoginData] = useState(DataManager.getSecureLoginData());

    const [policies, setPolicies] = useState([]);
    const [policyNetworkInFlight, setPolicyNetworkInFlight] = useState(false);

    const [deactivationModalShown, setDeactivationModalShown] = useState(false);

    const dataManagerCallback = useRef();

    useEffect(() => {
        NavigationUtil.setSection(NavigationUtil.SECTIONS.SETTINGS);

        Navigator.scrollTop();

        AuthUtil.supportsSecureLogin((support) => {
            setSupportsSecureLogin(support);
        });

        dataManagerCallback.current = (key, value) => {
            if (key === DataManager.keys.user) {
                setUser(DataManager.getUser());
            } else if (key === "qinotify.secure_login_data") {
                setSecureLoginData(DataManager.getSecureLoginData());
            }
        };
        DataManager.addCallback(dataManagerCallback.current);

        fetchPoliciesFromNetwork();

        return () => {
            DataManager.removeCallback(dataManagerCallback.current);
        };
    }, []);

    function launchPrivacyPolicy() {
        window.open(process.env.REACT_APP_BASE_URL + "/page/terms");
    }

    function showChangePasswordModal() {
        setChangePasswordModalShown(true);
    }

    function changePasswordModalDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setChangePasswordModalShown(false);
        }
    }

    function showReflectionSettingsModal() {
        setReflectionSettingsModalShown(true);
    }

    function reflectionSettingsDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setReflectionSettingsModalShown(false);
        }
    }

    function showDeactivationModal() {
        setDeactivationModalShown(true);
    }

    function deactivationModalDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setDeactivationModalShown(false);
        }
    }

    function promptForSignOut() {
        AlertModal.showModal(
            Rosetta.string("settings.sign_out"),
            Rosetta.string("settings.sign_out_confirm"),
            [
                AlertModal.button(
                    Rosetta.string("settings.sign_out"),
                    () => {
                        AlertModal.dismissModal();
                        AppUser.signOut();
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        );
    }

    function moveToOrganisationSelector() {
        Navigator.navigate("/organisations");
    }

    function reloadApp() {
        CommonUtil.reloadApp();
    }

    function promptTwoFactorStateChange() {
        let message = Rosetta.string("settings.two_factor_enable");
        let buttonLabel = Rosetta.string("settings.two_factor_enable_button");
        let buttonClass = "success";
        let newState = 1;
        if (parseInt(user.twoFactorEnabled) === 1) {
            message = Rosetta.string("settings.two_factor_disable");
            buttonLabel = Rosetta.string("settings.two_factor_disable_button");
            buttonClass = "danger";
            newState = 0;
        }

        AlertModal.showModal(
            Rosetta.string("settings.two_factor_title"),
            message,
            [
                AlertModal.button(
                    buttonLabel,
                    () => {
                        submitTwoFactorStateOverNetwork(newState);
                        AlertModal.dismissModal();
                    },
                    buttonClass
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        )
    }

    function handleSecureLoginClick() {
        if (secureLoginData) {
            AlertModal.showModal(
                Rosetta.string("settings.secure_login"),
                Rosetta.string("settings.secure_login_disable"),
                [
                    AlertModal.button(
                        Rosetta.string("settings.two_factor_disable_button"),
                        () => {
                            disableSecureLoginOverNetwork();

                            DataManager.clearSecureLoginData();

                            AlertModal.dismissModal();
                        },
                        "danger"
                    ),
                    AlertModal.button(
                        Rosetta.string("common.cancel"),
                        () => {
                            AlertModal.dismissModal();
                        }
                    )
                ]
            )
        } else {
            Navigator.navigate("/secure-login-enroll#r=settings");
        }
    }

    function disableSecureLoginOverNetwork() {
        if (secureLoginData) {
            const formData = new FormData();
            formData.append("identifier", secureLoginData.identifier);

            Axios.post(ENDPOINTS.user.disableSecureLogin, formData)
                .then((r) => {
                    const resp = API.parse(r);
                    console.log("Server result: ", resp.success);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    function handlePolicyClick(policy) {
        if (policy) {
            if (policy.url !== null) {
                window.open(policy.url);
            } else if (policy.filePath !== null) {
                window.open(policy.filePath);
            } else if (policy.description !== null) {
                AlertModal.showModal(
                    policy.title,
                    HTMLReactParser(policy.description),
                    [
                        AlertModal.button(
                            Rosetta.string("common.close"),
                            () => {
                                AlertModal.dismissModal();
                            }
                        )
                    ]
                );
            }
        }
    }

    function submitTwoFactorStateOverNetwork(state) {
        if (twoFactorNetworkInFlight) return;
        setTwoFactorNetworkInFlight(true);

        const data = new FormData();
        data.append("state", state);

        Axios.post(ENDPOINTS.user.setTwoFactorAuthenticationState, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.user) {
                        const newUser = CommonUtil.cloneObject(user);
                        newUser.twoFactorEnabled = resp.data.user.twoFactorEnabled;
                        DataManager.setUser(newUser);

                        Toast.show(
                            Rosetta.string("common.success"),
                            Rosetta.string("settings.two_factor_state_change"),
                            Toast.SUCCESS,
                            Toast.LONG
                        );
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setTwoFactorNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("U2FA1000C"));
                setTwoFactorNetworkInFlight(false);
            })
    }

    function fetchPoliciesFromNetwork() {
        if (policyNetworkInFlight) return;
        setPolicyNetworkInFlight(true);

        const formData = new FormData();
        formData.append("organisationId", DataManager.getOrganisationId());

        Axios.post(ENDPOINTS.organisation.getOrganisationPolicies, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPolicies(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setPolicyNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setPolicyNetworkInFlight(false);
            });
    }

    function setNotificationsEnabledOverNetwork(state) {
        if (notificationNetworkInFlight) return;
        setNotificationNetworkInFlight(true);

        const formData = new FormData();
        formData.append("state", state);

        if (state === "1") {
            // Pop Notification Permission prompt now and upload FCM token if we don't already have one
            FirebaseUtil.getFCMToken();
        }

        Axios.post(ENDPOINTS.user.setNotificationsEnabled, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let user = resp.data.user;
                    DataManager.setUser(user);

                    let toastLabel = Rosetta.string("settings.notification_preference_enabled");
                    if (state === "0") {
                        toastLabel = Rosetta.string("settings.notification_preference_disabled");
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        toastLabel,
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNotificationNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNotificationNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UNS1000C"));
            });
    }

    // RENDER

    let organisationElem = [];
    let deactivationElem = [];
    if (user) {
        if (!user.organisationId) {
            organisationElem = (
                <li className={"list-group-item clickable d-flex justify-content-between align-items-center"}
                    onClick={() => moveToOrganisationSelector()}>
                    {Rosetta.string("settings.change_organisation")}
                    <div className={"list-indicator"} style={{backgroundImage: ImageUtil.background(IconIndicator)}}/>
                </li>
            )
        }

        if (AppUser.isInRole([AppUser.roles.SUPER_USER, AppUser.roles.USER, AppUser.roles.ADMIN])) {
            deactivationElem = (
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <ul className={"list-group"}>
                            <SettingItem
                                label={Rosetta.string("settings.account_deactivation_title")}
                                onClick={() => showDeactivationModal()} />
                        </ul>
                    </div>
                </div>
            )
        }
    }

    // Two Factor Preference
    let twoFactorBadge = (<span className={"badge text-bg-danger"}>{Rosetta.string("common.off")}</span>);
    if (user && user.twoFactorEnabled && parseInt(user.twoFactorEnabled) === 1) {
        twoFactorBadge = (<span className={"badge text-bg-success"}>{Rosetta.string("common.on")}</span>)
    }

    // Secure Login Preference
    let secureLoginElem = [];
    if (supportsSecureLogin) {
        let secureLoginBadge = [];
        if (secureLoginData !== undefined && secureLoginData !== null) {
            secureLoginBadge = <span className={"badge text-bg-success"}>{Rosetta.string("common.on")}</span>;
        } else {
            secureLoginBadge = <span className={"badge text-bg-danger"}>{Rosetta.string("common.off")}</span>;
        }

        secureLoginElem = (
            <SettingItem
                label={Rosetta.string("settings.secure_login")}
                badge={secureLoginBadge}
                onClick={() => handleSecureLoginClick()} />
        )
    }

    // Notifications Preference
    let notificationBadge = (<span className={"badge text-bg-success"}>{Rosetta.string("common.on")}</span>);
    let notificationState = "0";
    if (user && user.notificationsEnabled && parseInt(user.notificationsEnabled) === 0) {
        notificationBadge = (<span className={"badge text-bg-danger"}>{Rosetta.string("common.off")}</span>);
        notificationState = "1";
    }

    /*
    <SettingItem
        label={"DEBUG: SET RELOAD DATE"}
        onClick={() => {
            DataManager.setAppLoadDate(Chronos.now().add(-3, Chronos.DAYS).seconds());
        }} />
     */

    return (
        <div className={"settings-screen app-screen"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("settings.title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-lg-10 col-xl-8"}>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <ul className={"list-group"}>
                                    <li className={"list-group-item"}><h5>{Rosetta.string("settings.your_details")}</h5></li>
                                    <li className={"list-group-item"}>
                                        <div><strong>{Rosetta.string("settings.details_name")}</strong></div>
                                        <div>{Rosetta.string("common.name_format", { given_name : user.givenName, family_name : user.familyName })}</div>
                                    </li>
                                    <li className={"list-group-item"}>
                                        <div><strong>{Rosetta.string("settings.details_email")}</strong></div>
                                        <div>{user.emailAddress}</div>
                                    </li>
                                    <li className={"list-group-item"}>
                                        <div><strong>{Rosetta.string("settings.details_role")}</strong></div>
                                        <div>{user.role}</div>
                                    </li>

                                    <SettingItem
                                        label={Rosetta.string("settings.reflection_settings")}
                                        onClick={() => showReflectionSettingsModal()} />

                                    {organisationElem}

                                    <SettingItem
                                        label={Rosetta.string("settings.sign_out")}
                                        onClick={() => promptForSignOut()} />
                                </ul>
                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <ul className={"list-group"}>
                                    <li className={"list-group-item"}>
                                        <div><strong>{Rosetta.string("settings.app_version")}</strong></div>
                                        <div>{BuildUtil.VERSION}</div>
                                    </li>

                                    <SettingItem
                                        label={Rosetta.string("settings.refresh_app")}
                                        onClick={() => {
                                            reloadApp();
                                        }} />
                                </ul>
                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <ul className={"list-group"}>
                                    <SettingItem
                                        label={Rosetta.string("settings.change_password")}
                                        onClick={() => showChangePasswordModal()} />

                                    <SettingItem
                                        label={Rosetta.string("settings.two_factor_title")}
                                        badge={twoFactorBadge}
                                        onClick={() => promptTwoFactorStateChange()} />

                                    {secureLoginElem}

                                    <SettingItem
                                        label={Rosetta.string("settings.notification_preference")}
                                        badge={notificationBadge}
                                        onClick={() => setNotificationsEnabledOverNetwork(notificationState)} />
                                </ul>
                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <ul className={"list-group"}>
                                    <SettingItem
                                        label={Rosetta.string("settings.privacy_statement")}
                                        onClick={() => launchPrivacyPolicy()} />

                                    {policies.map((policy) => (
                                        <SettingItem
                                            label={policy.title}
                                            onClick={() => handlePolicyClick(policy)} />
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {deactivationElem}

                    </div>
                </div>
            </div>

            <ChangePasswordModal
                shown={changePasswordModalShown}
                callback={changePasswordModalDidCallback} />

            <ReflectionSettingsModal
                shown={reflectionSettingsModalShown}
                callback={reflectionSettingsDidCallback} />

            <AccountDeactivationPromptModal
                shown={deactivationModalShown}
                callback={deactivationModalDidCallback} />

        </div>
    )

}

export const SettingItem = (props) => {
    const {label} = props;
    const {badge} = props;
    const {onClick} = props;

    let indicator = [];
    if (onClick) {
        indicator = (<div className={"list-indicator"} style={{backgroundImage : ImageUtil.background(IconIndicator)}} />);
    }

    return (
        <li className={"list-group-item clickable d-flex align-items-center"} onClick={onClick}>
            <span className={"flex-fill"}>{label}</span>
            {badge}
            {indicator}
        </li>
    )
}