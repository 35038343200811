import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import WindowUtil from "../../../util/WindowUtil";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import Validator from "../../../util/Validator";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";

export const ChangePasswordModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [error, setError] = useState(null);

    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();

            setForceDismiss(false);
            setPassword("");
            setPasswordConfirm("");
            setError(null);
            setNetworkInFlight(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function submitPasswordOverNetwork() {
        if (networkInFlight) return;

        const result = Validator.validateCreateFormData({
            password, passwordConfirm
        }, [
            Validator.rule("password", "string", Rosetta.string("settings.new_password"), "newPassword"),
            Validator.rule("passwordConfirm", "string", Rosetta.string("settings.new_password_confirm"), "newPasswordConfirm")
        ]);

        if (!result.success) {
            setError(result.error);
            return;
        }

        setError(null);
        setNetworkInFlight(true);

        Axios.post(ENDPOINTS.user.updateUserPassword, result.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setForceDismiss(true);

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("settings.new_password_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setError(API.defaultError("UCP1000C"));
                setNetworkInFlight(false);
            });
    }

    // RENDER

    if (!shown) return [];

    let submitButton = (<button className={"btn btn-primary full-width"} onClick={() => submitPasswordOverNetwork()}>{Rosetta.string("common.submit")}</button>);
    if (networkInFlight) {
        submitButton = (<LoadingSpinner inline={true} small={true} />);
    }

    let errorElem = [];
    if (error) {
        errorElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>
                        {error}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("settings.change_password")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("settings.new_password")}</label>
                    <input type={"password"} className={"form-control"} value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("settings.new_password_confirm")}</label>
                    <input type={"password"} className={"form-control"} value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                </div>
            </div>

            {errorElem}

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {submitButton}
                </div>
            </div>

        </Offcanvas>
    )

}