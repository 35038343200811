import "./QISwitch.css";

export const QISwitchActions = {
    CHANGE : "qiswitch.change"
};

export const QISwitch = (props) => {

    const {value} = props;
    const {onChange} = props;

    function handleCallback(data) {
        console.log("SWITCH CALLBACK: " + data);
        if (onChange) {
            onChange(data);
        }
    }

    let extraClass = "";
    if (value) {
        extraClass = " active";
    }

    return (
        <div className={"qi-switch" + extraClass} onClick={() => handleCallback(!value)}>
            <div className={"check-hide"}>
                <input type={"checkbox"} value={value} onChange={(e) => handleCallback(e.target.checked)} />
            </div>

            <div className={"handle"}>
                <div className={"handle-block"}>
                    <div className={"block-colour badge-all-good"} />
                    <div className={"block-colour badge-improve"} />
                </div>

            </div>
        </div>
    )

}