import "./ScreenTitle.css";
import {ImageUtil} from "../../util/ImageUtil";
import arrowBack from "../../assets/images/arrow_back.svg";
import {Navigator} from "../../util/Navigator";

export const ScreenTitle = (props) => {

    function navigationIconWasClicked() {
        Navigator.goBack();
    }

    // RENDER

    let screenTitle = "";
    if (props.title !== undefined) {
        screenTitle = props.title;
    }

    let actionElem = (<div className={"action-space"} />);
    if (props.action !== undefined) {
        actionElem = (
            <button className={"action-icon always-display"}
                onClick={props.action.callback}
                aria-label={props.action.title}
                style={{backgroundImage : ImageUtil.background(props.action.icon)}} />
        )
    }

    return (
        <div className={"screen-title"}>
            <div className={"title-container"}>
                <button className={"action-icon"}
                        onClick={() => navigationIconWasClicked()}
                        style={{backgroundImage : ImageUtil.background(arrowBack)}} />
                <span className={"screen-title-text"}>{screenTitle}</span>
                {actionElem}
            </div>
        </div>
    )

}

export const ScreenTitleAction = {
    create : (icon, title, callback) => {
        return {
            icon,
            title,
            callback
        };
    }
}