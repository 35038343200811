export const NavigationUtil = {
    SECTIONS : {
        PATIENTS : 1,
        REFLECTIONS : 2,
        SETTINGS : 3,
        ORGANISATION : 4
    },
    ACTIONS : {
        SECTION_CHANGE : "navigation.section_change",
        BADGE_COUNTS_CHANGE : "navigation.badge_counts_change"
    },
    _currentSection : null,
    _badgeCounts : {},
    _callbacks : [],
    setSection : (section) => {
        NavigationUtil._currentSection = section;

        NavigationUtil.triggerCallbacks(NavigationUtil.ACTIONS.SECTION_CHANGE, section);
    },
    getSection : () => {
        return NavigationUtil._currentSection;
    },
    setBadgeCounts : (counts) => {
        NavigationUtil._badgeCounts = counts;

        NavigationUtil.triggerCallbacks(NavigationUtil.ACTIONS.BADGE_COUNTS_CHANGE, counts);
    },
    addCallback : (callback) => {
        NavigationUtil._callbacks.push(callback);
    },
    removeCallback : (callback) => {
        for (let i = 0; i < NavigationUtil._callbacks.length; i++) {
            if (NavigationUtil._callbacks[i] === callback) {
                delete NavigationUtil._callbacks[i];
            }
        }
    },
    triggerCallbacks : (action, data) => {
        for (let i = 0; i < NavigationUtil._callbacks.length; i++) {
            try {
                let callback = NavigationUtil._callbacks[i];
                if (callback) {
                    callback(action, data);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
}