import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import {useEffect, useRef, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import {ReflectionUtil} from "../../../util/ReflectionUtil";
import {Chronos} from "../../../chronos/Chronos";
import {Button, Check, CheckList, Container, DropDown, Former, FormerActions, Text} from "../../form/Former";
import WindowUtil from "../../../util/WindowUtil";
import Validator from "../../../util/Validator";
import {CommonUtil} from "../../../util/CommonUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import {Toast} from "../../toast/TokyoToaster";

export const SegmentReflectionEditorModal = (props) => {

    const {shown} = props;
    const {data} =  props;
    const {callback} = props;

    const [formState, setFormState] = useState({});
    const [forceDismiss, setForceDismiss] = useState(false);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const casualCategories = useRef([]);

    useEffect(() => {
        casualCategories.current = ReflectionUtil.getCasualCategories();
    }, []);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            setForceDismiss(false);
            setFormState({});
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitReflectionOverNetwork(data.data);
            }
        }
    }

    function submitReflectionOverNetwork(state) {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const networkData = CommonUtil.cloneObject(data);
        networkData.data = JSON.stringify(state);

        const validationResult = Validator.validateCreateFormData(networkData, [
            Validator.rule("reflectionFeedbackTypeId", "int", "Reflection Feedback Type ID", "reflectionFeedbackTypeId", false),
            Validator.rule("caseRecordId", "int", "Case Record ID", "caseRecordId", false),
            Validator.rule("reflectionTypeId", "int", "Reflection Type ID", "reflectionTypeId", false),
            Validator.rule("segmentId", "int", "Segment ID", "segmentId", false),
            Validator.rule("data", "string", "Data", "data", false)
        ]);

        Axios.post(ENDPOINTS.reflection.submitReflection, validationResult.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("reflection.editor_feedback_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("REFS1000C"));
            });
    }

    // RENDER
    if (!shown) return [];

    let offcanvasTitle = Rosetta.string("reflection.editor_title_what_worked_well");
    let privacyLabel1 = Rosetta.string("reflection.editor_reflection_privacy_1");
    let privacyLabel2 = Rosetta.string("reflection.editor_reflection_privacy_2");
    let casualCategoryLabel = Rosetta.string("reflection.editor_casual_categories_label");
    let feedbackLabel = Rosetta.string("reflection.editor_feedback_label_well");
    if (data) {
        if (data.reflectionTypeId === ReflectionUtil.reflectionTypes.OPPORTUNITY_TO_IMPROVE) {
            offcanvasTitle = Rosetta.string("reflection.editor_title_opportunity_to_improve");
            feedbackLabel = Rosetta.string("reflection.editor_feedback_label_improve");
        } else if (data.reflectionTypeId === ReflectionUtil.reflectionTypes.SERVICE_IMPROVEMENT_SUGGESTION) {
            offcanvasTitle = Rosetta.string("reflection.editor_title_service_improvement");
            privacyLabel1 = Rosetta.string("reflection.editor_reflection_privacy_service_improvement_1");
            privacyLabel2 = Rosetta.string("reflection.editor_reflection_privacy_service_improvement_2");
            casualCategoryLabel = Rosetta.string("reflection.editor_casual_categories_service_improvement");
            feedbackLabel = Rosetta.string("reflection.editor_feedback_label_service_improvement");
        }
    }

    let nelaId = "";
    let emergencyLaparotomyData = "";
    if (data.hasOwnProperty("caseRecord") && data.caseRecord) {
        nelaId = data.caseRecord.recordId;
    }

    return (
        <Offcanvas
            shown={true}
            title={offcanvasTitle}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <Former
                state={formState}
                callback={formDidCallback}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                {Rosetta.string("reflection.editor_reflection_date", { date : Chronos.now().format("dd/MM/yyyy HH:mm")})}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        {Rosetta.string("reflection.editor_reflection_info_1", { NELA_ID : nelaId, date : emergencyLaparotomyData })}
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-info"}>
                            <p>{privacyLabel1}</p>
                            <p>{privacyLabel2}</p>
                        </div>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        {Rosetta.string("reflection.editor_journey_prelude")}
                    </div>
                </div>

                <Container className={"row mt-4"}>
                    <Container className={"col-12"}>

                        <Text
                            name={"patient_journey"}
                            label={Rosetta.string("reflection.editor_journey_label")}
                            className={"form-control"}
                            multiline={true}
                            mandatory={true} />

                    </Container>
                </Container>

                <Container className={"row mt-4"}>
                    <Container className={"col-12"}>

                        <CheckList
                            name={"casual_category"}
                            label={casualCategoryLabel}
                            className={"form-select"}>

                            {casualCategories.current.map((option) => (
                                <Check value={option}>{option}</Check>
                            ))}

                        </CheckList>
                    </Container>
                </Container>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        {Rosetta.string("reflection.editor_feedback_prelude")}
                    </div>
                </div>

                <Container className={"row mt-4"}>
                    <Container className={"col-12"}>

                        <Text
                            name={"feedback"}
                            label={feedbackLabel}
                            multiline={true}
                            className={"form-control"} />

                    </Container>
                </Container>

                <Container className={"row mt-4"}>
                    <Container className={"col-12 text-center"}>

                        <Button
                            className={"btn btn-success"}
                            label={Rosetta.string("common.submit")} />

                    </Container>
                </Container>

            </Former>


        </Offcanvas>
    )

}