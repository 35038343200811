import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {CaseRecordUtil} from "../../../util/CaseRecordUtil";
import {ColourUtil} from "../../../util/ColourUtil";
import {useParams} from "react-router-dom";
import {AlertModal} from "../../alertmodal/AlertModal";
import HTMLReactParser from "html-react-parser";
import {ReflectionEditorModal} from "../reflections/ReflectionEditorModal";
import {ReflectionUtil} from "../../../util/ReflectionUtil";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";
import {NavigationUtil} from "../../../util/NavigationUtil";
import {Navigator} from "../../../util/Navigator";
import {SegmentReflectionEditorModal} from "../reflections/SegmentReflectionEditorModal";
import {SegmentReflectionWritingTemplateModal} from "../reflections/SegmentReflectionWritingTemplateModal";

export const CaseRecordResultSummaryScreen = (props) => {

    const {departmentId} = useParams();
    const {caseRecordId} = useParams();
    const {focusOutcomeTypeId} = useParams();

    const [caseRecord, setCaseRecord] = useState(null);

    const [reflectionEditorShown, setReflectionEditorShown] = useState(false);
    const [reflectionEditorData, setReflectionEditorData] = useState(null);

    const [segmentReflectionEditorShown, setSegmentReflectionEditorShown] = useState(false);
    const [segmentReflectionWritingModalShown, setSegmentReflectionWritingModalShown] = useState(false);
    const [segmentReflectionData, setSegmentReflectionData] = useState(null);

    useEffect(() => {
        NavigationUtil.setSection(NavigationUtil.SECTIONS.PATIENTS);

        Navigator.scrollTop();

        const cr = CaseRecordUtil.findCaseRecordInCache(caseRecordId);
        if (cr) {
            setCaseRecord(cr);
        } else {
            Navigator.navigate("/");
        }
    }, []);

    function showClinicalStandard(item) {
        AlertModal.showModal(
            Rosetta.string("case_record.section_clinical_standards"),
            HTMLReactParser(item.clinicalStandard),
            [
                AlertModal.button(
                    Rosetta.string("common.close"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        )
    }

    function summonReflectionEditor(measure) {
        if (caseRecord) {
            const editorData = {};

            if (caseRecord.patient) {
                editorData.patientName = caseRecord.patient.name
            }

            if (measure) {
                editorData.segmentId = measure.segmentId;
                editorData.measureTitle = measure.title;
                editorData.measureNumber = measure.number;

                if (measure.caseRecordSegmentSectionItemOutcomeTypeId) {
                    editorData.feedbackTypeId = ReflectionUtil.segmentSectionOutcomeTypeToReflectionType(measure.caseRecordSegmentSectionItemOutcomeTypeId);
                }
            }

            setReflectionEditorData(editorData);
        }

        setReflectionEditorShown(true);
    }

    function reflectionEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setReflectionEditorShown(false);
            setReflectionEditorData(null);
        }
    }

    function summonSegmentReflectionEditor(reflectionTypeId) {
        let reflectionFeedbackTypeId = ReflectionUtil.feedbackTypes.NEUTRAL;
        switch (reflectionTypeId) {
            case ReflectionUtil.reflectionTypes.WHAT_WORKED_WELL:
                reflectionFeedbackTypeId = ReflectionUtil.feedbackTypes.POSITIVE; break;
            case ReflectionUtil.reflectionTypes.OPPORTUNITY_TO_IMPROVE:
                reflectionFeedbackTypeId = ReflectionUtil.feedbackTypes.NEGATIVE; break;
            default:
        }

        setSegmentReflectionData({
            reflectionFeedbackTypeId,
            reflectionTypeId,
            caseRecordId
        });
        setSegmentReflectionEditorShown(true);
    }

    function segmentReflectionEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setSegmentReflectionEditorShown(false);
        }
    }

    function summonSegmentReflectionWritingEditor() {
        setSegmentReflectionData({
            reflectionFeedbackTypeId : ReflectionUtil.feedbackTypes.NEUTRAL,
            reflectionTypeId : ReflectionUtil.reflectionTypes.RECORD_PRIVATE_REFLECTION,
            caseRecordId
        });
        setSegmentReflectionWritingModalShown(true);
    }

    function segmentReflectionWritingModalDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setSegmentReflectionWritingModalShown(false);
        }
    }

    // RENDER

    let mainContent = [];

    if (caseRecord) {
        let items = [];

        if (caseRecord.hasOwnProperty("segments")) {
            caseRecord.segments.forEach((segment) => {
                let segmentItems = [];

                if (segment.hasOwnProperty("sections")) {

                    segment.sections.forEach((section) => {
                        let sectionItems = [];

                        if (section.hasOwnProperty("items")) {
                            section.items.forEach((item) => {
                                item.segmentId = segment.id; // Hold onto this for later
                                if (parseInt(item.caseRecordSegmentSectionItemOutcomeTypeId) === parseInt(focusOutcomeTypeId)) {
                                    sectionItems.push(
                                        CaseRecordUtil.generateItemView(item, (action, data) => {
                                            if (action === CaseRecordUtil.actions.CLINICAL_STANDARD_CLICK) {
                                                showClinicalStandard(data);
                                            } else if (action === CaseRecordUtil.actions.REFLECT_CLICK) {
                                                summonReflectionEditor(item);
                                            }
                                        })
                                    )
                                }

                            });

                        }

                        if (sectionItems.length > 0) {
                            segmentItems.push(
                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>

                                        <ul className={"list-group"}>
                                            <li className={"list-group-item section-title"}>{section.title}</li>
                                            {sectionItems}
                                        </ul> 

                                    </div>
                                </div>
                            )
                        }

                    });

                }

                if (segmentItems.length > 0) {
                    items.push(
                        <div className={"row mt-4 justify-content-center"}>
                            <div className={"col-12 col-lg-10 col-xl-8"}>

                                <ul className={"list-group"}>
                                    <li className={"list-group-item section-heading"}>
                                        <div className={"number-tag"} style={{backgroundColor : segment.primaryColour}}>
                                            <span className={"text" + (!ColourUtil.isLight(segment.primaryColour) ? " dark" : "")}>{segment.segmentNumber}</span>
                                        </div>

                                        <div className={"section-content"}>
                                            <div className={"section-title"}>{segment.segmentName}</div>
                                        </div>
                                    </li>
                                </ul>

                                {segmentItems}

                            </div>
                        </div>
                    )
                }
            });
        }

        if (items.length === 0) {
            items.push(
                <div className={"row mt-4"}>
                    <div className={"col-12"}>

                        <ul className={"list-group"}>
                            <li className={"list-group-item text-center"}>
                                {Rosetta.string("common.empty_message")}
                            </li>
                        </ul>

                    </div>
                </div>
            )
        }

        mainContent = (
            <div className={"animate-screen-content"}>
                {items}
            </div>
        );
    }

    let reflectionTitle = Rosetta.string("case_record.summary_all_good_reflection_title");
    let reflectionMessage = Rosetta.string("case_record.summary_all_good_reflection_message");
    if (parseInt(focusOutcomeTypeId) === CaseRecordUtil.itemOutcomeTypes.NEGATIVE) {
        reflectionTitle = Rosetta.string("case_record.summary_improve_reflection_title");
        reflectionMessage = Rosetta.string("case_record.summary_improve_reflection_message");
    }

    // Yes, we're using case-record-section-screen here purposefully.
    return (
        <div className={"case-record-section-screen app-screen"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("case_record.detail_title")} />
                </div>
            </div>

            {mainContent}

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-lg-10 col-xl-8"}>

                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <strong>{reflectionTitle}</strong>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    {reflectionMessage}
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <button className={"btn btn-primary full-width"} onClick={() => summonSegmentReflectionEditor(ReflectionUtil.reflectionTypes.WHAT_WORKED_WELL)}>{Rosetta.string("case_record.summary_action_went_well")}</button>
                                    <button className={"btn btn-primary full-width mt-1"} onClick={() => summonSegmentReflectionEditor(ReflectionUtil.reflectionTypes.OPPORTUNITY_TO_IMPROVE)}>{Rosetta.string("case_record.summary_action_improve")}</button>
                                    <button className={"btn btn-primary full-width mt-1"} onClick={() => summonSegmentReflectionEditor(ReflectionUtil.reflectionTypes.SERVICE_IMPROVEMENT_SUGGESTION)}>{Rosetta.string("case_record.summary_action_service_improvement")}</button>
                                    <button className={"btn btn-primary full-width mt-1"} onClick={() => summonSegmentReflectionWritingEditor()}>{Rosetta.string("case_record.summary_action_private_reflection")}</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <ReflectionEditorModal
                shown={reflectionEditorShown}
                caseRecordId={caseRecordId}
                segmentId={reflectionEditorData ? reflectionEditorData.segmentId : null}
                patientName={reflectionEditorData ? reflectionEditorData.patientName : ""}
                measureTitle={reflectionEditorData ? reflectionEditorData.measureTitle : ""}
                measureNumber={reflectionEditorData ? reflectionEditorData.measureNumber : ""}
                reflectionFeedbackTypeId={reflectionEditorData ? reflectionEditorData.feedbackTypeId : -1}
                callback={reflectionEditorDidCallback} />

            <SegmentReflectionEditorModal
                shown={segmentReflectionEditorShown}
                data={segmentReflectionData}
                callback={segmentReflectionEditorDidCallback} />

            <SegmentReflectionWritingTemplateModal
                shown={segmentReflectionWritingModalShown}
                data={segmentReflectionData}
                callback={segmentReflectionWritingModalDidCallback} />

        </div>
    )

}