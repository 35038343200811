import {BaseModal, BaseModalActions} from "../../alertmodal/BaseModal";
import {useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import {AppUser} from "../../../util/AppUser";
import {Toast} from "../../toast/TokyoToaster";
import {DataManager} from "../../../data/DataManager";

export const AccountDeactivationPromptModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [checkChecked, setCheckChecked] = useState(false);
    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [forceDismiss, setForceDismiss] = useState(false);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function requestDeactivationOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const secureLoginData = DataManager.getSecureLoginData();

        const formData = new FormData();
        if (secureLoginData && secureLoginData.identifier) {
            formData.append("secureLoginIdentifier", secureLoginData.identifier);
        }

        Axios.post(ENDPOINTS.user.requestUserDeactivation, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("settings.account_deactivation_confirm"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    DataManager.clearSecureLoginData();
                    AppUser.signOut();
                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("RUD1000C"));
            });
    }

    // RENDER

    if (!shown) return [];

    let buttonElems = <LoadingSpinner inline={true} small={true} />;
    if (!networkInFlight) {
        let submitButton = (<button className={"alert-dialog-button danger disabled"}>{Rosetta.string("settings.account_deactivation_submit")}</button>);
        if (checkChecked) {
            submitButton = (<button className={"alert-dialog-button danger"} onClick={() => requestDeactivationOverNetwork()}>{Rosetta.string("settings.account_deactivation_submit")}</button>);
        }

        buttonElems = (
            <div className={"alert-modal-dialog-buttons"}>
                {submitButton}
                <button className={"alert-dialog-button"} onClick={() => handleCallback(BaseModalActions.CLOSE)}>{Rosetta.string("common.cancel")}</button>
            </div>
        );
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"alert-modal-dialog-title"}>
                        {Rosetta.string("settings.account_deactivation_title")}
                    </div>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert-modal-dialog-message"}>
                        {Rosetta.string("settings.account_deactivation_message")}
                    </div>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label><input type={"checkbox"} checked={checkChecked} onChange={(e) => setCheckChecked(e.target.checked)} /> {Rosetta.string("settings.account_deactivation_accept")}</label>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    {buttonElems}
                </div>
            </div>

        </BaseModal>
    )
}