import {BaseModal, BaseModalSizes} from "../../alertmodal/BaseModal";
import React, {useEffect, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import WindowUtil from "../../../util/WindowUtil";
import HTMLReactParser from "html-react-parser";

export const ClinicalStandardModal = (props) => {

    const {shown} = props;
    const {text} = props;
    const {organisationText} = props;
    const {callback} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    // RENDER

    if (!shown) return [];

    // Convert from string to DOM
    let useText = text;
    let useOrgText = organisationText;
    try {
        useText = HTMLReactParser(useText);

        if (useOrgText) {
            useOrgText = HTMLReactParser(useOrgText);
        }
    } catch (e) {
        console.log(e);
    }

    // Check if Organisation Text exists
    let organisationElem = [];
    if (organisationText && organisationText.trim() !== "") {
        organisationElem = [
            <div className={"text-center mt-2"}>
                <strong>{Rosetta.string("case_record.section_clinical_standards_organisation")}</strong>
            </div>,
            <div className={"card"}>
                <div className={"card-body"}>
                    {useOrgText}
                </div>
            </div>
        ];
    }

    return (
        <BaseModal
            shown={true}
            size={BaseModalSizes.LARGE}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"alert-modal-dialog-title"}>
                {Rosetta.string("case_record.section_clinical_standards")}
            </div>

            <div className={"alert-modal-dialog-message"}>
                <div className={"card"}>
                    <div className={"card-body text-start"}>
                        {useText}
                    </div>
                </div>

                {organisationElem}
            </div>

            <div className={"alert-modal-dialog-buttons"}>
                <button className={"alert-dialog-button"} onClick={() => setForceDismiss(true)}>{Rosetta.string("common.close")}</button>
            </div>

        </BaseModal>
    )

}