import Axios from "axios";
import {API, ENDPOINTS} from "../network/API";
import {CommonUtil} from "./CommonUtil";

export const AnalyticsTags = {
    TAG_CASE_RECORD_SEGMENT_VIEW : "case_record.segment_view",
    TAG_URGENCY_TABLE_VIEW : "case_record.urgency_table_view",
    TAG_ASA_GUIDANCE_VIEW : "case_record.asa_guidance_view",
    TAG_RCS_SURGICAL_CATEGORIES_VIEW : "case_record.surgical_categories_view",
    TAG_HIGH_RISK_DEFINITION_GUIDANCE_VIEW : "case_record.high_risk_definition_guidance_view",
    TAG_CLINICAL_STANDARD_VIEW : "case_record.clinical_standard_view"
};

export const Analytics = {
    track : (tag, metadata) => {
        if (tag) {
            const data = new FormData();
            data.append("tag", tag);
            if (metadata) {
                if (CommonUtil.isObject(metadata)) {
                    metadata = JSON.stringify(metadata);
                }
                data.append("metadata", metadata);
            }

            Axios.post(ENDPOINTS.analytics.track, data)
                .then((r) => {
                    const resp = API.parse(r);
                    if (resp.success) {
                        console.log("ANALYTICS: Tracked tag: " + tag, metadata);
                    } else {
                        console.log("ANALYTICS ERROR! " + API.formatError(resp));
                    }
                })
                .catch((e) => {
                    console.log("ANALYTICS ERROR!", e);
                });
        }
    }
}