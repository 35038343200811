import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";

import "./CaseRecordListScreen.css";

import IconGood from "../../../assets/images/icon_good.svg";
import IconImprove from "../../../assets/images/icon_improve.svg";
import {ImageUtil} from "../../../util/ImageUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import {PlaceholderBlock} from "../../placeholder/PlaceholderBlock";
import {CaseRecordUtil} from "../../../util/CaseRecordUtil";
import {Navigator} from "../../../util/Navigator";
import {NavigationUtil} from "../../../util/NavigationUtil";
import {DataManager} from "../../../data/DataManager";
import {FirebaseUtil} from "../../../util/FirebaseUtil";
import {CaseRecordFilterModal} from "./CaseRecordFilterModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";
import {Chronos} from "../../../chronos/Chronos";
import {CaseRecordStructureSelectionModal} from "./structure/CaseRecordStructureSelectionModal";
import {FilterText} from "../../filter/FilterText";
import WindowUtil from "../../../util/WindowUtil";

export const CaseRecordListScreen = (props) => {

    // const [departmentId, setDepartmentId] = useState(1);
    // const [locationId, setLocationId] = useState(-1);

    const [caseRecords, setCaseRecords] = useState([]);
    const [filterCount, setFilterCount] = useState(0);
    const [listNetworkInFlight, setListNetworkInFlight] = useState(false);

    // const [locations, setLocations] = useState([]);
    // const [locationNetworkInFlight, setLocationNetworkInFlight] = useState(false);

    const [filterShown, setFilterShown] = useState(false);
    const [filterData, setFilterData] = useState(null);

    const [filterTypes, setFilterTypes] = useState([]);
    const [filterTypesNetworkInFlight, setFilterTypesNetworkInFlight] = useState(false);

    const [keyword, setKeyword] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [filterItemShown, setFilterItemShown] = useState(false);
    const [filterItem, setFilterItem] = useState(null);
    const [filterType, setFilterType] = useState(null);
    const [filterValue, setFilterValue] = useState("");
    const [filterStartDate, setFilterStartDate] = useState("");
    const [filterEndDate, setFilterEndDate] = useState("");

    const [firstRun, setFirstRun] = useState(true);

    const fragmentNetworkInFlight = useRef(false);

    useEffect(() => {
        NavigationUtil.setSection(NavigationUtil.SECTIONS.PATIENTS);

        Navigator.scrollTop();

        if (!DataManager.getFCMTokenUploaded()) {
            FirebaseUtil.getFCMToken((token) => {
                console.log("Result: " + token);
            });
        }

        //fetchLocationsFromNetwork();
        fetchFilterTypesOverNetwork();
        // fetchCaseRecordsFromNetwork();
    }, []);

    useEffect(() => {
        if (filterItem !== null) {
            if (filterType === CaseRecordUtil.filterTypes.DATE_BETWEEN) {
                if (
                    filterStartDate && filterStartDate !== ""
                    && filterEndDate && filterEndDate !== ""
                ) {
                    fetchCaseRecordsFromNetwork();
                }
            } else {
                console.log("Filter item was not null. Value: " + filterValue);
                if (filterValue && filterValue !== "") {
                    fetchCaseRecordsFromNetwork();
                }
            }
        }
    }, [
        filterData,
        filterItem, filterType, filterValue,
        filterStartDate, filterEndDate
    ]); // departmentId, locationId,

    useEffect(() => {
        fetchCaseRecordsFromNetwork();
    }, [keyword, startDate, endDate]);

    function moveToFilters() {
        Navigator.navigate("/caseRecordFilter");
    }

    function generateCaseRecordItem(caseRecord) {
        let titleContent;
        let statusBadge;
        let contentBadge;
        let dischargeBadge;

        let extraClass = "";
        let onClick = undefined;

        if (caseRecord) {
            let newBadgeElem = [];
            if (parseInt(caseRecord.userViewed) === 0) {
                newBadgeElem = <div className={"new-badge"} />;
            }

            let publishDate = caseRecord.recordDate;
            if (caseRecord.datePublished !== null) {
                publishDate = caseRecord.datePublished;
            }

            let dischargeStatusLabel = Rosetta.string("case_record.case_record_discharge_status_inpatient");
            if (caseRecord.dischargeStatus !== null) {
                let dischargeStatus = parseInt(caseRecord.dischargeStatus);
                if (dischargeStatus === 1) {
                    dischargeStatusLabel = Rosetta.string("case_record.case_record_discharge_status_alive");
                } else if (dischargeStatus === 0) {
                    dischargeStatusLabel = Rosetta.string("case_record.case_record_discharge_status_mortality");
                } else if (dischargeStatus === 60) {
                    dischargeStatusLabel = Rosetta.string("case_record.case_record_discharge_status_locked");
                }
            }
            dischargeBadge = (
                <div className={"badge text-bg-dark text-wrap"}>
                    {Rosetta.string("case_record.case_record_discharge_status", { label : dischargeStatusLabel})}
                </div>
            )

            titleContent = [
                newBadgeElem,
                <div className={"title-area-content"}>
                    <div className={"case-record-title"}>{caseRecord.patientName}</div>
                    <div>{CaseRecordUtil.formatTimeDisplay(publishDate, "case_record.list_item_updated")}</div>
                </div>
            ]

            statusBadge = (
                <div className={"badge badge-status"}>{caseRecord.recordStageId} - {caseRecord.recordStage}</div>
            )

            contentBadge = [
                <div className={"badge badge-all-good me-1"}>
                    <span className={"badge-icon"} style={{backgroundImage : ImageUtil.background(IconGood)}} />
                    {Rosetta.string("case_record.list_item_all_good", {count : caseRecord.positiveResults})}
                </div>,
                <div className={"badge badge-improve"}>
                    <span className={"badge-icon"} style={{backgroundImage : ImageUtil.background(IconImprove)}} />
                    {Rosetta.string("case_record.list_item_improvement", {count : caseRecord.negativeResults})}
                </div>
            ]

            extraClass = "clickable";

            onClick = () => {
                moveToCaseRecord(caseRecord.id);
            };
        } else {
            titleContent = <PlaceholderBlock className={"medium"} />;
            statusBadge = <PlaceholderBlock className={"medium"} />
            contentBadge = [];
            dischargeBadge = [];
        }

        return (
            <div className={"col-12 col-lg-6 mb-2"}>
                <div className={"card case-record-item " + extraClass} onClick={onClick}>
                    <div className={"card-body"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"title-area"}>
                                    {titleContent}
                                </div>
                            </div>
                        </div>

                        <div className={"row badge-row mt-1"}>
                            <div className={"col-12"}>
                                {statusBadge}
                            </div>
                        </div>

                        <div className={"row badge-row mt-1"}>
                            <div className={"col-12"}>
                                {contentBadge}
                            </div>
                        </div>

                        <div className={"row badge-row mt-1"}>
                            <div className={"col-12"}>
                                {dischargeBadge}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function moveToCaseRecord(caseRecordId) {
        CaseRecordUtil.clearCaseRecordCache();
        Navigator.navigate("/caseRecord/" + 0 + "/" + caseRecordId);
    }

    function summonFilterModal() {
        setFilterShown(true);
    }

    function filterModalDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setFilterData(data);
            }
            setFilterShown(false);
        }
    }

    function summonFilerItemModal() {
        setFilterItemShown(true);
    }

    function summonFilterItemDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setFilterItemShown(false);

            if (data) {
                setFilterItem(data);
            }
        }
    }

    function clearFilterItem() {
        setFilterItem(null);
    }

    function fetchCaseRecordsFromNetwork() {
        if (listNetworkInFlight || fragmentNetworkInFlight.current) return;
        setListNetworkInFlight(true);
        fragmentNetworkInFlight.current = true;

        console.log("FETCH FRAGMENTS...");

        const formData = new FormData();
        formData.append("departmentId", 0);
        formData.append("locationId", -1);

        if (keyword && keyword !== "") {
            formData.append("keyword", keyword);
        }

        if (startDate && startDate !== "") {
            try {
                const date = Chronos.parse(startDate).seconds();
                formData.append("startDate", date);
            } catch (e) {
                console.log(e);
            }
        }

        if (endDate && endDate !== "") {
            try {
                const date = Chronos.parse(endDate).seconds();
                formData.append("endDate", date);
            } catch (e) {
                console.log(e);
            }
        }

        if (filterData) {
            if (filterData.patientId) {
                formData.append("patientId", filterData.patientId);
            }

            if (filterData.startDate) {
                formData.append("startDate", Chronos.with(new Date(filterData.startDate)).seconds());
            }

            if (filterData.endDate) {
                formData.append("endDate", Chronos.with(new Date(filterData.endDate)).seconds());
            }
        }

        if (filterItem !== null || (filterValue !== null && filterValue !== "") || (filterStartDate !== "" && filterEndDate !== null)) {
            let filter = {
                caseRecordFilterTypeId : filterType
            };

            if (parseInt(filterType) === CaseRecordUtil.filterTypes.DATE_BETWEEN) {
                console.log("FILTER TYPE IS DATE BETWEEN");
                if (filterStartDate !== "" && filterEndDate !== "") {
                    console.log("BOTH FILTER DATES ARE NOT BLANK");
                    try {
                        let filterSDate = Chronos.parse(filterStartDate).seconds();
                        console.log("FS:", filterSDate);
                        let filterEDate = Chronos.parse(filterEndDate).seconds();
                        console.log("FE:", filterEDate);

                        filter.startDate = filterSDate;
                        filter.endDate = filterEDate;
                    } catch (e) {
                        console.log(e);
                    }
                }
            } else {
                if (filterValue !== null && filterValue !== "") {
                    filter.value = filterValue
                }
            }

            if (filterItem) {
                filter.caseRecordSegmentSectionItemId = filterItem.id;
            }

            if (filter.hasOwnProperty("value") || (filter.hasOwnProperty("startDate") && filter.hasOwnProperty("endDate"))) {
                formData.append("filter", JSON.stringify(filter));
            }
        }

        Axios.post(ENDPOINTS.caseRecord.getFragments, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setCaseRecords(resp.data.fragments);
                    setFilterCount(resp.data.filterCount);

                    setFirstRun(false);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setListNetworkInFlight(false);
                fragmentNetworkInFlight.current = false;
            })
            .catch((e) => {
                console.log(e);
                setListNetworkInFlight(false);
                fragmentNetworkInFlight.current = false;
                AlertModal.showError(API.defaultError("CRF1000C"));
            })
    }

    // function fetchLocationsFromNetwork() {
    //     if (locationNetworkInFlight) return;
    //     setLocationNetworkInFlight(true);
    //
    //     Axios.get(ENDPOINTS.user.fetchLocations)
    //         .then((r) => {
    //             const resp = API.parse(r);
    //             if (resp.success) {
    //                 setLocations(resp.data.locations);
    //             } else {
    //                 console.log(API.formatError(resp));
    //             }
    //             setLocationNetworkInFlight(false);
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //             setLocationNetworkInFlight(false);
    //         });
    // }

    function fetchFilterTypesOverNetwork() {
        if (filterTypesNetworkInFlight) return;
        setFilterTypesNetworkInFlight(true);

        const formData = new FormData();

        Axios.post(ENDPOINTS.caseRecord.getCaseRecordFilterTypes, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setFilterTypes(resp.data.types);

                    if (resp.data.types.length > 0 && filterType === null) {
                        setFilterType(resp.data.types[0]);
                    }

                    if (firstRun) {
                        fetchCaseRecordsFromNetwork();
                    }
                } else {
                    console.log(API.formatError(resp));
                }
                setFilterTypesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFilterTypesNetworkInFlight(false);
            });
    }

    // RENDER

    let listContent = [];
    let filterContent = [];

    if (!listNetworkInFlight) {
        caseRecords.forEach((caseRecord) => {
            listContent.push(generateCaseRecordItem(caseRecord));
        });

        if (listContent.length === 0) {
            listContent = (
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <strong>{Rosetta.string("case_record.list_empty")}</strong>
                    </div>
                </div>
            )
        }
    } else {
        for (let i = 0; i < 4; i++) {
            listContent.push(generateCaseRecordItem());
        }
    }

    // if (!firstRun) {
        let filterItemText = Rosetta.string("case_record.list_search_item_any");
        let filterItemClear = [];
        if (filterItem) {
            filterItemText = filterItem.title;
            filterItemClear = (
                <div className={"input-group-text clickable"} onClick={() => clearFilterItem()}>
                    {Rosetta.string("common.clear")}
                </div>
            );
        }

        let filterTextInput = (
            <div className={"col-12 col-md-6"}>
                <FilterText showPrefix={false} callback={(keyword) => setFilterValue(keyword)} />
            </div>
        );
        let filterDateInputs = [];

        if (parseInt(filterType) === CaseRecordUtil.filterTypes.DATE_BETWEEN) {
            filterTextInput = [];

            filterDateInputs = (
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <label>{Rosetta.string("case_record.filter_date_start")}</label>
                        <input type={"date"} className="form-control" value={filterStartDate} onChange={(e) => { if (e.target.value !== "") setFilterStartDate(e.target.value) }} />
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <label>{Rosetta.string("case_record.filter_date_end")}</label>
                        <input type={"date"} className="form-control"  value={filterEndDate} onChange={(e) => setFilterEndDate(e.target.value)} />
                    </div>
                </div>
            );
        }

        filterContent = (
            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-10 col-lg-8 col-xl-6"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label className={"mt-0"}>{Rosetta.string("case_record.list_keyword")}</label>
                                    <FilterText callback={(text) => setKeyword(text)}/>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label className={"mt-0"}>{Rosetta.string("case_record.list_date_start")}</label>
                                    <input type={"date"} className={"form-control"} value={startDate}
                                           onChange={(e) => setStartDate(e.target.value)}/>
                                </div>

                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label className={"mt-0"}>{Rosetta.string("case_record.list_date_end")}</label>
                                    <input type={"date"} className={"form-control"} value={endDate}
                                           onChange={(e) => setEndDate(e.target.value)}/>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12 text-center"}>
                                    <label>{Rosetta.string("case_record.list_search")}</label>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <div className={"input-group"}>
                                        <div className={"form-control clickable"}
                                             onClick={() => summonFilerItemModal()}>{filterItemText}</div>
                                        {filterItemClear}
                                    </div>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12 col-md-6"}>
                                    <select className={"form-select"} value={filterType}
                                            onChange={(e) => setFilterType(e.target.value)}>
                                        {filterTypes.map((type) => (
                                            <option value={type.id}>{type.name}</option>
                                        ))}
                                    </select>
                                </div>

                                {filterTextInput}
                            </div>

                            {filterDateInputs}
                        </div>

                        <div className={"card-footer"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <button className={"btn btn-primary full-width"} onClick={() => moveToFilters()}>
                                        {Rosetta.string("case_record.list_filter", { count : filterCount}, filterCount)}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    // }

    return (
        <div className={"case-record-list-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("case_record.list_title")} />
                </div>
            </div>

            <div className={"app-screen animate-screen-content"}>

                {filterContent}

                <div className={"row mt-4"}>
                    {listContent}
                </div>

            </div>

            <CaseRecordFilterModal
                shown={filterShown}
                data={filterData}
                callback={filterModalDidCallback} />

            <CaseRecordStructureSelectionModal
                shown={filterItemShown}
                callback={summonFilterItemDidCallback} />

        </div>
    )

}

/*

<div className={"row mt-2 justify-content-center"}>
    <div className={"col-12 col-md-10 col-lg-8"}>
        <div className={"card"}>
            <div className={"card-body"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"d-flex"}>
                            <div className={"flex-fill"}>
                                <label className={"mt-0"}>{Rosetta.string("case_record.list_location_filter")}</label>
                                <select className={"form-select"} value={locationId} onChange={(e) => setLocationId(e.target.value)}>
                                    <option value={"-1"}>{Rosetta.string("case_record.list_location_all")}</option>
                                    {
                                        locations.map((location) => (
                                            <option value={location.id}>{location.name}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className={"ms-2"}>
                                <label className={"mt-0"}>&nbsp;</label><br />
                                <button className={"btn btn-primary"} onClick={() => summonFilterModal()}>{Rosetta.string("case_record.filter_title")}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

 */