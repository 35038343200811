import {useEffect, useState} from "react";
import {CaseRecordStructureSelectionModal} from "../structure/CaseRecordStructureSelectionModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";
import Rosetta from "../../../../rosetta/Rosetta";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import Validator from "../../../../util/Validator";
import {Toast} from "../../../toast/TokyoToaster";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";

import deleteIcon from "../../../../assets/images/delete.svg";
import {ImageUtil} from "../../../../util/ImageUtil";

import "./CaseRecordFilterScreen.css";

export const CaseRecordFilterScreen = (props) => {

    const [filters, setFilters] = useState([]);
    const [filterTypes, setFilterTypes] = useState([]);
    const [filterNetworkInFlight, setFilterNetworkInFlight] = useState(false);

    const [filterItem, setFilterItem] = useState(null);
    const [filterType, setFilterType] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [structureModalShown, setStructureModalShown] = useState(false);

    useEffect(() => {
        fetchCaseRecordFiltersFromNetwork();
    }, []);

    function summonCaseRecordStructureModal() {
        setStructureModalShown(true);
    }

    function structureDidCallback(action, data) {
        console.log("Structure callback", action);
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setFilterItem(data);
            }
            setStructureModalShown(false);
        }
    }

    function promptRemoveFiler(filterId) {
        AlertModal.showModal(
            Rosetta.string("case_record.filter_remove_prompt_title"),
            Rosetta.string("case_record.filter_remove_prompt_message"),
            [
                AlertModal.button(
                    Rosetta.string("case_record.filter_remove_prompt_title"),
                    () => {
                        AlertModal.dismissModal();
                        deleteFilterOverNetwork(filterId);
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        );
    }

    function fetchCaseRecordFiltersFromNetwork() {
        if (filterNetworkInFlight) return;
        setFilterNetworkInFlight(true);

        const formData = new FormData();
        // TODO Filter

        Axios.post(ENDPOINTS.user.getCaseRecordFilters, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setFilters(resp.data.data);
                    setFilterTypes(resp.data.types);

                    if (filterType === null) {
                        if (resp.data.types && resp.data.types.length > 0) {
                            setFilterType(resp.data.types[0].id);
                        }
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setFilterNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFilterNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UCRF1000C"));
            });
    }

    function submitCaseRecordFilterOverNetwork() {
        if (submitNetworkInFlight) return;

        const validationState = {
            itemId : filterItem ? filterItem.id : undefined,
            filterType, filterValue
        };

        if (validationState.filterType === null) {
            if (filterTypes && filterTypes.length > 0) {
                validationState.filterType = filterTypes[0].id
            }
        }

        const validationData = Validator.validateCreateFormData(validationState, [
            Validator.rule("itemId", "int", Rosetta.string("case_record.filter_create_item"), "caseRecordSegmentSectionItemId", false),
            Validator.rule("filterType", "int", Rosetta.string("case_record.filter_create_type"), "caseRecordFilterTypeId", false),
            Validator.rule("filterValue", "string", Rosetta.string("case_record.filter_create_value"), "value", false)
        ]);

        if (!validationData.success) {
            AlertModal.showError(validationData.error);
            return;
        }

        setSubmitNetworkInFlight(true);

        const formData = validationData.formData;

        Axios.post(ENDPOINTS.user.submitCaseRecordFilter, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    fetchCaseRecordFiltersFromNetwork();

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("case_record.filter_create_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("SCRF1000C"));
            })
    }

    function deleteFilterOverNetwork(filterId) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        const formData = new FormData();
        formData.append("userCaseRecordFilterId", filterId);

        Axios.post(ENDPOINTS.user.deleteCaseRecordFilter, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    fetchCaseRecordFiltersFromNetwork();

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("case_record.filter_remove_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UCRFD1000"));
            });
    }

    // RENDER

    let filterItemElem = (<div>{Rosetta.string("case_record.filter_select_item")}</div>);
    if (filterItem) {
        //<div><strong>{filterItem.segmentName} - {filterItem.sectionTitle}</strong></div>
        filterItemElem = (
            <div>
                <div>{filterItem.title}</div>
            </div>
        );
    }

    let listContent = [];
    if (filterNetworkInFlight) {
        listContent = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} />
                </div>
            </div>
        );
    } else if (filters.length > 0) {
        listContent = filters.map((filter) => (
            <li className={"list-group-item filter-item"}>
                <div className={"filter-item-content"}>
                    <div><span className={"badge text-bg-secondary"}>{filter.segmentName} - {filter.sectionTitle}</span></div>
                    <div><strong>{filter.title}</strong></div>
                    <div>{filter.filterTypeName} &quot;{filter.value}&quot;</div>
                </div>

                <div
                    className={"filter-item-action ms-1"}
                    style={{backgroundImage : ImageUtil.background(deleteIcon)}}
                    onClick={() => promptRemoveFiler(filter.id)} />
            </li>
        ));

        if (listContent.length > 0) {
            listContent = (
                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-md-10 col-lg-8"}>
                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                {Rosetta.string("case_record.filter_list_title")}
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <ul className={"list-group"}>
                                    {listContent}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={"app-screen case-record-filter-screen"}>
            <div className={"row justify-content-center"}>
                <div className={"col-12 col-md-10 col-lg-8"}>

                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <h5>{Rosetta.string("case_record.filter_create_title")}</h5>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("case_record.filter_create_item")}</label>
                                    <div className={"list-group"}>
                                        <div className={"list-group-item clickable"} onClick={() => summonCaseRecordStructureModal()}>
                                            {filterItemElem}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 col-lg-6"}>
                                    <label>{Rosetta.string("case_record.filter_create_type")}</label>
                                    <select className={"form-select"} value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                                        {
                                            filterTypes.map((type) => (
                                                <option value={type.id}>{type.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className={"col-12 col-lg-6"}>
                                    <label>{Rosetta.string("case_record.filter_create_value")}</label>
                                    <input type={"text"} className={"form-control"} value={filterValue} onChange={(e) => setFilterValue(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <button className={"btn btn-primary full-width"} onClick={() => submitCaseRecordFilterOverNetwork()}>
                                        {Rosetta.string("case_record.filter_create_submit")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {listContent}

            <CaseRecordStructureSelectionModal
                shown={structureModalShown}
                callback={structureDidCallback} />

        </div>
    )

}