import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {Button, Check, CheckList, Container, DropDown, Former, FormerActions, Text} from "../../form/Former";
import {Chronos} from "../../../chronos/Chronos";
import {ReflectionUtil} from "../../../util/ReflectionUtil";
import HTMLReactParser from "html-react-parser";
import WindowUtil from "../../../util/WindowUtil";
import Validator from "../../../util/Validator";
import {CommonUtil} from "../../../util/CommonUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import {AlertModal} from "../../alertmodal/AlertModal";

export const SegmentReflectionWritingTemplateModal = (props) => {

    const {shown} = props;
    const {data} = props;
    const {callback} = props;

    const [reflection, setReflection] = useState(null);

    const [formState, setFormState] = useState({});
    const [forceDismiss, setForceDismiss] = useState(false);

    const [pathways, setPathways] = useState([]);
    const [clinicalPractices, setClinicalPractices] = useState([]);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        setPathways(ReflectionUtil.getPathways());
    }, []);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            setReflection(null);
            setFormState({});
            setForceDismiss(false);
            setClinicalPractices([]);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function formDidCallback(action, data) {
        if (action === FormerActions.SUBMIT) {
            if (data.success) {
                submitReflectionOverNetwork(data.data);
            }
        } else if (action === FormerActions.CHANGE) {
            if (data.name === "pathway") {
                getClinicalPracticesForPathway(data.value);
            }
        }
    }

    function getClinicalPracticesForPathway(pathway) {
        let out = [];

        let index = null;
        let count = 0;
        if (pathway === pathways[0]) {
            index = 1;
            count = 14;
        } else if (pathway === pathways[1]) {
            index = 2;
            count = 12;
        } else if (pathway === pathways[2]) {
            index = 3;
            count = 24;
        }

        if (index !== null && count > 0) {
            for (let i = 1; i <= count; i++) {
                out.push(Rosetta.string("reflection.editor_writing_template_clinical_practice_" + index + "_" + i));
            }
        }

        setClinicalPractices(out);
    }

    function submitReflectionOverNetwork(state) {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const networkData = CommonUtil.cloneObject(data);
        networkData.data = JSON.stringify(state);

        if (reflection) {
            networkData.id = reflection.id;
        }

        const validationResult = Validator.validateCreateFormData(networkData, [
            Validator.rule("id", "int", "", "id", true),
            Validator.rule("reflectionFeedbackTypeId", "int", "Reflection Feedback Type ID", "reflectionFeedbackTypeId", false),
            Validator.rule("caseRecordId", "int", "Case Record ID", "caseRecordId", false),
            Validator.rule("reflectionTypeId", "int", "Reflection Type ID", "reflectionTypeId", false),
            Validator.rule("segmentId", "int", "Segment ID", "segmentId", false),
            Validator.rule("data", "string", "Data", "data", false)
        ]);

        Axios.post(ENDPOINTS.reflection.submitReflection, validationResult.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("reflection.editor_feedback_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setReflection(resp.data.reflection);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("REFS2000C"));
            });
    }

    // RENDER

    if (!shown) return [];

    const additionalButtons = [];

    if (reflection) {
        additionalButtons.push(
            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-primary"}>Download PDF</button>
                </div>
            </div>
        )
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("reflection.editor_title_private_reflection")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <Former
                state={formState}
                callback={formDidCallback}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                {Rosetta.string("reflection.editor_reflection_date", { date : Chronos.now().format("dd/MM/yyyy HH:mm")})}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"alert alert-info"}>
                            {Rosetta.string("reflection.editor_writing_template_prelude")}
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <strong>{Rosetta.string("reflection.editor_writing_template_background_info")}</strong><br />
                        <p>{Rosetta.string("reflection.editor_writing_template_background_info_1")}</p>
                        <p>{Rosetta.string("reflection.editor_writing_template_background_info_2")}</p>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <strong>{Rosetta.string("reflection.editor_writing_template_purpose_label")}</strong><br />
                        <p>{Rosetta.string("reflection.editor_writing_template_purpose_1")}</p>
                        <p>{Rosetta.string("reflection.editor_writing_template_purpose_2")}</p>
                        <p>{Rosetta.string("reflection.editor_writing_template_purpose_3")}</p>
                        <p>{Rosetta.string("reflection.editor_writing_template_purpose_4")}</p>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <strong>{Rosetta.string("reflection.editor_writing_template_rolfe_framework_label")}</strong><br />
                        <ul>
                            <li>{Rosetta.string("reflection.editor_writing_template_rolfe_framework_1")}</li>
                            <li>{Rosetta.string("reflection.editor_writing_template_rolfe_framework_2")}</li>
                            <li>{Rosetta.string("reflection.editor_writing_template_rolfe_framework_3")}</li>
                        </ul>
                    </div>
                </div>

                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <DropDown
                            name={"pathway"}
                            mandatory={true}
                            label={Rosetta.string("reflection.editor_writing_template_pathway_label")}
                            className={"form-select"}>

                            {
                                pathways.map((pathway) => (
                                    <option value={pathway}>{pathway}</option>
                                ))
                            }

                        </DropDown>
                    </Container>
                </Container>

                <Container className={"row"}>
                    <Container className={"col-12"}>
                        <CheckList
                            name={"clinical_practice"}
                            label={Rosetta.string("reflection.editor_writing_template_clinical_practice_label")}
                            mandatory={true}
                            className={"form-select"}>

                            {
                                clinicalPractices.map((practice) => (
                                    <Check value={practice}>{practice}</Check>
                                ))
                            }

                        </CheckList>
                    </Container>
                </Container>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <h5>{Rosetta.string("reflection.editor_writing_template_rolfe_framework_1")}</h5>
                        {HTMLReactParser(Rosetta.string("reflection.editor_what_list_html"))}
                    </div>
                </div>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Text
                            name={"input_what"}
                            label={Rosetta.string("reflection.editor_what_label")}
                            className={"form-control"}
                            multiline={true}
                            mandatory={true} />
                    </Container>
                </Container>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <h5>{Rosetta.string("reflection.editor_writing_template_rolfe_framework_2")}</h5>
                        {HTMLReactParser(Rosetta.string("reflection.editor_so_what_list_html"))}
                    </div>
                </div>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Text
                            name={"input_so_what"}
                            label={Rosetta.string("reflection.editor_so_what_label")}
                            className={"form-control"}
                            multiline={true}
                            mandatory={true} />
                    </Container>
                </Container>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <h5>{Rosetta.string("reflection.editor_writing_template_rolfe_framework_3")}</h5>
                        {HTMLReactParser(Rosetta.string("reflection.editor_now_what_list_html"))}
                    </div>
                </div>

                <Container className={"row mt-2"}>
                    <Container className={"col-12"}>
                        <Text
                            name={"input_now_what"}
                            label={Rosetta.string("reflection.editor_now_what_label")}
                            className={"form-control"}
                            multiline={true}
                            mandatory={true} />
                    </Container>
                </Container>

                <Container className={"row mt-4"}>
                    <Container className={"col-12 text-center"}>
                        <Button
                            className={"btn btn-success"}
                            label={Rosetta.string("common.save")} />
                    </Container>
                </Container>

            </Former>

            {additionalButtons}

        </Offcanvas>
    )

}