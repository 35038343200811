import "./PlaceholderBlock.css";

export const PlaceholderBlock = (props) => {

    let className = "";
    if (props.className !== undefined) {
        className = " " + props.className;
    }

    return (
        <div className={"placeholder-block" + className} />
    )

}