import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {ReflectionUtil} from "../../../util/ReflectionUtil";
import {Chronos} from "../../../chronos/Chronos";
import {DateUtil} from "../../../util/DateUtil";
import Validator from "../../../util/Validator";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import {LoadingSpinner} from "../../loading/LoadingSpinner";

export const ReflectionReminderModal = (props) => {

    const {shown} = props;
    const {callback} = props;
    const {reflection} = props;
    const {reminder} = props;

    const [reminderTypeId, setReminderTypeId] = useState(ReflectionUtil.reminderTypes.ONCE);
    const [reminderDate, setReminderDate] = useState("");
    const [reminderTime, setReminderTime] = useState("");
    const [comments, setComments] = useState("");

    const [error, setError] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            if (reminder) {
                setReminderTypeId(reminder.reminderTypeId);
                setReminderDate(Chronos.withTimestampSeconds(reminder.reminderDate).format("yyyy-MM-dd"));
                setReminderTime(Chronos.withTimestampSeconds(DateUtil.secondStringToDate(reminder.reminderTime)).format("HH:mm"));
                setComments(reminder.comments);
            }
        } else {
            setNetworkInFlight(false);
            setForceDismiss(false);

            setReminderTypeId(ReflectionUtil.reminderTypes.ONCE);
            setReminderDate("");
            setReminderTime("");
            setComments("");
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function submitReminderOverNetwork() {
        if (networkInFlight) return;

        const result = Validator.validateCreateFormData({
            reminderId : reminder ? reminder.reminderId : undefined,
            reflectionId : reflection ? reflection.id : undefined,
            reminderTypeId, reminderDate, reminderTime, comments
        }, [
            Validator.rule("reminderId", "int", "", "reminderId", true),
            Validator.rule("reflectionId", "int", "", "reflectionId", true),
            Validator.rule("reminderTypeId", "int", Rosetta.string("reflection.reminder_type"), "reminderTypeId"),
            Validator.rule("reminderDate", "string", Rosetta.string("reflection.reminder_date"), "reminderDate"),
            Validator.rule("reminderTime", "string", Rosetta.string("reflection.reminder_time"), "reminderTime"),
            Validator.rule("comments", "string", Rosetta.string("reflection.reminder_comments"), "comments")
        ]);

        if (!result.success) {
            setError(result.error);
            return;
        }

        setNetworkInFlight(false);

        let endpointUrl = ENDPOINTS.reflection.setReflectionReminder;
        if (reminder) {
            endpointUrl = ENDPOINTS.reflection.updateReflectionReminder;
        }

        Axios.post(endpointUrl, result.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("reflection.reminder_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setForceDismiss(true);
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setError(API.defaultError("RRS1000C"));
                setNetworkInFlight(false);
            })
    }

    // RENDER

    if (!shown) return [];

    let reminderTypeIds = [
        ReflectionUtil.reminderTypes.ONCE,
        ReflectionUtil.reminderTypes.DAILY,
        ReflectionUtil.reminderTypes.WEEKLY
    ];

    let errorElem = [];
    if (error !== null) {
        errorElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>{error}</div>
                </div>
            </div>
        )
    }

    let submitButton = (<button className={"btn btn-primary full-width"} onClick={() => submitReminderOverNetwork()}>{Rosetta.string("common.submit")}</button>);
    if (networkInFlight) {
        submitButton = (<LoadingSpinner inline={true} small={true} />);
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("reflection.reminder_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("reflection.reminder_type")}</label>
                    <select className={"form-select"} value={reminderTypeId} onChange={(e) => setReminderTypeId(e.target.value)}>
                        {
                            reminderTypeIds.map((type) => (
                                <option value={type}>{ReflectionUtil.getNameForReminderType(type)}</option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("reflection.reminder_date")}</label>
                    <input type={"date"} className={"form-control"} value={reminderDate} onChange={(e) => setReminderDate(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("reflection.reminder_time")}</label>
                    <input type={"time"} className={"form-control"} value={reminderTime} onChange={(e) => setReminderTime(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("reflection.reminder_comments")}</label>
                    <textarea className={"form-control"} value={comments} onChange={(e) => setComments(e.target.value)} />
                </div>
            </div>

            {errorElem}

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {submitButton}
                </div>
            </div>

        </Offcanvas>
    )

}