import {DataManager} from "../data/DataManager";

export const AppUser = {
    roles : {
        SUPER_ADMIN : 1,
        ADMIN : 2,
        USER : 3,
        SUPER_USER : 4,
        SYSTEM_ADMIN : 5
    },
    signOut : () => {
        DataManager.clear()
    },
    isInRole : (userRoleIds) => {
        let out = false;

        const user = DataManager.getUser();

        console.log("USER ROLE: " + user.userRoleId);

        if (user) {
            if (!Array.isArray(userRoleIds)) {
                userRoleIds = [userRoleIds];
            }

            for (let roleId of userRoleIds) {
                if (parseInt(roleId) === parseInt(user.userRoleId)) {
                    out = true;
                    break;
                }
            }
        }

        console.log("In roles? " + out);

        return out;
    },
    getNonWorkerRoles() {
        return [

        ]
    }
}