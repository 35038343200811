import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {CaseRecordUtil} from "../../../util/CaseRecordUtil";
import {Navigator} from "../../../util/Navigator";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {ImageUtil} from "../../../util/ImageUtil";
import {ColourUtil} from "../../../util/ColourUtil";
import {AlertModal} from "../../alertmodal/AlertModal";
import {Chronos} from "../../../chronos/Chronos";

import "./CaseRecordSectionScreen.css";

import IconGood from "../../../assets/images/icon_good.svg";
import IconImprove from "../../../assets/images/icon_improve.svg";
import IconOverflow from "../../../assets/images/icon_more.svg";
import {CommonUtil} from "../../../util/CommonUtil";
import {ReflectionEditorModal} from "../reflections/ReflectionEditorModal";
import {ReflectionUtil} from "../../../util/ReflectionUtil";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";
import {NavigationUtil} from "../../../util/NavigationUtil";
import {SegmentReflectionEditorModal} from "../reflections/SegmentReflectionEditorModal";
import {SegmentReflectionWritingTemplateModal} from "../reflections/SegmentReflectionWritingTemplateModal";
import {ClinicalStandardModal} from "./ClinicalStandardModal";
import {BaseModalActions} from "../../alertmodal/BaseModal";
import {CaseRecordSegmentNavigationLink} from "./CaseRecordSegmentNavigationLink";

import arrowLeft from "../../../assets/images/arrow_left.svg";
import arrowRight from "../../../assets/images/arrow_right.svg";
import {ASAGuidanceModal} from "./info/ASAGuidanceModal";
import {UrgencyDialog} from "./info/UrgencyDialog";
import {Toast} from "../../toast/TokyoToaster";
import {PDFViewerModal} from "../../pdf/PDFViewerModal";
import {AppConfig} from "../../../util/AppConfig";
import {API} from "../../../network/API";
import {Analytics, AnalyticsTags} from "../../../util/Analytics";

export const CaseRecordSectionScreen = (props) => {

    const {departmentId} = useParams();
    const {caseRecordId} = useParams();
    const {segmentId} = useParams();

    const [segment, setSegment] = useState(null);
    const [filterType, setFilterType] = useState("-1");

    const [reflectionEditorShown, setReflectionEditorShown] = useState(false);
    const [reflectionEditorData, setReflectionEditorData] = useState(null);

    const [segmentReflectionEditorShown, setSegmentReflectionEditorShown] = useState(false);
    const [segmentReflectionWritingModalShown, setSegmentReflectionWritingModalShown] = useState(false);
    const [segmentReflectionData, setSegmentReflectionData] = useState(null);

    const [clinicalStandardShown, setClinicalStandardShown] = useState(false);
    const [clinicalStandardText, setClinicalStandardText] = useState("");
    const [clinicalStandardOrgText, setClinicalStandardOrgText] = useState(undefined);

    const [asaGuidanceShown, setAsaGuidanceShown] = useState(false);
    const [urgencyDialogShown, setUrgencyDialogShown] = useState(false);

    const [pdfViewerShown, setPdfViewerShown] = useState(false);
    const [pdfViewerUrl, setPdfViewerUrl] = useState(null);
    const [pdfViewerTitle, setPdfViewerTitle] = useState(null);

    const caseRecord = useRef();
    const lastSegmentIdTracked = useRef();

    useEffect(() => {
        NavigationUtil.setSection(NavigationUtil.SECTIONS.PATIENTS);

        trackSegmentView();
        getSegment(true);
    }, []);

    useEffect(() => {
        trackSegmentView();
        getSegment(true);
    }, [segmentId]);

    function trackSegmentView() {
        // There was a problem where we were tracking views more than once for a single load.
        // We now hold what the last segment we tracked was and only send when it changes.
        if (lastSegmentIdTracked.current !== segmentId) {
            lastSegmentIdTracked.current = segmentId;
            Analytics.track(AnalyticsTags.TAG_CASE_RECORD_SEGMENT_VIEW, segmentId);
        }
    }

    function getSegment(scrollToTop) {
        if (scrollToTop === undefined) {
            scrollToTop = false;
        }

        if (caseRecordId) {
            const caseRecordCache = CaseRecordUtil.findCaseRecordInCache(caseRecordId);
            if (caseRecordCache && segmentId) {
                caseRecord.current = caseRecordCache;
                if (caseRecordCache.segments) {
                    for (let i = 0; i < caseRecordCache.segments.length; i++) {
                        let segment = caseRecordCache.segments[i];
                        if (parseInt(segment.id) === parseInt(segmentId)) {
                            // Signal a "view" of the Segment now.
                            setSegment(segment);

                            setTimeout(() => {
                                Navigator.scrollTop();
                            }, 10);
                        }
                    }
                }
            } else {
                Navigator.navigate("/");
            }
        }
    }

    function showClinicalStandards(text, organisationText) {
        // AlertModal.showModal(
        //     Rosetta.string("case_record.section_clinical_standards"),
        //     HTMLReactParser(text),
        //     [
        //         AlertModal.button(
        //             Rosetta.string("common.close"),
        //             () => {
        //                 AlertModal.dismissModal();
        //             }
        //         )
        //     ]
        // );

        summonClinicalStandardModal(text, organisationText);
    }

    function summonReflectionEditor(measure) {
        if (caseRecord.current) {
            const editorData = {};

            if (caseRecord.patient) {
                editorData.patientName = caseRecord.current.patient.name
            }

            if (measure) {
                editorData.measureTitle = measure.title;
                editorData.measureNumber = measure.number;

                if (measure.caseRecordSegmentSectionItemOutcomeTypeId) {
                    editorData.feedbackTypeId = ReflectionUtil.segmentSectionOutcomeTypeToReflectionType(measure.caseRecordSegmentSectionItemOutcomeTypeId);
                }
            }

            setReflectionEditorData(editorData);
        }

        setReflectionEditorShown(true);
    }

    function reflectionEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setReflectionEditorShown(false);
            setReflectionEditorData(null);
        }
    }

    function summonSegmentReflectionEditor(reflectionTypeId) {
        let reflectionFeedbackTypeId = ReflectionUtil.feedbackTypes.NEUTRAL;
        switch (reflectionTypeId) {
            case ReflectionUtil.reflectionTypes.WHAT_WORKED_WELL:
                reflectionFeedbackTypeId = ReflectionUtil.feedbackTypes.POSITIVE; break;
            case ReflectionUtil.reflectionTypes.OPPORTUNITY_TO_IMPROVE:
                reflectionFeedbackTypeId = ReflectionUtil.feedbackTypes.NEGATIVE; break;
            default:
        }

        setSegmentReflectionData({
            reflectionFeedbackTypeId,
            reflectionTypeId,
            caseRecordId,
            caseRecord: caseRecord.current,
            segmentId
        });
        setSegmentReflectionEditorShown(true);
    }

    function segmentReflectionEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setSegmentReflectionEditorShown(false);
        }
    }

    function summonSegmentReflectionWritingEditor() {
        setSegmentReflectionData({
            reflectionFeedbackTypeId : ReflectionUtil.feedbackTypes.NEUTRAL,
            reflectionTypeId : ReflectionUtil.reflectionTypes.RECORD_PRIVATE_REFLECTION,
            caseRecordId,
            caseRecord : caseRecord.current,
            segmentId
        });
        setSegmentReflectionWritingModalShown(true);
    }

    function segmentReflectionWritingModalDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setSegmentReflectionWritingModalShown(false);
        }
    }

    function showTimelineOptions(item) {
        let buttons = [
            AlertModal.button(
                Rosetta.string("case_record.section_timeline_option_reflect"),
                () => {
                    AlertModal.dismissModal();

                    // Wait for the modal to dismiss, then pop again.
                    setTimeout(() => {
                        AlertModal.showModal(
                            Rosetta.string("reflection.editor_title"),
                            Rosetta.string("reflection.editor_type"),
                            [
                                AlertModal.button(
                                    Rosetta.string("case_record.reflection_button_went_well"),
                                    () => {
                                        summonSegmentReflectionEditor(ReflectionUtil.reflectionTypes.WHAT_WORKED_WELL);
                                        AlertModal.dismissModal();
                                    }
                                ),
                                AlertModal.button(
                                    Rosetta.string("case_record.reflection_button_improve"),
                                    () => {
                                        summonSegmentReflectionEditor(ReflectionUtil.reflectionTypes.OPPORTUNITY_TO_IMPROVE);
                                        AlertModal.dismissModal();
                                    }
                                ),
                                AlertModal.button(
                                    Rosetta.string("case_record.reflection_button_service_suggestion"),
                                    () => {
                                        summonSegmentReflectionEditor(ReflectionUtil.reflectionTypes.SERVICE_IMPROVEMENT_SUGGESTION);
                                        AlertModal.dismissModal();
                                    }
                                ),
                                AlertModal.button(
                                    Rosetta.string("case_record.reflection_button_private_reflection"),
                                    () => {
                                        summonSegmentReflectionWritingEditor();
                                        AlertModal.dismissModal();
                                    }
                                ),
                            ]
                        );
                    }, 50);
                }
            )
        ];

        if (item.clinicalStandard && item.clinicalStandard.trim() !== "") {
            buttons.push(
                AlertModal.button(
                    Rosetta.string("case_record.section_timeline_option_clinical_standards"),
                    () => {
                        AlertModal.dismissModal();
                        showClinicalStandards(item.clinicalStandard);
                    }
                )
            )
        }

        buttons.push(
            AlertModal.button(
                Rosetta.string("common.cancel"),
                () => {
                    AlertModal.dismissModal();
                }
            )
        )

        AlertModal.showModal(
            Rosetta.string("case_record.section_timeline_option_title"),
            "",
            buttons
        );
    }

    function generateSections() {
        let out = [];

        if (segment.sections) {
            segment.sections.forEach((section) => {
                let itemElems = [];

                // Ignore check - If the title of a section is "@ignore" then do not process.
                if (section.title) {
                    if (section.title.trim() === "@ignore") {
                        return;
                    }
                }

                section.items.forEach((item) => {
                    let shouldAdd = true;
                    if (filterType !== "-1") {
                        shouldAdd = parseInt(filterType) === parseInt(item.caseRecordSegmentSectionItemOutcomeTypeId);
                    }

                    if (shouldAdd) {
                        itemElems.push(
                            CaseRecordUtil.generateItemView(item, (action, data) => {
                                if (action === CaseRecordUtil.actions.CLINICAL_STANDARD_CLICK) {
                                    let clinicalStandardId = undefined;
                                    if (item.hasOwnProperty("clinicalStandardId")) {
                                        clinicalStandardId = item.clinicalStandardId;
                                    }
                                    Analytics.track(AnalyticsTags.TAG_CLINICAL_STANDARD_VIEW, clinicalStandardId);

                                    showClinicalStandards(item.clinicalStandard);
                                } else if (action === CaseRecordUtil.actions.REFLECT_CLICK) {
                                    summonReflectionEditor(item);
                                } else if (action === CaseRecordUtil.actions.ACTION_CLICK) {
                                    CaseRecordUtil.handleItemAction(data, caseRecord.current, (action, data) => {
                                        if (action === CaseRecordUtil.itemActions.OPEN_URL) {
                                            window.open(data);
                                        } else if (action === CaseRecordUtil.itemActions.OPEN_DIALOG) {
                                            if (data === CaseRecordUtil.itemDialogs.ASA) {
                                                Analytics.track(AnalyticsTags.TAG_ASA_GUIDANCE_VIEW);
                                                const asaPdfUrl = AppConfig.get(AppConfig.keys.asaClassificationUrl);
                                                if (asaPdfUrl) {
                                                    summonPDFViewer("ASA Classification", API.ensureUrl(asaPdfUrl));
                                                } else {
                                                    summonASAGuidanceModal();
                                                }
                                            } else if (data === CaseRecordUtil.itemDialogs.URGENCY) {
                                                Analytics.track(AnalyticsTags.TAG_URGENCY_TABLE_VIEW);
                                                const urgencyUrl = AppConfig.get(AppConfig.keys.nelaUrgencyUrl);
                                                if (urgencyUrl) {
                                                    summonPDFViewer("NELA Urgency", API.ensureUrl(urgencyUrl));
                                                } else {
                                                    summonUrgencyModal();
                                                }
                                            } else if (data === CaseRecordUtil.itemDialogs.CLINICAL_FRAILTY) {
                                                const frailtyUrl = AppConfig.get(AppConfig.keys.criticalSafetyScaleUrl);
                                                if (frailtyUrl) {
                                                    summonPDFViewer("Critical Frailty Scale", API.ensureUrl(frailtyUrl));
                                                }
                                                // Note: No fallback dialog here.
                                            } else {
                                                Toast.show(
                                                    Rosetta.string("common.error"),
                                                    "Failed to open dialog: " + data,
                                                    Toast.ERROR,
                                                    Toast.LONG
                                                );
                                            }
                                        } else if (action === CaseRecordUtil.itemActions.MESSAGE) {
                                            AlertModal.showModal(
                                                Rosetta.string("common.alert"),
                                                data,
                                                [
                                                    AlertModal.button(
                                                        Rosetta.string("common.ok"),
                                                        () => {
                                                            AlertModal.dismissModal();
                                                        }
                                                    )
                                                ]
                                            );
                                        }
                                    })
                                }
                            })
                        )
                    }
                });

                if (itemElems.length > 0) {
                    out.push(
                        <div className={"row mt-2 justify-content-center"}>
                            <div className={"col-12 col-lg-10 col-xl-8"}>
                                <ul className={"list-group"}>
                                    <li className={"list-group-item section-title"}>{section.title}</li>
                                    {itemElems}
                                </ul>
                            </div>
                        </div>
                    )
                }
            });
        }

        return out;
    }

    function generateTimeline() {
        let out = [];

        console.log("Generate timeline...", segment.id);
        console.log(segment.timeline);

        if (segment.timeline && segment.timeline.length > 0) {
            console.log("Has timeline!");
            let firstDate = null;
            let lastDate = null;

            segment.timeline.forEach((item) => {
                console.log("Item:", item);
                let currentDate = null;
                if (item.value && item.value !== "NO_DATE" && item.value.trim() !== "") {
                    currentDate = Chronos.with(new Date(item.value));
                    if (firstDate === null) {
                        firstDate = currentDate.getDate();
                    }

                    let headerItem = null;
                    if (lastDate === null || !currentDate.isSameDay(lastDate)) {
                        let day = currentDate.difference(firstDate, Chronos.DAYS) + 1;

                        // Defer adding the header item until after we've calculated gaps and spaces
                        headerItem = generateTimelineItem({
                            isHeading : true,
                            day
                        }, currentDate);
                    }

                    let differenceDays = currentDate.difference(lastDate, Chronos.DAYS);
                    if (differenceDays > 2) {
                        out.push(generateTimelineItem({isGap : true}, null));
                    } else {
                        if (headerItem == null) {
                            let differenceHours = currentDate.difference(lastDate, Chronos.HOURS);
                            if (differenceHours > 2) {
                                out.push(generateTimelineItem({isSpacer: true}, null));
                            }
                        }
                    }

                    if (headerItem != null) {
                        out.push(headerItem);
                    }

                    lastDate = currentDate.getDate();
                }

                if (currentDate === null && item.value === "NO_DATE") {
                    currentDate = "NO_DATE";
                }

                out.push(generateTimelineItem(item, currentDate));
                console.log("Insert item:", item);
            });

            if (out.length > 0) {
                out = (
                    <>
                        <div className={"row mt-4 justify-content-center"}>
                            <div className={"col-12 col-lg-10 col-xl-8"}>
                                <div className={"segment-timeline"}>
                                    {out}
                                </div>
                            </div>
                        </div>

                        {generateKeySummaryStatistics()}
                    </>
                )
            }
        }

        return out;
    }

    function generateTimelineItem(item, date) {
        let time = [];
        let lineIndicator = [];
        let lineClass = "";
        let body = [];

        if (date !== null && date !== "NO_DATE") {
            time = ((<span className={"text"}>{date.format("HH:mm")}</span>));
        }

        if (item.hasOwnProperty("isHeading") && item.isHeading) {
            // Date Heading
            body = (
                <div className={"timeline-item-body timeline-header-item"}>
                    <span className={"badge text-bg-danger"}>{Rosetta.string("case_record.section_timeline_day", { count : item.day })}</span>
                    <div>{date.format("dd MMMM yyyy")}</div>
                </div>
            );

            time = []; // Do not display time
            lineIndicator = (<div className={"indicator small"}/>);
        } else if (item.hasOwnProperty("isSpacer") && item.isSpacer) {
            // Space (without fade)
            body = (
                <div className={"timeline-item-body spacer"} />
            )
        } else if (item.hasOwnProperty("isGap") && item.isGap) {
            // Space (with fade)
            lineClass = " fade-centre";

            body = (<div className={"timeline-item-body spacer"} />);
        } else if (item.title && item.title !== "") {
            // Actual timeline item
            let titleElem = [];
            let subtitleElem = [];
            let actionElem = [];

            let forceNegative = false;
            if (item.hasOwnProperty("forceOutcomeTypeId")) {
                forceNegative = parseInt(item.forceOutcomeTypeId) === CaseRecordUtil.itemOutcomeTypes.NEGATIVE;
            }

            let bodyClass = "badge-all-good";
            if (date) {
                titleElem = (<div className={"title"}>{item.title}</div>);

                if (date !== "NO_DATE") {
                    lineIndicator = (<div className={"indicator active"}/>);
                } else {
                    lineIndicator = (<div className={"indicator"}/>);
                }
            } else {
                bodyClass = "badge-improve";

                titleElem = <div className={"title"}>{Rosetta.string("case_record.section_timeline_missing_title")}</div>;
                subtitleElem = <div className={"subtitle"}>{item.title}</div>
                lineIndicator = (<div className={"indicator"}/>);
            }

            if (forceNegative) {
                bodyClass = "badge-improve";
            }

            if (item.caseRecordSegmentSectionItemId !== undefined) {
                actionElem = (
                    <div
                        className={"action"}
                        style={{backgroundImage : ImageUtil.background(IconOverflow)}}
                        onClick={() => showTimelineOptions(item)} />
                );
            }

            body = (
                <div className={"timeline-item-body " + bodyClass}>
                    <div className={"item-content"}>
                        {titleElem}
                        {subtitleElem}
                    </div>
                    {actionElem}
                    <div className={"triangle"} />
                </div>
            )
        } else {
            console.log("TIMELINE ERROR!", item);
        }

        return(
            <div className={"timeline-item"}>
                <div className={"timeline-item-time"}>
                    {time}
                </div>
                <div className={"timeline-item-line"}>
                    <div className={"line" + lineClass}/>
                    {lineIndicator}
                </div>

                {body}
            </div>
        );
    }

    function generateKeySummaryStatistics() {
        if (segment.hasOwnProperty("keyStatistics") && segment.keyStatistics && segment.keyStatistics.length > 0) {
            let lookup = {};

            segment.keyStatistics.forEach((stat) => {
                lookup[stat.key] = stat.value;
            });

            let keyMetricsVersion = CommonUtil.getOrDefault(lookup, "key_metrics_version", 1);

            let out = [];

            if (keyMetricsVersion === 1) {
                out = (
                    <div className={"row mt-4 justify-content-center"}>
                        <div className={"col-12 col-lg-10 col-xl-8"}>

                            <div className={"card"}>
                                <div className={"card-header"}>
                                    <h4>{Rosetta.string("case_record.section_key_summary_title")}</h4>
                                </div>

                                <div className={"card-body"}>

                                    <div className={"row"}>
                                        <div className={"col-12 col-lg-6 mb-2"}>
                                            <strong>{Rosetta.string("case_record.section_key_summary_door_to_theatre")}</strong>
                                            <div>{CommonUtil.getOrDefault(lookup, "door_to_theatre", "---")}</div>
                                        </div>

                                        <div className={"col-12 col-lg-6 mb-2"}>
                                            <strong>{Rosetta.string("case_record.section_key_summary_door_first_sen_sur_rev")}</strong>
                                            <div>{CommonUtil.getOrDefault(lookup, "referral_to_review", "---")}</div>
                                        </div>

                                        <div className={"col-12 col-lg-6 mb-2"}>
                                            <strong>{Rosetta.string("case_record.section_key_summary_referral_first_sen_sur_rev")}</strong>
                                            <div>{CommonUtil.getOrDefault(lookup, "referral_to_first_senior_surgical_review", "---")}</div>
                                        </div>

                                        <div className={"col-12 col-lg-6 mb-2"}>
                                            <strong>{Rosetta.string("case_record.section_key_summary_first_sen_rev_booking_ct")}</strong>
                                            <div>{CommonUtil.getOrDefault(lookup, "first_senior_review_to_ct_booking", "---")}</div>
                                        </div>

                                        <div className={"col-12 col-lg-6 mb-2"}>
                                            <strong>{Rosetta.string("case_record.section_key_summary_book_to_report_ct")}</strong>
                                            <div>{CommonUtil.getOrDefault(lookup, "book_to_report_ct", "---")}</div>
                                        </div>

                                        <div className={"col-12 col-lg-6 mb-2"}>
                                            <strong>{Rosetta.string("case_record.section_key_summary_ct_report_theatre")}</strong>
                                            <div>{CommonUtil.getOrDefault(lookup, "ct_to_theatre", "---")}</div>
                                        </div>

                                        <div className={"col-12 col-lg-6 mb-2"}>
                                            <strong>{Rosetta.string("case_record.section_key_summary_first_sen_rev_first_dose_anti")}</strong>
                                            <div>{CommonUtil.getOrDefault(lookup, "first_senior_review_first_dose_antibiotics", "---")}</div>
                                        </div>
                                    </div>

                                    <div className={"row mt-4"}>
                                        <div className={"col-12 text-center"}>
                                            <h5>{Rosetta.string("case_record.section_key_summary_urgency_title")}</h5>
                                            <em>{Rosetta.string("case_record.section_key_summary_urgency_subtitle")}</em>
                                        </div>

                                        <div className={"col-12 col-lg-6 mb-2"}>
                                            <strong>{Rosetta.string("case_record.section_key_summary_urgency_preop")}</strong>
                                            <div>{CommonUtil.getOrDefault(lookup, "urgency_expected", "---")}</div>
                                        </div>

                                        <div className={"col-12 col-lg-6 mb-2"}>
                                            <strong>{Rosetta.string("case_record.section_key_summary_urgency_actual")}</strong>
                                            <div>{CommonUtil.getOrDefault(lookup, "urgency_actual", "---")}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                );
            } else if (keyMetricsVersion === 2) {
                out = (
                    <div className={"row mt-4 justify-content-center"}>
                        <div className={"col-12 col-lg-10 col-xl-8"}>

                            <div className={"card"}>
                                <div className={"card-header text-center"}>
                                    <h4>{Rosetta.string("case_record.section_key_summary_title")}</h4>
                                    <div>{Rosetta.string("case_record.section_key_summary_subtitle")}</div>
                                </div>

                                <div className={"card-body"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>

                                            <table>
                                                <thead>
                                                <tr>
                                                    <th colSpan={3} className={"text-center"}>{Rosetta.string("case_record.section_key_milestone_title")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={3} className={"text-center"}><strong>{Rosetta.string("case_record.section_key_milestone_subtitle")}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{Rosetta.string("case_record.section_key_milestone_nela_indications")}</td>
                                                        <td>&nbsp;</td>
                                                        <td>{CommonUtil.getOrDefault(lookup, "surgery_indicators", "")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{Rosetta.string("case_record.section_key_milestone_operative_findings")}</td>
                                                        <td>&nbsp;</td>
                                                        <td>{CommonUtil.getOrDefault(lookup, "operative_findings", "")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{Rosetta.string("case_record.section_key_milestone_first_inpatient_speciality")}</td>
                                                        <td>&nbsp;</td>
                                                        <td>{CommonUtil.getOrDefault(lookup, "first_inpatient_speciality", "")}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12"}>

                                            <table>
                                                <thead>
                                                <tr>
                                                    <th colSpan={3} className={"text-center"}>{Rosetta.string("case_record.section_key_milestone_major_milestone")}</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <tr>
                                                    <td className={"text-center"}><strong>{Rosetta.string("case_record.section_key_milestone_milestone_pop_reported")}</strong></td>
                                                    <td className={"text-center"}><strong>{Rosetta.string("case_record.section_key_milestone_other_subtitle_percent")}</strong></td>
                                                    <td className={"text-center"}><strong>{Rosetta.string("case_record.section_key_milestone_other_subtitle_calculated")}</strong></td>
                                                </tr>

                                                <tr>
                                                    <td>{Rosetta.string("case_record.section_key_milestone_door_to_op_decision_time")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_to_op_decision_time_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_to_op_decision_time", "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{Rosetta.string("case_record.section_key_milestone_access_theatre_time")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "access_to_theatre_time_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "access_to_theatre_time", "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{Rosetta.string("case_record.section_key_milestone_door_theatre_time")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_to_theatre_time_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_to_theatre_time", "N/A")}</td>
                                                </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12"}>

                                            <table>
                                                <thead>
                                                <tr>
                                                    <th colSpan={3} className={"text-center"}>{Rosetta.string("case_record.section_key_milestone_other_title")}</th>
                                                </tr>
                                                <tr>
                                                    <td className={"text-center"}><strong>{Rosetta.string("case_record.section_key_milestone_other_subtitle_milestone")}</strong></td>
                                                    <td className={"text-center"}><strong>{Rosetta.string("case_record.section_key_milestone_other_subtitle_percent")}</strong></td>
                                                    <td className={"text-center"}><strong>{Rosetta.string("case_record.section_key_milestone_other_subtitle_calculated")}</strong></td>
                                                </tr> 
                                                </thead>

                                                <tbody>
                                                <tr>
                                                    <td>{formatMilestoneLabel(Rosetta.string("case_record.section_key_milestone_door_first_medical_review"))}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_first_review_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_first_review", "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{formatMilestoneLabel(Rosetta.string("case_record.section_key_milestone_door_first_surgical_review"))}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_first_st3_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_first_st3", "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{formatMilestoneLabel(Rosetta.string("case_record.section_key_milestone_door_first_consultant_surgical_review"))}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_first_con_sur_review_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_first_con_sur_review", "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{formatMilestoneLabel(Rosetta.string("case_record.section_key_milestone_door_first_dose_antibiotics"))}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_first_dose_antibiotics_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_first_dose_antibiotics", "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{formatMilestoneLabel(Rosetta.string("case_record.section_key_milestone_door_request_ct_time"))}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_ct_request_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "door_ct_request", "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{formatMilestoneLabel(Rosetta.string("case_record.section_key_milestone_request_report_ct_time"))}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "request_report_ct_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "request_report_ct", "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{formatMilestoneLabel(Rosetta.string("case_record.section_key_milestone_length_of_trial"))}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "active_nonop_management_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "active_nonop_management", "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{formatMilestoneLabel(Rosetta.string("case_record.section_key_milestone_ct_report_decision_operate"))}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "ct_report_decision_operate_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "ct_report_decision_operate", "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <td>{formatMilestoneLabel(Rosetta.string("case_record.section_key_milestone_ct_report_arrival_theatre"))}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "ct_report_arrival_theatre_percent", "N/A")}</td>
                                                    <td className={"text-center"}>{CommonUtil.getOrDefault(lookup, "ct_report_arrival_theatre", "N/A")}</td>
                                                </tr>
                                                </tbody>
                                            </table>

                                            <div className={"mt-2"}>
                                                <em>{Rosetta.string("case_record.section_key_milestone_other_subtitle_percent_explain")}</em>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            return out;
        }

        return [];
    }

    function formatMilestoneLabel(string) {
        let split = string.split("\n");

        let out = [];
        for (let i = 0; i < split.length; i++) {
            if (i === 0) {
                out.push(<div><strong>{split[i]}</strong></div>);
            } else if (i === 1) {
                out.push(<div>{split[i]}</div>);
            } else {
                out.push(<div><small>{split[i]}</small></div>)
            }
        }

        return out;
    }

    function summonClinicalStandardModal(text, orgText) {
        setClinicalStandardText(text);
        setClinicalStandardOrgText(orgText);
        setClinicalStandardShown(true);
    }

    function clinicalStandardDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setClinicalStandardShown(false);
        }
    }

    function summonASAGuidanceModal() {
        setAsaGuidanceShown(true);
    }

    function asaGuidanceDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setAsaGuidanceShown(false);
        }
    }

    function summonUrgencyModal() {
        setUrgencyDialogShown(true);
    }

    function urgencyDialogDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setUrgencyDialogShown(false);
        }
    }

    function summonPDFViewer(title, url) {
        setPdfViewerTitle(title);
        setPdfViewerUrl(url);
        setPdfViewerShown(true);
    }

    function pdfViewerDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setPdfViewerShown(false);
        }
    }

    // RENDER

    let mainContent;
    if (segment) {
        let navigationButtonElems = [[], [], []]
        if (caseRecord.current) {
            let currentSegmentIndex = -1;
            if (caseRecord.current.segments) {
                for (let i = 0; i < caseRecord.current.segments.length; i++) {
                    if (parseInt(caseRecord.current.segments[i].id) === parseInt(segmentId)) {
                        currentSegmentIndex = i;
                        break;
                    }
                }

                navigationButtonElems[1] = (
                    <CaseRecordSegmentNavigationLink
                        to={"/caseRecord/" + caseRecord.current.departmentId + "/" + caseRecord.current.id}
                        title={""}
                        label={Rosetta.string("case_record.navigation_overview")} />
                );

                if (currentSegmentIndex > 0) {
                    // Add previous button
                    let prevSegment = caseRecord.current.segments[currentSegmentIndex - 1];
                    if (prevSegment) {
                        navigationButtonElems[0] = (
                            <CaseRecordSegmentNavigationLink
                                to={"/caseRecordSegment/1/" + caseRecord.current.id + "/" + prevSegment.id}
                                title={Rosetta.string("case_record.navigation_previous_segment")}
                                label={prevSegment.segmentName}
                                startIcon={arrowLeft} />
                        )
                    }
                }

                if (currentSegmentIndex < (caseRecord.current.segments.length - 1)) {
                    // Add next button
                    let nextSegment = caseRecord.current.segments[currentSegmentIndex + 1];
                    if (nextSegment) {
                        navigationButtonElems[2] = (
                            <CaseRecordSegmentNavigationLink
                                to={"/caseRecordSegment/1/" + caseRecord.current.id + "/" + nextSegment.id}
                                title={Rosetta.string("case_record.navigation_next_segment")}
                                label={nextSegment.segmentName}
                                endIcon={arrowRight} />
                        )
                    }
                }
            }
        }

        let filterElem = (
            <div className={"row mt-2 justify-content-center"}>
                <div className={"col-12 col-lg-10 col-xl-8"}>

                    <div className={"card"}>
                        <div className={"card-body"}>

                            <label>{Rosetta.string("case_record.section_filter")}</label>
                            <select className={"form-select"} value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                                <option value={"-1"}>{Rosetta.string("case_record.section_filter_all")}</option>
                                <option value={"1"}>{Rosetta.string("case_record.section_filter_positive")}</option>
                                <option value={"2"}>{Rosetta.string("case_record.section_filter_negative")}</option>
                                <option value={"4"}>{Rosetta.string("case_record.section_filter_unable_to_evaluate")}</option>
                                <option value={"5"}>{Rosetta.string("case_record.section_filter_error")}</option>
                            </select>

                        </div>
                    </div>

                </div>
            </div>
        );

        if (segment.hasOwnProperty("showFilter")) {
            if (parseInt(segment.showFilter) !== 1) {
                filterElem = [];
            }
        }

        /*
        <div className={"row mt-4"}>
            <div className={"col-12"}>
                <button className={"btn btn-primary"} onClick={() => summonASAGuidanceModal()}>Summon ASA</button>
                <button className={"btn btn-primary"} onClick={() => summonUrgencyModal()}>Summon Urgency</button>
            </div>
        </div>
         */

        mainContent = (
            <div className={"animate-screen-content"}>

                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-lg-10 col-xl-8"}>

                        <ul className={"list-group"}>
                            <li className={"list-group-item section-heading"}>
                                <div className={"number-tag"} style={{backgroundColor : segment.primaryColour}}>
                                    <span className={"text" + (!ColourUtil.isLight(segment.primaryColour) ? " dark" : "")}>{segment.segmentNumber}</span>
                                </div>

                                <div className={"section-content"}>
                                    <div className={"section-title"}>{segment.segmentName}</div>
                                    <div className={"section-subtitle"}>{segment.segmentSubtitle}</div>

                                    <div className={"badge-row"}>
                                        <span className={"badge badge-all-good me-1"}>
                                            <span className={"badge-icon"} style={{backgroundImage : ImageUtil.background(IconGood)}} />
                                            {segment.positiveCount}
                                        </span>

                                        <span className={"badge badge-improve me-1"}>
                                            <span className={"badge-icon"} style={{backgroundImage : ImageUtil.background(IconImprove)}} />
                                            {segment.negativeCount}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>

                {filterElem}

                {generateTimeline()}

                {generateSections()}

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h5>{Rosetta.string("case_record.reflection_title")}</h5>
                            </div>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <p>{Rosetta.string("case_record.reflection_message_1")}</p>
                                        <p>{Rosetta.string("case_record.reflection_message_2")}</p>
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12 col-10"}>
                                        <button className={"btn btn-primary mb-1 full-width"} onClick={() => summonSegmentReflectionEditor(ReflectionUtil.reflectionTypes.WHAT_WORKED_WELL)}>{Rosetta.string("case_record.reflection_button_went_well")}</button>
                                        <button className={"btn btn-primary mb-1 full-width"} onClick={() => summonSegmentReflectionEditor(ReflectionUtil.reflectionTypes.OPPORTUNITY_TO_IMPROVE)}>{Rosetta.string("case_record.reflection_button_improve")}</button>
                                        <button className={"btn btn-primary mb-1 full-width"} onClick={() => summonSegmentReflectionEditor(ReflectionUtil.reflectionTypes.SERVICE_IMPROVEMENT_SUGGESTION)}>{Rosetta.string("case_record.reflection_button_service_suggestion")}</button>
                                        <button className={"btn btn-primary mb-1 full-width"} onClick={() => summonSegmentReflectionWritingEditor()}>{Rosetta.string("case_record.reflection_button_private_reflection")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mt-4 align-items-center"}>
                    {navigationButtonElems.map((elem) => (
                        <div className={"col-12 col-md-4"}>
                            {elem}
                        </div>
                    ))}
                </div>
            </div>
        )
    } else {
        mainContent = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <LoadingSpinner />
                </div>
            </div>
        )
    }

    return (
        <div className={"case-record-section-screen app-screen"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("case_record.section_title")} />
                </div>
            </div>

            {mainContent}

            <ReflectionEditorModal
                shown={reflectionEditorShown}
                caseRecordId={caseRecordId}
                segmentId={segmentId}
                patientName={reflectionEditorData ? reflectionEditorData.patientName : ""}
                measureTitle={reflectionEditorData ? reflectionEditorData.measureTitle : ""}
                measureNumber={reflectionEditorData ? reflectionEditorData.measureNumber : ""}
                reflectionFeedbackTypeId={reflectionEditorData ? reflectionEditorData.feedbackTypeId : -1}
                callback={reflectionEditorDidCallback} />

            <SegmentReflectionEditorModal
                shown={segmentReflectionEditorShown}
                data={segmentReflectionData}
                callback={segmentReflectionEditorDidCallback} />

            <SegmentReflectionWritingTemplateModal
                shown={segmentReflectionWritingModalShown}
                data={segmentReflectionData}
                callback={segmentReflectionWritingModalDidCallback} />

            <ClinicalStandardModal
                shown={clinicalStandardShown}
                text={clinicalStandardText}
                organisationText={clinicalStandardOrgText}
                callback={clinicalStandardDidCallback} />

            <ASAGuidanceModal
                shown={asaGuidanceShown}
                callback={asaGuidanceDidCallback} />

            <UrgencyDialog
                shown={urgencyDialogShown}
                callback={urgencyDialogDidCallback} />

            <PDFViewerModal
                shown={pdfViewerShown}
                url={pdfViewerUrl}
                title={pdfViewerTitle}
                callback={pdfViewerDidCallback} />

        </div>
    )

}