import {PDFViewerModal} from "../pdf/PDFViewerModal";
import {BaseModalActions} from "../alertmodal/BaseModal";
import {useState} from "react";
import {API} from "../../network/API";

export const PDFDebugScreen = (props) => {

    const [pdfShown, setPdfShown] = useState(false);

    function handleCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setPdfShown(false);
        }
    }

    return (
        <div className={"app-screen"}>
            <button className={"btn btn-primary"} onClick={() => setPdfShown(true)}>Launch PDF</button>

            <PDFViewerModal
                shown={pdfShown}
                url={API.ensureUrl("/pdf/ASA_Classification.pdf")}
                title={"ASA Guidance"}
                callback={handleCallback} />

        </div>
    )

}