import {BrowserRouter, Route, Routes} from "react-router-dom";
import {InitNavigator, Navigator} from "../util/Navigator";
import {RosettaAwareComponent} from "./RosettaAwareComponent";
import {Toast, TokyoToaster} from "./toast/TokyoToaster";
import {useEffect, useRef, useState} from "react";
import {AlertModalComponent} from "./alertmodal/AlertModal";
import {BaseModalActions} from "./alertmodal/BaseModal";
import {QIHeaderBar} from "./headerbar/QIHeaderBar";
import {QISideBar} from "./navigation/QISideBar";
import {NavList} from "./navigation/NavigationList";
import {QINavigationBar} from "./navigation/QINavigationBar";

import NavIconPatients from "../assets/images/nav_patients.svg";
import NavIconReflections from "../assets/images/nav_reflection.svg";
import NavIconSettings from "../assets/images/nav_setttings.svg";
import {DataManager} from "../data/DataManager";
import {LoginScreen} from "./screens/login/LoginScreen";
import {CommonUtil} from "../util/CommonUtil";
import {CaseRecordListScreen} from "./screens/case-record/CaseRecordListScreen";
import {AppUser} from "../util/AppUser";
import {API, ENDPOINTS} from "../network/API";
import Rosetta from "../rosetta/Rosetta";
import {CaseRecordLandingScreen} from "./screens/case-record/CaseRecordLandingScreen";
import {CaseRecordSectionScreen} from "./screens/case-record/CaseRecordSectionScreen";
import {CaseRecordResultSummaryScreen} from "./screens/case-record/CaseRecordResultSummaryScreen";
import {CaseRecordPeopleScreen} from "./screens/case-record/CaseRecordPeopleScreen";
import {SettingsScreen} from "./screens/settings/SettingsScreen";
import {ReflectionsListScreen} from "./screens/reflections/ReflectionsListScreen";
import {ReflectionDetailScreen} from "./screens/reflections/ReflectionDetailScreen";
import {NavigationUtil} from "../util/NavigationUtil";
import Axios from "axios";
import {OrganisationListScreen} from "./screens/organisation/OrganisationListScreen";
import {ThemeUtil} from "../util/ThemeUtil";
import {SecureLoginPromptScreen} from "./screens/login/SecureLoginPromptScreen";
import {RegistrationScreen} from "./screens/login/register/RegistrationScreen";
import {
    OrganisationPolicyAcceptanceActions,
    OrganisationPolicyAcceptanceModal
} from "./screens/organisation/OrganisationPolicyAcceptanceModal";
import {Signal} from "../util/Signal";
import {CaseRecordFilterScreen} from "./screens/case-record/filter/CaseRecordFilterScreen";
import {ForgottenPasswordResetScreen} from "./screens/login/forgotten/ForgottenPasswordResetScreen";
import {Chronos} from "../chronos/Chronos";
import {CaseRecordUtil} from "../util/CaseRecordUtil";
import {AppConfig} from "../util/AppConfig";
import {PDFDebugScreen} from "./debug/PDFDebugScreen";

export const MainComponent = () => {

    const [rosettaMutationKey, setRosettaMutationKey] = useState(0);
    const [alertModalState, setAlertModalState] = useState({shown:false});

    const [user, setUser] = useState(null);
    const [organisationId, setOrganisationId] = useState(null);
    const [appCountNetworkInFlight, setAppCountNetworkInFlight] = useState(false);

    const [acceptPolicies, setAcceptPolicies] = useState([]);
    const [acceptPoliciesNetworkInFlight, setAcceptPoliciesNetworkInFlight] = useState(false);

    const dataManagerCallback = useRef();
    const shownAuthenticationFailureToast = useRef(false);

    const badgeRefreshTimeout = useRef(0);

    useEffect(() => {
        dataManagerCallback.current = (keyName, value) => {
            if (keyName === DataManager.keys.user) {
                const user = DataManager.getUser();
                if (user) {
                    fetchOrganisationPoliciesRequiringAcceptanceFromNetwork();
                    if (user.organisationId) {
                        setOrganisationId(user.organisationId);
                    }
                }
                setUser(user);
            } else if (keyName === DataManager.keys.sessionToken) {
                API.setAuthenticationToken(value);

                if (value) {
                    shownAuthenticationFailureToast.current = false;
                }
            } else if (keyName === DataManager.keys.organisationId) {
                API.setOrganisationId(value);
                setOrganisationId(value);

                if (value) {
                    fetchOrganisationPoliciesRequiringAcceptanceFromNetwork();
                }
            } else if (keyName === DataManager.keys.organisationTheme) {
                ThemeUtil.setThemeCSS(value);
            }
        };

        DataManager.addCallback(dataManagerCallback.current);

        // Get User, hold reference
        let user = DataManager.getUser();
        if (user) {
            if (user.organisationId) {
                setOrganisationId(user.organisationId);
            } else {
                const orgId = DataManager.getOrganisationId();
                if (orgId) {
                    setOrganisationId(orgId);
                }
            }
            setUser(user);
        }

        let themeData = DataManager.getOrganisationTheme();
        if (themeData) {
            ThemeUtil.setThemeCSS(themeData);
        }

        // Setup API data and callback
        API.setAuthenticationToken(DataManager.getSessionToken());
        API.setAuthFailureCallback(() => {
            if (shownAuthenticationFailureToast.current === false) {
                Toast.show(
                    Rosetta.string("common.sign_out"),
                    Rosetta.string("common.auth_failure_signout"),
                    Toast.ERROR,
                    Toast.LONG
                );

                AppUser.signOut();

                shownAuthenticationFailureToast.current = true;
            }
        });

        // Set up global Alert Dialog callbacks
        if (!window.hasOwnProperty("_showModal")) {
            window._showModal = (title, message, buttons, icon) => {
                showAlertModal(title, message, buttons, icon);
            }
        }

        if (!window.hasOwnProperty("_hideModal")) {
            window._dismissModal = () => {
                const ams = CommonUtil.cloneObject(alertModalState);
                ams.forceDismiss = true;
                setAlertModalState(ams);
            }
        }

        // Check for badge counts
        badgeRefreshTimeout.current = runAppNotificationCounts();

        // Start Signal now
        Signal.init();

        if (DataManager.getAppWasReloaded()) {
            setTimeout(() => {
                Toast.show(
                    Rosetta.string("system.app_reload_title"),
                    Rosetta.string("system.app_reload_message"),
                    Toast.INFO,
                    Toast.LONG
                );
            }, 300);

            DataManager.setAppWasReloaded(false);
        }

        let lastLoadDate = DataManager.getAppLoadDate();
        console.log("LAST LOAD DATE: " + lastLoadDate);
        if (lastLoadDate === 0) {
            console.log("Set app load date.");
            DataManager.setAppLoadDate(Chronos.now().seconds());
        } else if ((Chronos.now().add(-2, Chronos.DAYS).seconds() > lastLoadDate)) {
            console.log("App loaded more than 2 days ago, reload now.");
            setTimeout(() => {
                CommonUtil.reloadApp();
            }, 400);
        }

        // Fetch app config now
        fetchAppConfigFromNetwork();

        return () => {
            clearTimeout(badgeRefreshTimeout.current);
            DataManager.removeCallback(dataManagerCallback.current);
        }
    }, []);

    useEffect(() => {
        if (user) {
            fetchOrganisationPoliciesRequiringAcceptanceFromNetwork();
        }
    }, [user]);

    useEffect(() => {
        API.setOrganisationId(organisationId);

        // Fetch badge counts now
        clearTimeout(badgeRefreshTimeout.current);
        badgeRefreshTimeout.current = runAppNotificationCounts();
        fetchAppNotificationCountsFromNetwork();
    }, [organisationId]);

    // ALERT MODAL

    function showAlertModal(title, message, buttons, icon) {
        setAlertModalState({
            shown : true,
            title,
            message,
            buttons,
            icon
        });
    }

    function alertModalDidCallback(action) {
        if (action === BaseModalActions.CLOSE) {
            setAlertModalState({shown: false});
        }
    }

    function organisationPolicyAcceptanceModalDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            if (data) {
                setAcceptPolicies(data);
            }
        } else if (action === OrganisationPolicyAcceptanceActions.SIGN_OUT) {
            AppUser.signOut();
            setAcceptPolicies([]);
        }
    }

    function runAppNotificationCounts() {
        return setTimeout(() => {
            fetchAppNotificationCountsFromNetwork();
            badgeRefreshTimeout.current = runAppNotificationCounts();
        }, 150000); // 2.5 minutes
    }

    function fetchAppNotificationCountsFromNetwork() {
        const user = DataManager.getUser();
        if (!user) return;

        if (appCountNetworkInFlight) return;
        setAppCountNetworkInFlight(true);

        Axios.get(ENDPOINTS.user.getAppNotificationCounts)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    const countData = {};
                    if (resp.data.hasOwnProperty("caseRecordCount")) {
                        countData[NavigationUtil.SECTIONS.PATIENTS] = resp.data["caseRecordCount"];
                    }

                    NavigationUtil.setBadgeCounts(countData);
                } else {
                    console.log(API.formatError(resp));
                }
                setAppCountNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setAppCountNetworkInFlight(false);
            });
    }

    function fetchOrganisationPoliciesRequiringAcceptanceFromNetwork() {
        const user = DataManager.getUser();
        const organisationId = DataManager.getOrganisationId();

        if (acceptPoliciesNetworkInFlight || !user) return;
        setAcceptPoliciesNetworkInFlight(true);

        const formData = new FormData();
        if (organisationId) {
            formData.append("organisationId", organisationId);
        }

        Axios.post(ENDPOINTS.organisation.getOrganisationPoliciesRequiringAction, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setAcceptPolicies(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setAcceptPoliciesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setAcceptPoliciesNetworkInFlight(false);
            });
    }

    function fetchAppConfigFromNetwork() {
        Axios.get(ENDPOINTS.appConfig.getAppConfig)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    AppConfig.set(resp.data.config);
                } else {
                    console.log(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function debug_spawnTestCaseRecordNotifications() {
        setTimeout(() => {
            let notificationActions = [
                Toast.action(
                    Rosetta.string("common.view"),
                    () => {
                        // Clear the Case Record Cache now so we load the Notification Case Record anew on load
                        CaseRecordUtil.clearCaseRecordCache();
                        Navigator.navigate("/caseRecord/1/" + 35);
                    }
                )
            ];

            Toast.show(
                "DEBUG",
                "Test Case Record Notification",
                Toast.INFO,
                Toast.INFINITE,
                notificationActions
            );
        }, 1000);
    }

    // RENDER

    let mainContent = [];

    if (user) {
        let hasOrganisationId = user.organisationId || organisationId;

        let navItems = [];

        if (hasOrganisationId) {
            navItems = [
                NavList.item(
                    NavigationUtil.SECTIONS.PATIENTS,
                    Rosetta.string("navigation.patients"),
                    "/",
                    NavIconPatients
                ),
                NavList.item(
                    NavigationUtil.SECTIONS.REFLECTIONS,
                    Rosetta.string("navigation.reflections"),
                    "/reflections",
                    NavIconReflections
                ),
                NavList.item(
                    NavigationUtil.SECTIONS.SETTINGS,
                    Rosetta.string("navigation.settings"),
                    "/settings",
                    NavIconSettings
                )
            ];
        }

        let navigationContent = [
            {
                items : navItems
            }
        ];

        let mainRoutes = [
            <Route
                path={""}
                element={<CaseRecordListScreen />} />,

            <Route
                path={"/caseRecord/:departmentId/:caseRecordId"}
                element={<CaseRecordLandingScreen />} />,

            <Route
                path={"/caseRecordSegment/:departmentId/:caseRecordId/:segmentId"}
                element={<CaseRecordSectionScreen />} />,

            <Route
                path={"/caseRecordOverview/:departmentId/:caseRecordId/:focusOutcomeTypeId"}
                element={<CaseRecordResultSummaryScreen />} />,

            <Route
                path={"/caseRecordPeople/:departmentId/:caseRecordId/"}
                element={<CaseRecordPeopleScreen />} />,

            <Route
                path={"/caseRecordFilter/"}
                element={<CaseRecordFilterScreen />} />,

            <Route
                path={"/reflections"}
                element={<ReflectionsListScreen />} />,

            <Route
                path={"/reflection/:reflectionId"}
                element={<ReflectionDetailScreen />} />,

            <Route
                path={"/settings"}
                element={<SettingsScreen />} />,

            <Route
                path={"/organisations"}
                element={<OrganisationListScreen />} />,

            <Route
                path={"/secure-login-enroll"}
                element={<SecureLoginPromptScreen />} />,

            <Route
                path={"/debug_pdf"}
                element={<PDFDebugScreen />} />
        ];

        if (!hasOrganisationId) {
            // If we do not have an Organisation ID selected, show the Organisation List screen now
            mainRoutes = [
                <Route
                    path={"*"}
                    element={<OrganisationListScreen />} />,

                <Route
                    path={"/secure-login-enroll"}
                    element={<SecureLoginPromptScreen />} />
            ];
        }

        // Do not show side navigation when there's no content to display in it.
        let sideNavigationElem = [];
        if (navItems.length > 0) {
            sideNavigationElem = (
                <div className={"d-none d-md-block col-md-4 col-lg-3"}>
                    <QISideBar
                        items={navigationContent}/>
                </div>
            )
        }

        mainContent = (
            <div className={"container"}>
                <QIHeaderBar />

                <div className={"row justify-content-center"}>
                    {sideNavigationElem}

                    <div className={"col-12 col-md-8 col-lg-9"}>
                        <Routes>

                            {mainRoutes}

                        </Routes>
                    </div>
                </div>

                <QINavigationBar items={navigationContent} />

                <OrganisationPolicyAcceptanceModal
                    policies={acceptPolicies}
                    callback={organisationPolicyAcceptanceModalDidCallback} />
            </div>
        );
    } else {
        const mainRoutes = [
            <Route
                path={"*"}
                element={<LoginScreen />} />,

            <Route
                path={"/registration"}
                element={<RegistrationScreen />} />,

            <Route
                path={"/forgotten/:token"}
                element={<ForgottenPasswordResetScreen />} />
        ];

        mainContent = (
            <Routes>
                {mainRoutes}
            </Routes>
        );
    }

    return (
        <BrowserRouter>
            <InitNavigator />

            <RosettaAwareComponent key={rosettaMutationKey} callback={() => setRosettaMutationKey(Math.random())}>
                <TokyoToaster>
                    {mainContent}
                </TokyoToaster>
            </RosettaAwareComponent>

            <AlertModalComponent
                shown={alertModalState.shown}
                icon={alertModalState.icon}
                title={alertModalState.title}
                message={alertModalState.message}
                buttons={alertModalState.buttons}
                forceDismiss={alertModalState.forceDismiss}
                callback={alertModalDidCallback} />

        </BrowserRouter>
    )

}