export const DataManager = {
    _callbacks : [],
    keys : {
        sessionToken :  "qinotify_sessionToken",
        user : "qinotify_user",
        organisationId : "qinotify_organisationId",
        organisationImages : "qinotify.organisation_images",
        organisationTheme : "qinotify.organisation_theme",
        fcmTokenUploaded : "qinotify_fcmtokenuploaded",
        appWasReloaded : "qinotify_appwasrealoded",
        appLoadDate : "qinotify_apploaddate",
        appConfig : "qinotify_appConfig"
    },
    getSessionToken : () => {
        return DataManager.getItem(DataManager.keys.sessionToken, null);
    },
    setSessionToken : (token) => {
        return DataManager.setItem(DataManager.keys.sessionToken, token);
    },
    getUser : () => {
        return DataManager.getJsonItem(DataManager.keys.user);
    },
    setUser : (user) => {
        return DataManager.setJsonItem(DataManager.keys.user, user);
    },
    getOrganisationId : () => {
        return DataManager.getItem(DataManager.keys.organisationId, null);
    },
    setOrganisationId : (organisationId) => {
        return DataManager.setItem(DataManager.keys.organisationId, organisationId);
    },
    getOrganisationTheme : () => {
        return DataManager.getItem(DataManager.keys.organisationTheme);
    },
    setOrganisationTheme : (theme) => {
        return DataManager.setItem(DataManager.keys.organisationTheme, theme);
    },
    getOrganisationImages : () => {
        return DataManager.getJsonItem(DataManager.keys.organisationImages);
    },
    setOrganisationImages : (images) => {
        return DataManager.setJsonItem(DataManager.keys.organisationImages, images);
    },
    getFCMTokenUploaded : () => {
        return parseInt(DataManager.getItem(DataManager.keys.fcmTokenUploaded, "0")) === 1;
    },
    setFCMTokenUploaded : (value) => {
        return DataManager.setItem(DataManager.keys.fcmTokenUploaded, value ? "1" : "0");
    },
    getSecureLoginData : () => {
        return DataManager.getJsonItem("qinotify.secure_login_data");
    },
    setSecureLoginData : (data) => {
        return DataManager.setJsonItem("qinotify.secure_login_data", data);
    },
    clearSecureLoginData : () => {
        return DataManager.remove("qinotify.secure_login_data");
    },
    setAppWasReloaded : (value) => {
        return DataManager.setItem(DataManager.keys.appWasReloaded, value ? 1 : 0);
    },
    getAppWasReloaded : () => {
        return parseInt(DataManager.getItem(DataManager.keys.appWasReloaded, "0")) === 1;
    },
    setAppLoadDate : (date) => {
        return DataManager.setItem(DataManager.keys.appLoadDate, date);
    },
    getAppLoadDate : () => {
        return parseInt(DataManager.getItem(DataManager.keys.appLoadDate, "0"));
    },
    getAppConfig : () => {
        return DataManager.getJsonItem(DataManager.keys.appConfig);
    },
    setAppConfig : (appConfig) => {
        return DataManager.setJsonItem(DataManager.keys.appConfig, appConfig);
    },
    getItem : (keyName, defaultValue) => {
        if (defaultValue === undefined) defaultValue = null;

        let item = localStorage.getItem(keyName);
        if (item != null) {
            return item;
        }
        return defaultValue;
    },
    setItem : (keyName, value) => {
        localStorage.setItem(keyName, value);
        DataManager._triggerCallbacks(keyName, value);
        return true;
    },
    getJsonItem : (keyName) => {
        let item = DataManager.getItem(keyName);
        if (item != null) {
            try {
                item = JSON.parse(item);
                return item;
            } catch (e) {
                console.log(e);
            }
        }
        return null;
    },
    setJsonItem : (keyName, item) => {
        return DataManager.setItem(keyName, JSON.stringify(item));
    },
    remove : (key) => {
        localStorage.removeItem(key);
        DataManager._triggerCallbacks(key, undefined);
        return true;
    },
    clear : (triggerCallbacks) => {
        if (triggerCallbacks === undefined) {
            triggerCallbacks = true;
        }

        for (const [key, value] of Object.entries(DataManager.keys)) {
            localStorage.removeItem(value);

            if (triggerCallbacks) {
                DataManager._triggerCallbacks(value, undefined);
            }
        }

        return true;
    },
    addCallback : (callback) => {
        if (callback instanceof Function) {
            DataManager._callbacks.push(callback);
        }
    },
    removeCallback : (callback) => {
        for (let i = 0; i < DataManager._callbacks.length; i++) {
            if (DataManager._callbacks[i] === callback) {
                delete DataManager._callbacks[i];
                break;
            }
        }
    },
    _triggerCallbacks : (keyName, value) => {
        DataManager._callbacks.forEach((callback) => {
            callback(keyName, value);
        });
    }
}
