import {useState} from "react";
import {BaseModal, BaseModalActions} from "../../alertmodal/BaseModal";
import Rosetta from "../../../rosetta/Rosetta";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {EventUtil} from "../../../util/EventUtil";

export const TwoFactorCodeActions = {
    SUBMIT : "twofactorcode.submit"
};

export const TwoFactorCodeModal = (props) => {

    const {shown} = props;
    const {networkInFlight} = props;
    const {email} = props;
    const {error} = props;
    const {forceDismiss} = props;
    const {callback} = props;

    const [code, setCode] = useState("");

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function handleSubmit(e) {
        EventUtil.cancel(e);

        if (!networkInFlight && code.length > 0) {
            handleCallback(TwoFactorCodeActions.SUBMIT, code);
        }
    }

    if (!shown) return [];

    let submitButton = (
        <input type={"submit"} className={"btn btn-success"} value={Rosetta.string("login.verify_code_submit")} />
    );
    if (networkInFlight) {
        submitButton = (
            <LoadingSpinner inline={true} small={true} />
        )
    }

    let errorElem = [];
    if (error) {
        errorElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert text-bg-danger"}>
                        {error}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <h5>{Rosetta.string("login.verify_code_title")}</h5>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    {Rosetta.string("login.verify_code_message", { email })}
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className={"row mt-2 justify-content-center"}>
                    <div className={"col-12 col-md-10 col-lg-8 col-xl-6"}>
                        <input type={"text"} className={"form-control text-center"} value={code} placeholder={Rosetta.string("login.verify_code_placeholder")} onChange={(e) => setCode(e.target.value)} />
                    </div>
                </div>

                {errorElem}

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        {submitButton}
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn"} onClick={() => handleCallback(BaseModalActions.CLOSE)}>{Rosetta.string("common.cancel")}</button>
                    </div>
                </div>
            </form>

        </BaseModal>
    )

}