import Rosetta from "../rosetta/Rosetta";
import {CaseRecordUtil} from "./CaseRecordUtil";

export const ReflectionUtil = {
    reflectionTypes : {
        // V1 Types
        OPEN : 1,
        PRIVATE : 2,
        // V2 Types
        WHAT_WORKED_WELL : 3,
        OPPORTUNITY_TO_IMPROVE : 4,
        SERVICE_IMPROVEMENT_SUGGESTION : 5,
        RECORD_PRIVATE_REFLECTION : 6
    },
    feedbackTypes : {
        POSITIVE : 1,
        NEUTRAL : 2,
        NEGATIVE : 3
    },
    reminderTypes : {
        NONE : 1,
        ONCE : 2,
        DAILY : 3,
        WEEKLY : 4
    },
    getCasualCategories : () => {
        return [
            Rosetta.string("reflection.editor_casual_category_environment"),
            Rosetta.string("reflection.editor_casual_category_tasks"),
            Rosetta.string("reflection.editor_casual_category_equipment"),
            Rosetta.string("reflection.editor_casual_category_communication")
        ]
    },
    getPathways : () => {
        return [
            Rosetta.string("reflection.editor_writing_template_pathway_1"),
            Rosetta.string("reflection.editor_writing_template_pathway_2"),
            Rosetta.string("reflection.editor_writing_template_pathway_3"),
        ];
    },
    getNameForReflectionType : (typeId) => {
        typeId = parseInt(typeId);
        switch (typeId) {
            case ReflectionUtil.reflectionTypes.OPEN:
                return Rosetta.string("reflection.detail_title_public");
            case ReflectionUtil.reflectionTypes.PRIVATE:
                return Rosetta.string("reflection.detail_title_private");
            default:
                return "";
        }
    },
    getNameForReminderType : (typeId) => {
        typeId = parseInt(typeId);
        console.log("getNameForReminderType: " + typeId);
        switch (typeId) {
            case ReflectionUtil.reminderTypes.ONCE:
                return Rosetta.string("reflection.detail_reminder_once");
            case ReflectionUtil.reminderTypes.DAILY:
                return Rosetta.string("reflection.detail_reminder_daily");
            case ReflectionUtil.reminderTypes.WEEKLY:
                return Rosetta.string("reflection.detail_reminder_weekly");
            default:
                return Rosetta.string("reflection.detail_reminder_none")
        }
    },
    segmentSectionOutcomeTypeToReflectionType : (outcomeTypeId) => {
        outcomeTypeId = parseInt(outcomeTypeId);
        switch (outcomeTypeId) {
            case CaseRecordUtil.itemOutcomeTypes.POSITIVE:
                return ReflectionUtil.feedbackTypes.POSITIVE;
            case CaseRecordUtil.itemOutcomeTypes.NEGATIVE:
                return ReflectionUtil.feedbackTypes.NEGATIVE;
            default:
                return ReflectionUtil.feedbackTypes.NEUTRAL;
        }
    }
}