import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {CaseRecordUtil} from "../../../util/CaseRecordUtil";

export const CaseRecordPublicationHistoryModal = (props) => {

    const {shown} = props;
    const {publications} = props;
    const {callback} = props;

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    // RENDER

    if (!shown) return [];

    let recordElems = [];

    if (publications) {
        if (publications.length > 0) {
            publications.forEach((publication) => {
                let stageBadge = [];
                if (publication.caseRecordStageId !== null) {
                    stageBadge = (
                        <div className={"badge text-bg-warning"}>
                            {publication.caseRecordStageId} - {publication.recordStage}
                        </div>
                    )
                }

                recordElems.push(
                    <li className={"list-group-item"}>
                        <div>
                            {CaseRecordUtil.formatTimeDisplay(
                                publication.datePublished,
                                "case_record.detail_pub_history_date",
                                "dd/MM/yy HH:mm"
                            )}
                        </div>
                        <div className={"mt-1"}>
                            {stageBadge}
                        </div>
                    </li>
                )
            });
        }
    }

    if (recordElems.length === 0) {
        recordElems.push(
            <li className={"list-group-item"}>
                <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
            </li>
        )
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("case_record.detail_publication_history")}
            gravity={OffcanvasGravity.END}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>

                    <ul className={"list-group"}>
                        {recordElems}
                    </ul>

                </div>
            </div>

        </Offcanvas>
    )


}