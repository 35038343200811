import {useParams} from "react-router-dom";
import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {CaseRecordUtil} from "../../../util/CaseRecordUtil";
import {DataManager} from "../../../data/DataManager";
import {CaseRecordMissingClinicianModal} from "./CaseRecordMissingClinicianModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";
import {Navigator} from "../../../util/Navigator";
import {NavigationUtil} from "../../../util/NavigationUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

export const CaseRecordPeopleScreen = (props) => {

    const {departmentId} = useParams();
    const {caseRecordId} = useParams();

    const [caseRecord, setCaseRecord] = useState(null);
    const [emailConfig, setEmailConfig] = useState(null);

    const [missingClinicianShown, setMissingClinicianShown] = useState(false);

    const [configNetworkInFlight, setConfigNetworkInFlight] = useState(false);

    useEffect(() => {
        NavigationUtil.setSection(NavigationUtil.SECTIONS.PATIENTS);

        Navigator.scrollTop();

        if (caseRecordId) {
            const caseRecord = CaseRecordUtil.findCaseRecordInCache(caseRecordId);
            if (caseRecord) {
                setCaseRecord(caseRecord);

                fetchEmailConfigFromNetwork();
            } else {
                Navigator.navigate("/");
            }
        }
    }, []);

    function shownMissingClinicianModal() {
        setMissingClinicianShown(true);
    }

    function missingClinicianDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setMissingClinicianShown(false);
        }
    }

    function emailClinician(person) {
        let subject = "Re%3A%20QIN-EmLap%20Case%20Review";
        let body = "";
        if (emailConfig) {
            if (emailConfig.hasOwnProperty("qin_user_email_subject")) {
                subject = encodeURI(emailConfig.qin_user_email_subject);
            }

            if (emailConfig.hasOwnProperty("qin_user_email_body")) {
                body = encodeURI(emailConfig.qin_user_email_body);
            }
        }

        const uri = "mailto:" + person.emailAddress + "?subject=" + subject + "&body=" + body;
        window.open(uri);
    }

    function fetchEmailConfigFromNetwork() {
        if (configNetworkInFlight) return;
        setConfigNetworkInFlight(true);

        Axios.get(ENDPOINTS.caseRecord.getUserEmailConfig)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setEmailConfig(resp.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setConfigNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setConfigNetworkInFlight(false);
            });
    }

    // RENDER

    let mainContent = [];

    if (caseRecord) {
        let youElems = [];
        let otherElems = [];

        const user = DataManager.getUser();

        caseRecord.associatedPeople.forEach((person) => {
            if (user) {
                if (parseInt(person.id) === parseInt(user.id)) {
                    youElems.push(generatePersonItem(person));
                } else {
                    otherElems.push(generatePersonItem(person, true));
                }
            }
        });

        if (youElems.length > 0) {
            youElems = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ul className={"list-group"}>
                            <li className={"list-group-item"}><h5>{Rosetta.string("case_record.people_involved_you")}</h5></li>
                            {youElems}
                        </ul>
                    </div>
                </div>
            );
        }

        if (otherElems.length > 0) {
            otherElems = (
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <ul className={"list-group"}>
                            <li className={"list-group-item"}><h5>{Rosetta.string("case_record.people_involved_others")}</h5></li>
                            {otherElems}
                        </ul>
                    </div>
                </div>
            )
        }

        mainContent = (
            <div className={"animate-screen-content"}>
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-lg-10 col-xl-8"}>

                        {youElems}
                        {otherElems}

                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                {Rosetta.string("case_record.people_involved_missing_title")}
                            </div>

                            <div className={"col-12 mt-2"}>
                                <button className={"btn btn-primary full-width"} onClick={() => shownMissingClinicianModal()}>{Rosetta.string("case_record.people_involved_missing_action")}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    function generatePersonItem(person, showEmailButton) {
        if (!showEmailButton) showEmailButton = false;

        let emailButton = [];
        if (showEmailButton) {
            if (person.emailAddress !== null) {
                emailButton = (
                    <button className={"btn btn-primary"} onClick={() => emailClinician(person)}>{Rosetta.string("case_record.people_involved_email")}</button>
                )
            }
        }

        return (
            <li className={"list-group-item d-flex align-items-center"}>
                <div className={"flex-fill"}>
                    <div><strong>{Rosetta.string("common.name_format", { given_name : person.givenName, family_name : person.familyName })}</strong></div>
                    <div>{person.role}</div>
                </div>
                {emailButton}
            </li>
        )
    }

    return (
        <div className={"case-record-people-screen app-screen"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("case_record.detail_title")} />
                </div>
            </div>

            {mainContent}

            <CaseRecordMissingClinicianModal
                shown={missingClinicianShown}
                caseRecordId={caseRecordId}
                callback={missingClinicianDidCallback} />

        </div>
    )

}