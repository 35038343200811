import {useEffect, useState} from "react";
import Rosetta from "../../rosetta/Rosetta";
import {ImageUtil} from "../../util/ImageUtil";

import "./FilterText.css";

import search from "../../assets/images/search.svg";

export const FilterText = (props) => {

    const {timeout} = props;
    const {showPrefix} = props;

    const [text, setText] = useState();
    const [cbTrigger, setCbTrigger] = useState(0);

    useEffect(() => {
        let to = setTimeout(() => {
            setCbTrigger(cbTrigger + 1);
        }, (timeout ? timeout : 600));

        return () => {
            clearTimeout(to);
        }
    }, [text]);

    useEffect(() => {
        if (props.callback !== undefined) {
            props.callback(text);
        }
    }, [cbTrigger]);

    let prefixElem = [];
    if (showPrefix === undefined || showPrefix === true) {
        prefixElem = (
            <span className="input-group-text">
                <span className={"addon-icon"} style={{backgroundImage : ImageUtil.background(search)}} />
            </span>
        );
    }

    return (
        <div className="input-group flex-nowrap filter-text">
            {prefixElem}
            <input type={"text"} className={"form-control"} value={text} onChange={(e) => setText(e.target.value)} />
            <span className="input-group-text clickable" onClick={() => setText("")}>{Rosetta.string("common.clear")}</span>
        </div>

    )

}