import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import "./PasswordValidator.css";

import iconTick from "../../assets/images/tick_light.svg";
import iconCross from "../../assets/images/close_light.svg";
import {ImageUtil} from "../../util/ImageUtil";
import Rosetta from "../../rosetta/Rosetta";
import {LoadingSpinner} from "../loading/LoadingSpinner";

export const PasswordValidatorActions = {
    CHANGE : "passwordvalidator.change"
};

export const PasswordValidator = (props) => {

    const {type} = props;
    const {callback} = props;

    const [password, setPassword] = useState("");

    const [validated, setValidated] = useState(false);
    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [networkError, setNetworkError] = useState(false);

    const [errors, setErrors] = useState([]);
    const [passes, setPasses] = useState([]);

    useEffect(() => {
        validatePasswordOverNetwork();
    }, []);

    useEffect(() => {
        validatePasswordOverNetwork();
    }, [password]);

    useEffect(() => {
        handleCallback(PasswordValidatorActions.CHANGE, {
            validated,
            password
        });
    }, [validated]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function validatePasswordOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        setNetworkError(false);

        const formData = new FormData();
        formData.append("password", password);

        Axios.post(ENDPOINTS.auth.checkPasswordConformity, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setValidated(resp.data.success);
                    setErrors(resp.data.errors);
                    setPasses(resp.data.passes);
                } else {
                    console.log(API.formatError(resp));
                    setNetworkError(true);
                    setValidated(false);
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                setValidated(false);
                setNetworkError(true);
            });
    }

    // RENDER

    let mainContent = [];

    if (networkError) {
        mainContent = (
            <div className={"card text-bg-danger"}>
                <div className={"card-body"}>
                    <div className={"row"}>
                        <div className={"col-12 text-center"}>
                            {Rosetta.string("password.network_error")}
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"col-12 text-center"}>
                            <button className={"btn btn-outline-light"} onClick={() => validatePasswordOverNetwork()}>
                                {Rosetta.string("password.retry")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        let validationCheckElems = [];

        errors.forEach((check) => {
            validationCheckElems.push(<PasswordValidatorCheck pass={false} label={check}/>);
        })

        passes.forEach((check) => {
            validationCheckElems.push(<PasswordValidatorCheck pass={true} label={check}/>);
        })

        mainContent = (
            <div className={"card"}>
                <div className={"card-body"}>
                    {validationCheckElems}
                </div>
            </div>
        );
    }

    let suffix = [];
    if (networkInFlight) {
        suffix = (
            <span className={"input-group-suffix"}>
                <span className={"input-group-text"}>
                    <LoadingSpinner inline={true} fontSmall={true} />
                </span>
            </span>
        )
    }

    let useType = "password";
    if (type) {
        useType = type;
    }

    return (
        <div className={"password-validator card"}>
            <div className={"card-body"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"input-group"}>
                            <input
                                type={useType}
                                className={"form-control"}
                                value={password}
                                autoComplete={"off"}
                                onChange={(e) => setPassword(e.target.value)} />
                            {suffix}
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        {mainContent}
                    </div>
                </div>
            </div>
        </div>
    )

}

export const PasswordValidatorCheck = (props) => {
    const {pass} = props;
    const {label} = props;

    let useIcon = iconTick;
    let extraClass = " text-bg-success";
    if (!pass) {
        useIcon = iconCross;
        extraClass = " text-bg-danger";
    }

    return (
        <div className={"validation-check" + extraClass}>
            <div className={"icon"} style={{backgroundImage : ImageUtil.background(useIcon)}} />
            <div className={"text"}>{label}</div>
        </div>
    )

}