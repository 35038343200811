import {ImageUtil} from "../../util/ImageUtil";

import "./LoadingSpinner.css";

import icon from "../../assets/images/refresh.svg";

export const LoadingSpinner = (props) => {

    let classExtra = "";
    if (props.inline && props.inline === true) {
        classExtra = " inline";
    }

    if (props.small && props.small === true) {
        classExtra += " small";
    } else if (props.fontSmall && props.fontSmall === true) {
        classExtra += " font-small";
    }

    return (
        <div className={"loading-spinner" + classExtra}>
            <div className={"spinner-container"}>
                <span className={"spinner-spin"} style={{backgroundImage : ImageUtil.background(icon)}} />
            </div>
        </div>
    )

}