import {useEffect, useRef, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import ArrayUtil from "../../../util/ArrayUtil";
import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {ReflectionUtil} from "../../../util/ReflectionUtil";

import IconGood from "../../../assets/images/icon_good.svg";
import IconBad from "../../../assets/images/icon_improve.svg";
import {ImageUtil} from "../../../util/ImageUtil";
import {LoadingSpinner} from "../../loading/LoadingSpinner";

import "./ReflectionsListScreen.css";
import {Navigator} from "../../../util/Navigator";
import {NavigationUtil} from "../../../util/NavigationUtil";
import {ReflectionFilterModal} from "./ReflectionFilterModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";
import {Chronos} from "../../../chronos/Chronos";
import {ReflectionListItem} from "./ReflectionListItem";

export const ReflectionsListScreen = (props) => {

    const [reflections, setReflections] = useState([]);

    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);

    const [windowScroll, setWindowScroll] = useState(0);
    const [shouldFetchMore, setShouldFetchMore] = useState(true);

    const [filterShown, setFilterShown] = useState(false);
    const [filterData, setFilterData] = useState({});

    const windowListener = useRef();

    useEffect(() => {
        NavigationUtil.setSection(NavigationUtil.SECTIONS.REFLECTIONS);

        Navigator.scrollTop();

        fetchReflectionsFromNetwork();

        windowListener.current = (event) => {
            setWindowScroll(window.scrollY);
        };

        window.addEventListener("scroll", windowListener.current);

        return () => {
            window.removeEventListener("scroll", windowListener.current);
        }
    }, []);

    useEffect(() => {
        fetchReflectionsFromNetwork()
    }, [page, limit]);

    useEffect(() => {
        setReflections([]);
        fetchReflectionsFromNetwork();
    }, [filterData]);

    useEffect(() => {
        onWindowScrolled();
    }, [windowScroll]);

    function onWindowScrolled() {
        const bodyHeight = document.body.scrollHeight - window.innerHeight;

        if (bodyHeight > 0) {
            if (shouldFetchMore && !networkInFlight) {
                if (windowScroll > (bodyHeight - (window.innerHeight * 0.1))) {
                    setPage(page + 1)
                }
            }
        }
    }

    function navigateToDetail(reflection) {
        Navigator.navigate("/reflection/" + reflection.id);
    }

    function summonFilterModal() {
        setFilterShown(true);
    }

    function filterModalDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            console.log("Filter closed.", data);
            if (data) {
                setFilterData(data);
            }

            setFilterShown(false);
        }
    }

    function fetchReflectionsFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("page", page);
        formData.append("limit", limit);

        if (filterData) {
            if (filterData.patientId) {
                formData.append("patientId", filterData.patientId);
            }

            if (filterData.startDate) {
                formData.append("startDate", Chronos.with(new Date(filterData.startDate)).seconds());
            }

            if (filterData.endDate) {
                formData.append("endDate", Chronos.with(new Date(filterData.endDate)).seconds());
            }

            if (filterData.typeId) {
                formData.append("feedbackTypeId", filterData.typeId);
            }
        }

        Axios.post(ENDPOINTS.reflection.fetchAllReflectionsForCurrentUser, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.reflections.length > 0) {
                        const existingData = [...reflections];
                        const newData = ArrayUtil.appendArray(existingData, resp.data.reflections, true);
                        setReflections(newData);
                    }

                    if (resp.data.reflections.length < limit) {
                        setShouldFetchMore(false);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("URF1000C"));
            });
    }

    // RENDER

    let listContent = [];
    if (reflections.length > 0) {
        reflections.forEach((reflection) => {
            listContent.push(
                <ReflectionListItem
                    reflection={reflection}
                    callback={() => navigateToDetail(reflection)} />
            );
        });

        if (networkInFlight) {
            listContent.push(
                <li className={"list-group-item text-center"}>
                    <LoadingSpinner inline={true} small={true} />
                </li>
            )
        }
    } else if (networkInFlight) {
        // If we have no existing content, show placeholders while loading.
        for (let i = 0; i < 5; i++) {
            listContent.push(
                <li className={"list-group-item"}>
                    <PlaceholderText />
                </li>
            )
        }
    } else {
        listContent.push(
            <li className={"list-group-item text-center"}>{Rosetta.string("reflection.list_empty")}</li>
        )
    }

    return (
        <div className={"reflections-list-screen app-screen"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("reflection.list_title")} />
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-lg-10 col-xl-8 text-end"}>
                    <button className={"btn btn-primary"} onClick={() => summonFilterModal()}>{Rosetta.string("reflection.filter_title")}</button>
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-lg-10 col-xl-8"}>

                        <ul className={"list-group"}>
                            {listContent}
                        </ul>

                    </div>
                </div>
            </div>

            <ReflectionFilterModal
                shown={filterShown}
                data={filterData}
                callback={filterModalDidCallback} />

        </div>
    )

}