import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {CaseRecordStructureActions, CaseRecordStructureComponent} from "./CaseRecordStructureComponent";
import WindowUtil from "../../../../util/WindowUtil";

export const CaseRecordStructureSelectionModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [forceDismiss, setForceDismiss] = useState(false);
    const [callbackData, setCallbackData] = useState(undefined);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            setForceDismiss(false);
            setCallbackData(undefined);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            if (callbackData !== undefined) {
                data = callbackData;
            }

            callback(action, data);
        }
    }

    function structureDidCallback(action, data) {
        if (action === CaseRecordStructureActions.SECTION_ITEM_CLICKED) {
            setCallbackData(data);
            setForceDismiss(true);
        }
    }

    if (!shown) return [];

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("case_record.filter_select_item")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <CaseRecordStructureComponent callback={structureDidCallback} />

        </Offcanvas>
    )

}