import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";

import {Chronos} from "../../../chronos/Chronos";
import {ReflectionUtil} from "../../../util/ReflectionUtil";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import Validator from "../../../util/Validator";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import WindowUtil from "../../../util/WindowUtil";

import "./ReflectionEditorModal.css";
import {ImageUtil} from "../../../util/ImageUtil";

import IconGood from "../../../assets/images/icon_good.svg";
import IconImprove from "../../../assets/images/icon_improve.svg";
import {Navigator} from "../../../util/Navigator";

export const ReflectionEditorModal = (props) => {

    const {shown} = props;
    const {caseRecordId} = props;
    const {patientName} = props;
    const {segmentId} = props;
    const {measureTitle} = props;
    const {measureNumber} = props;
    const {reflectionFeedbackTypeId} = props;
    const {callback} = props;

    const [influences, setInfluences] = useState([]);
    const [error, setError] = useState(null);

    const [reflectionTypeId, setReflectionTypeId] = useState(ReflectionUtil.reflectionTypes.OPEN);
    const [influenceId, setInfluenceId] = useState(-1);
    const [feedback, setFeedback] = useState("");
    const [openFeedback, setOpenFeedback] = useState("");

    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [influenceNetworkInFlight, setInfluencesNetworkInFlight] = useState(false);

    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            fetchInfluencesFromNetwork();
        } else {
            WindowUtil.unlockBodyScroll();

            setInfluences([]);
            setReflectionTypeId(1);
            setInfluenceId(-1);
            setFeedback("");
            setOpenFeedback("");
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function fetchInfluencesFromNetwork() {
        if (influenceNetworkInFlight) return;
        setInfluencesNetworkInFlight(true);

        Axios.get(ENDPOINTS.reflection.getReflectionInfluences)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setInfluences(resp.data.influences);
                } else {
                    console.log(API.formatError(resp));
                }
                setInfluencesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setInfluencesNetworkInFlight(false);
            });
    }

    function submitReflectionOverNetwork() {
        if (networkInFlight) return;

        let validationRules = [
            Validator.rule("reflectionTypeId", "int", "ERR: reflectionTypeId", "reflectionTypeId"),
            Validator.rule("reflectionFeedbackTypeId", "int", "ERR: reflectionFeedbackTypeId", "reflectionFeedbackTypeId"),
            Validator.rule("measureTitle", "string", "ERR: measureTitle", "measureTitle"),
            Validator.rule("measureNumber", "string", "ERR: measureNumber", "measureNumber"),
            Validator.rule("caseRecordId", "int", "", "caseRecordId", true),
            Validator.rule("segmentId", "int", "", "segmentId", true),
            Validator.rule("segmentResultId", "int", "", "segmentResultId", true),
            Validator.rule("suggestedReflectionTitle", "string", "", "suggestedReflectionTitle", true),
            Validator.rule("influenceId", "int", "", "reflectionInfluenceId", true)
        ];

        if (parseInt(reflectionTypeId) === ReflectionUtil.reflectionTypes.OPEN) {
            validationRules.push(Validator.rule("feedback", "string", Rosetta.string("reflection.editor_validation_feedback"), "openFeedback"));
        } else {
            validationRules.push(Validator.rule("feedback", "string", Rosetta.string("reflection.editor_validation_feedback"), "privateFeedback"));
            validationRules.push(Validator.rule("openFeedback", "string", "", "openFeedback", true));
        }

        const result = Validator.validateCreateFormData({
            reflectionTypeId, reflectionFeedbackTypeId, measureTitle, measureNumber,
            caseRecordId, segmentId, influenceId, feedback, openFeedback
        }, validationRules);

        if (!result.success) {
            setError(result.error);
            return;
        }

        setError(null);
        setNetworkInFlight(true);

        Axios.post(ENDPOINTS.reflection.submitReflection, result.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let actions = [];
                    if (resp.data.reflection) {
                        actions.push(
                            Toast.action(
                                Rosetta.string("reflection.editor_submit_success_view"),
                                () => {
                                    Navigator.navigate("/reflection/" + resp.data.reflection.id);
                                }
                            )
                        );
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("reflection.editor_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG,
                        actions
                    );

                    setForceDismiss(true);
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                setError(API.defaultError("SRS1000C"));
            });
    }

    // RENDER

    if (!shown) return [];

    const reflectionTypes = [
        ReflectionUtil.reflectionTypes.OPEN,
        ReflectionUtil.reflectionTypes.PRIVATE
    ];

    let feedbackIconElem = [];
    let influenceLabel = null;
    let feedbackLabel = null;

    if (reflectionFeedbackTypeId) {
        let feedbackExtraClass = "";
        let feedbackIcon = null;

        if (parseInt(reflectionFeedbackTypeId) === ReflectionUtil.feedbackTypes.POSITIVE) {
            feedbackExtraClass = "badge-all-good";
            feedbackIcon = IconGood;

            influenceLabel = Rosetta.string("reflection.editor_influence_positive");
            feedbackLabel = Rosetta.string("reflection.editor_feedback_positive");
        } else if (parseInt(reflectionFeedbackTypeId) === ReflectionUtil.feedbackTypes.NEGATIVE) {
            feedbackExtraClass = "badge-improve";
            feedbackIcon = IconImprove;

            influenceLabel = Rosetta.string("reflection.editor_influence_negative");
            feedbackLabel = Rosetta.string("reflection.editor_feedback_negative");
        }

        feedbackIconElem = (
            <div className={"type-icon " + feedbackExtraClass}>
                <div className={"type-icon-icon"} style={{backgroundImage : ImageUtil.background(feedbackIcon)}} />
            </div>
        )
    }

    let influenceElem = [];
    if (influenceLabel !== null) {
        influenceElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label className={"no-bold"}>{influenceLabel}</label>
                    <select className={"form-select"} value={influenceId} onChange={(e) => setInfluenceId(e.target.value)}>
                        {
                            influences.map((influence) => (
                                <option value={influence.id}>{influence.influence}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        )
    }

    let privateFeedbackElem = [];
    if (parseInt(reflectionTypeId) === ReflectionUtil.reflectionTypes.PRIVATE) {
        privateFeedbackElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("reflection.editor_open_feedback_title")}</label>
                    <div>{Rosetta.string("reflection.editor_open_feedback_message")}</div>
                    <textarea className={"form-control"} value={openFeedback} onChange={(e) => setOpenFeedback(e.target.value)} />
                </div>
            </div>
        )
    }

    let submitButton = (<button className={"btn btn-primary full-width"} onClick={() => submitReflectionOverNetwork()}>{Rosetta.string("common.submit")}</button>);
    if (networkInFlight) {
        submitButton = (<LoadingSpinner inline={true} small={true} />);
    }

    let errorElem = [];
    if (error) {
        errorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>
                        {error}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("reflection.editor_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"reflection-editor-modal"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("reflection.editor_date")}</label>
                        <div className={"form-control"}>{Chronos.now().format("dd/MM/yyyy")}</div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <ul className={"list-group reflection-measure"}>
                            <li className={"list-group-item d-flex align-items-center"}>
                                <div className={"number"}>{measureNumber}</div>
                                <div className={"text ms-2 flex-fill"}>{measureTitle}</div>
                            </li>

                            <li className={"list-group-item d-flex align-items-center"}>
                                {feedbackIconElem}
                                <div className={"flex-fill ms-2"}>{patientName}</div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("reflection.editor_type")}</label>
                        <select className={"form-select"} value={reflectionTypeId} onChange={(e) => setReflectionTypeId(e.target.value)}>
                            {reflectionTypes.map((type) => (
                                <option value={type}>{ReflectionUtil.getNameForReflectionType(type)}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {influenceElem}

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label className={"no-bold"}>{feedbackLabel}</label>
                        <textarea className={"form-control"} value={feedback} onChange={(e) => setFeedback(e.target.value)} />
                    </div>
                </div>

                {privateFeedbackElem}

                {errorElem}

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        {submitButton}
                    </div>
                </div>

            </div>

        </Offcanvas>
    )

}