import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {AlertModal} from "../../../alertmodal/AlertModal";
import Rosetta from "../../../../rosetta/Rosetta";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";
import {PasswordValidator, PasswordValidatorActions} from "../../../password/PasswordValidator";
import {Navigator} from "../../../../util/Navigator";

export const ForgottenPasswordResetScreen = (props) => {

    const {token} = useParams();

    const [passwordValidated, setPasswordValidated] = useState(false);
    const [inputPassword, setInputPassword] = useState("");
    const [inputConfirmation, setInputConfirmation] = useState("");

    const [checkNetworkInFlight, setCheckNetworkInFlight] = useState(false);
    const [checkStatus, setCheckStatus] = useState(undefined);

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(undefined);

    useEffect(() => {
        checkResetOverNetwork();
    }, []);

    function navigateOut() {
        Navigator.navigate("/");
    }

    function canSubmit() {
        return !(
            !passwordValidated
            || inputPassword === ""
            || inputConfirmation === ""
            || inputPassword !== inputConfirmation
            || !checkStatus
            || checkNetworkInFlight
            || submitNetworkInFlight
        );
    }

    function passwordDidCallback(action, data) {
        if (action === PasswordValidatorActions.CHANGE) {
            setPasswordValidated(data.validated);
            setInputPassword(data.password);
        }
    }

    function checkResetOverNetwork() {
        if (checkNetworkInFlight) return;
        setCheckNetworkInFlight(true);

        const formData = new FormData();
        formData.append("token", token);

        Axios.post(ENDPOINTS.auth.validatePasswordResetToken, formData)
            .then((r) => {
                const resp = API.parse(r);
                setCheckStatus(resp.success);
                setCheckNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setCheckNetworkInFlight(false);
                AlertModal.showError(API.defaultError("FPC1000C"));
            });
    }

    function submitPasswordOverNetwork() {
        if (submitNetworkInFlight || !canSubmit()) return;
        setSubmitNetworkInFlight(true);

        const formData = new FormData();
        formData.append("token", token);
        formData.append("password", inputPassword);

        Axios.post(ENDPOINTS.auth.submitPasswordReset, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSubmitSuccess(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("FPS1000C"));
            })
    }

    // RENDER

    let mainContent = [];

    if (checkNetworkInFlight) {
        mainContent = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} />
                </div>
            </div>
        );
    } else if (checkStatus) {
        if (!submitSuccess) {
            let submitButton = (<button className={"btn btn-primary disabled"}>{Rosetta.string("common.submit")}</button>);
            if (submitNetworkInFlight) {
                submitButton = (<LoadingSpinner inline={true} small={true} />);
            } else if (canSubmit()) {
                submitButton = (<button className={"btn btn-primary"} onClick={() => submitPasswordOverNetwork()}>{Rosetta.string("common.submit")}</button>);
            }

            /*
            <label>{Rosetta.string("forgotten_password.change_password")}</label>
                        <input type={"password"} className={"form-control"} value={inputPassword} onChange={(e) => setInputPassword(e.target.value)} />
             */

            mainContent = [
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <h2>{Rosetta.string("forgotten_password.change_title")}</h2>
                        {Rosetta.string("forgotten_password.change_message")}
                    </div>
                </div>,

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <PasswordValidator callback={passwordDidCallback} />
                    </div>
                </div>,

                <div className={"row mt-1"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("forgotten_password.change_password_confirm")}</label>
                        <input type={"password"} className={"form-control"} value={inputConfirmation} onChange={(e) => setInputConfirmation(e.target.value)} />
                    </div>
                </div>,

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        {submitButton}
                    </div>
                </div>
            ];
        } else {
            mainContent = [
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h2>{Rosetta.string("forgotten_password.change_success_title")}</h2>
                    </div>

                    <div className={"col-12 text-center mt-2"}>
                        <p>{Rosetta.string("forgotten_password.change_success_message")}</p>
                        <p>{Rosetta.string("forgotten_password.change_success_info")}</p>
                    </div>
                </div>,
                
                <div className={"row mt-2"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn btn-primary"} onClick={() => navigateOut()}>{Rosetta.string("forgotten_password.change_check_fail_return_login")}</button>
                    </div>
                </div>
            ];
        }
    } else {
        mainContent = [
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <h2>{Rosetta.string("forgotten_password.change_check_fail")}</h2>
                    <p>{Rosetta.string("forgotten_password.change_check_fail_message_1")}</p>
                    <p>{Rosetta.string("forgotten_password.change_check_fail_message_2")}</p>
                    <p>{Rosetta.string("forgotten_password.change_check_fail_message_3")}</p>
                </div>
            </div>,

            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-primary"} onClick={() => navigateOut()}>{Rosetta.string("forgotten_password.change_check_fail_return_login")}</button>
                </div>
            </div>
        ];
    }

    return (
        <div className={"app-screen forgotten-password-reset-screen"}>
            <div className={"container"}>
                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-md-10 col-lg-8 col-xl-6"}>

                        <div className={"card mt-4"}>
                            <div className={"card-body"}>

                                {mainContent}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}