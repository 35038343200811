import {NavLink} from "react-router-dom";
import {ImageUtil} from "../../../util/ImageUtil";

import "./CaseRecordSegmentNavigationLink.css";

export const CaseRecordSegmentNavigationLink = (props) => {

    const {startIcon} = props;
    const {endIcon} = props;
    const {title} = props;
    const {label} = props;
    const {to} = props;

    // RENDER

    let startIconElem = [];
    if (startIcon) {
        startIconElem = (
            <div className={"nav-icon"} style={{backgroundImage : ImageUtil.background(startIcon)}} />
        )
    }

    let endIconElem = [];
    if (endIcon) {
        endIconElem = (
            <div className={"nav-icon"} style={{backgroundImage : ImageUtil.background(endIcon)}} />
        )
    }

    return (
        <div className={"list-group case-record-segment-navigation-link"}>
            <NavLink to={to} className={"list-group-item clickable d-flex align-items-center"}>
                {startIconElem}
                <div className={"nav-content"}>
                    <div className={"nav-title"}>{title}</div>
                    <div className={"nav-label"}>{label}</div>
                </div>
                {endIconElem}
            </NavLink>
        </div>
    )

}