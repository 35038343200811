import {ReflectionUtil} from "../../../util/ReflectionUtil";
import {ImageUtil} from "../../../util/ImageUtil";
import IconGood from "../../../assets/images/icon_good.svg";
import IconBad from "../../../assets/images/icon_improve.svg";

import "./ReflectionListItem.css";
import {useEffect, useRef, useState} from "react";
import {DataManager} from "../../../data/DataManager";
import Rosetta from "../../../rosetta/Rosetta";
import {Chronos} from "../../../chronos/Chronos";

export const ReflectionListItem = (props) => {

    const {reflection} = props;
    const {callback} = props;

    const [user, setUser] = useState();

    useEffect(() => {
        setUser(DataManager.getUser());
    }, []);

    function handleCallback() {
        if (callback) {
            callback("click", reflection);
        }
    }

    let icon = [];
    if (parseInt(reflection.reflectionFeedbackTypeId) === ReflectionUtil.feedbackTypes.POSITIVE) {
        icon = (
            <div className={"reflection-icon badge-all-good"}>
                <div className={"reflection-icon-icon"} style={{backgroundImage : ImageUtil.background(IconGood)}} />
            </div>
        );
    } else if (parseInt(reflection.reflectionFeedbackTypeId) === ReflectionUtil.feedbackTypes.NEGATIVE) {
        icon = (
            <div className={"reflection-icon badge-improve"}>
                <div className={"reflection-icon-icon"} style={{backgroundImage : ImageUtil.background(IconBad)}} />
            </div>
        );
    }

    let subtitleElem = [];
    if (reflection.hasOwnProperty("givenName") || reflection.hasOwnProperty("familyName")) {
        let ownReflection = false;
        if (user) {
            ownReflection = parseInt(reflection.userId) === parseInt(user.id);
        }

        let nameElem = [];
        let name = "";
        let badgeClass = "";
        if (ownReflection) {
            name = Rosetta.string("reflection.list_author_self");
            badgeClass = " text-bg-warning";
        } else {
            name = Rosetta.string("common.name_format", {given_name : reflection.givenName, family_name : reflection.familyName});
            badgeClass = " text-bg-dark";
        }
        nameElem = (
            <span className={"badge" + badgeClass}>
                {Rosetta.string("reflection.list_author", { name })}
            </span>
        );

        let summaryText = "";
        if (reflection.openFeedback) {
            summaryText = reflection.openFeedback.substring(0, 50);
        } else if (reflection.privateFeedback) {
            summaryText = reflection.privateFeedback.substring(0, 50);
        }

        subtitleElem = (
            <div>
                {nameElem}
                <div className={"reflection-summary"}>{summaryText}</div>
            </div>
        );
    } else {
        subtitleElem = (<div className={"reflection-text"}>{reflection.measureTitle}</div>);
    }

    let dateElem = [];
    if (reflection.dateCreated) {
        try {
            let formattedDate = Chronos.withTimestampSeconds(reflection.dateCreated).format("dd/MM/yyyy");
            dateElem = (
                <div className={"content-date"}>{formattedDate}</div>
            )
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <li className={"list-group-item clickable reflection-list-item"} onClick={() => handleCallback()}>
            {dateElem}
            <div className={"content-title"}>{reflection.measureTitle}</div>
            <div className={"content-container"}>
                {icon}
                {subtitleElem}
            </div>
        </li>
    )

}