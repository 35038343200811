import "./QIHeaderBar.css";

import logoSmall from "../../assets/images/qinotify_logo_small.png";
import {ImageUtil} from "../../util/ImageUtil";
import {useEffect, useRef, useState} from "react";
import {DataManager} from "../../data/DataManager";
import {ThemeUtil} from "../../util/ThemeUtil";
import {OrganisationUtil} from "../../util/OrganisationUtil";

export const QIHeaderBar = (props) => {

    const [brandImage, setBrandImage] = useState();

    const dataManagerCallback = useRef();

    useEffect(() => {
        dataManagerCallback.current = (key, value) => {
            if (key === DataManager.keys.organisationImages) {
                let newImage = undefined;
                let images = DataManager.getOrganisationImages();
                if (images && images.length > 0) {
                    let firstImage = ThemeUtil.getBrandImage(
                        images,
                        OrganisationUtil.IMAGE_TYPES.LOGO
                    );

                    if (firstImage) {
                        newImage = firstImage.imagePath;
                    }
                }
                setBrandImage(newImage);
            }
        };

        DataManager.addCallback(dataManagerCallback.current);

        return () => {
            DataManager.removeCallback(dataManagerCallback.current);
        }
    }, []);

    // RENDER

    let useImage = logoSmall;
    if (brandImage) {
        useImage = brandImage;
    }

    return (
        <div className={"row qi-header-bar d-none d-md-block"}>
            <div className={"col-12 text-center"}>
                <div className={"header-logo"} style={{backgroundImage : ImageUtil.background(useImage)}} />
            </div>
        </div>
    )

}