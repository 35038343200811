import {BaseModal, BaseModalSizes} from "../../../alertmodal/BaseModal";
import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import WindowUtil from "../../../../util/WindowUtil";

export const ASAGuidanceModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    if (!shown) return [];

    return (
        <BaseModal
            shown={true}
            size={BaseModalSizes.XLARGE}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <h5>{Rosetta.string("asa_guidance.title")}</h5>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    {Rosetta.string("asa_guidance.subtitle")}
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12 text-start"}>
                    <p>{Rosetta.string("asa_guidance.asa_ps_title")}</p>
                    <p>
                        <ol>
                            <li>{Rosetta.string("asa_guidance.asa_ps_item_1")}</li>
                            <li>{Rosetta.string("asa_guidance.asa_ps_item_2")}</li>
                        </ol>
                    </p>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <table>
                        <thead>
                        <tr>
                            <th rowSpan={2}>{Rosetta.string("asa_guidance.table_title_classification")}</th>
                            <th rowSpan={2}>{Rosetta.string("asa_guidance.table_title_definition")}</th>
                            <th>{Rosetta.string("asa_guidance.table_title_examples")}</th>
                        </tr>
                        <tr>
                            <th>{Rosetta.string("asa_guidance.table_title_examples_subtitle")}</th>
                        </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td><strong>{Rosetta.string("asa_guidance.table_asa_1_title")}</strong></td>
                                <td>{Rosetta.string("asa_guidance.table_asa_1_definition")}</td>
                                <td>
                                    <ol>
                                        <li>
                                            {Rosetta.string("asa_guidance.table_asa_1_example_item_1")}
                                            <ul>
                                                <li>{Rosetta.string("asa_guidance.table_asa_1_example_item_1a")}</li>
                                            </ul>
                                        </li>
                                        <li>
                                            {Rosetta.string("asa_guidance.table_asa_1_example_item_2")}
                                        </li>
                                    </ol>
                                </td>
                            </tr>

                            <tr>
                                <td><strong>{Rosetta.string("asa_guidance.table_asa_2_title")}</strong></td>
                                <td>{Rosetta.string("asa_guidance.table_asa_2_definition")}</td>
                                <td>
                                    <ol>
                                        <li>
                                            {Rosetta.string("asa_guidance.table_asa_2_example_item_1")}
                                            <ul>
                                                <li>{Rosetta.string("asa_guidance.table_asa_2_example_item_1a")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_2_example_item_1b")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_2_example_item_1c")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_2_example_item_1d")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_2_example_item_1e")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_2_example_item_1f")}</li>
                                            </ul>
                                        </li>
                                        <li>
                                            {Rosetta.string("asa_guidance.table_asa_1_example_item_2")}
                                            <ul>
                                                <li>{Rosetta.string("asa_guidance.table_asa_2_example_item_2a")}</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </td>
                            </tr>

                            <tr>
                                <td><strong>{Rosetta.string("asa_guidance.table_asa_3_title")}</strong></td>
                                <td>{Rosetta.string("asa_guidance.table_asa_3_definition")}</td>
                                <td>
                                    <ol>
                                        <li>
                                            {Rosetta.string("asa_guidance.table_asa_3_example_item_1")}
                                            <ul>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1a")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1b")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1c")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1d")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1e")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1f")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1g")}</li>
                                            </ul>
                                        </li>
                                        <li>
                                            {Rosetta.string("asa_guidance.table_asa_3_example_item_2")}
                                            <ul>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_2a")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_2b")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_2c")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_2d")}</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </td>
                            </tr>

                            <tr>
                                <td><strong>{Rosetta.string("asa_guidance.table_asa_3_title")}</strong></td>
                                <td>{Rosetta.string("asa_guidance.table_asa_3_definition")}</td>
                                <td>
                                    <ol>
                                        <li>
                                            {Rosetta.string("asa_guidance.table_asa_3_example_item_1")}
                                            <ul>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1a")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1b")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1c")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1d")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1e")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1f")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_1g")}</li>
                                            </ul>
                                        </li>
                                        <li>
                                            {Rosetta.string("asa_guidance.table_asa_3_example_item_2")}
                                            <ul>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_2a")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_2b")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_2c")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_3_example_item_2d")}</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </td>
                            </tr>

                            <tr>
                                <td><strong>{Rosetta.string("asa_guidance.table_asa_4_title")}</strong></td>
                                <td>{Rosetta.string("asa_guidance.table_asa_4_definition")}</td>
                                <td>
                                    <ol>
                                        <li>
                                            {Rosetta.string("asa_guidance.table_asa_4_example_item_1")}
                                            <ul>
                                                <li>{Rosetta.string("asa_guidance.table_asa_4_example_item_1a")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_4_example_item_1b")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_4_example_item_1c")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_4_example_item_1d")}</li>
                                            </ul>
                                        </li>
                                        <li>
                                            {Rosetta.string("asa_guidance.table_asa_4_example_item_2")}
                                            <ul>
                                                <li>{Rosetta.string("asa_guidance.table_asa_4_example_item_2a")}</li>
                                                <li>{Rosetta.string("asa_guidance.table_asa_4_example_item_2b")}</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </td>
                            </tr>

                            <tr>
                                <td><strong>{Rosetta.string("asa_guidance.table_asa_5_title")}</strong></td>
                                <td>{Rosetta.string("asa_guidance.table_asa_5_definition")}</td>
                                <td>
                                    <p>{Rosetta.string("asa_guidance.table_asa_5_example_item_1")}</p>
                                    <p>{Rosetta.string("asa_guidance.table_asa_5_example_item_2")}</p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6 col-xl-4"}>
                    <button className={"btn btn-primary full-width"} onClick={() => setForceDismiss(true)}>{Rosetta.string("common.close")}</button>
                </div>
            </div>

        </BaseModal>
    )

}