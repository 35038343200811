import "./LoginScreen.css";

import {ImageUtil} from "../../../util/ImageUtil";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import {DataManager} from "../../../data/DataManager";

import QiNotifyLogo from "../../../assets/images/qinotify_logo.png";
import WmahsnLogo from "../../../assets/images/hiwm_logo_square.svg";
import EmlapLogo from "../../../assets/images/emlap_logo.png";
import DudleyNHSLogo from "../../../assets/images/dudleynhs.jpg";
import {Chronos} from "../../../chronos/Chronos";
import {EventUtil} from "../../../util/EventUtil";
import {ForgottenPasswordModal} from "./ForgottenPasswordModal";
import {BaseModalActions} from "../../alertmodal/BaseModal";
import {AuthUtil} from "../../../util/AuthUtil";

import iconSecure from "../../../assets/images/icon_encrypted_drop.svg";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {TwoFactorCodeActions, TwoFactorCodeModal} from "./TwoFactorCodeModal";
import {Navigator} from "../../../util/Navigator";
import {CaseRecordUtil} from "../../../util/CaseRecordUtil";

export const LoginScreen = (props) => {

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [forgottenPasswordShown, setForgottenPasswordShown] = useState(false);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [twoFactorModalShown, setTwoFactorModalShown] = useState(false);
    const [twoFactorModalError, setTwoFactorModalError] = useState(null);
    const [twoFactorModalDismiss, setTwoFactorModalDismiss] = useState(false);

    const [showSecureLogin, setShowSecureLogin] = useState(false);
    const [supportsSecureLogin, setSupportsSecureLogin] = useState(false);

    const [failsafeTimeoutTrigger, setFailsafeTimeoutTrigger] = useState(0);
    const failsafeTimeout = useRef();
    const loginRequestToken = useRef();

    useEffect(() => {
        const secureLoginData = DataManager.getSecureLoginData();
        if (secureLoginData) {
            setShowSecureLogin(true);
        }
        // Check to see if browser supports Secure Login now
        AuthUtil.supportsSecureLogin((support) => {
            setSupportsSecureLogin(support);
        });

        CaseRecordUtil.clearCaseRecordCache();
    }, []);

    useEffect(() => {
        if (networkInFlight) {
            setNetworkInFlight(false);
        }
    }, [failsafeTimeoutTrigger]);

    function formDidSubmit(e) {
        EventUtil.cancel(e);

        submitLoginOverNetwork();
    }

    function showForgottenPasswordModal() {
        setForgottenPasswordShown(true);
    }

    function show2FAModal() {
        setTwoFactorModalShown(true);
        setTwoFactorModalError(null);
        setTwoFactorModalDismiss(false);
    }

    function forgottenPasswordDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setForgottenPasswordShown(false);
        }
    }

    function handleLoginResponse(resp, fromSecureLogin) {
        if (fromSecureLogin === undefined) {
            fromSecureLogin = false;
        }

        if (resp.success) {
            if (resp.data.hasOwnProperty("requiresAction")) {
                if (resp.data.requiresAction === true) {
                    console.log("Requires action!");
                    if (resp.data.hasOwnProperty("action") && resp.data.action) {
                        console.log("Action: ", resp.data.action);
                        if (resp.data.action.type === "2fa") {
                            console.log("show 2fa modal");
                            show2FAModal();
                        }
                    }
                }
                return;
            }

            if (!fromSecureLogin) {
                if (supportsSecureLogin) { //  && !showSecureLogin
                    // Show secure login enrollment
                    Navigator.navigate("/secure-login-enroll");
                }
            }

            DataManager.setSessionToken(resp.data.sessionToken);
            DataManager.setUser(resp.data.user);
            if (resp.data.user.hasOwnProperty("theme")) {
                if (resp.data.user.theme !== null) {
                    DataManager.setOrganisationTheme(resp.data.user.theme);
                }
            }
        } else {
            AlertModal.showError(API.formatError(resp));
        }
    }

    function twoFactorModalDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setTwoFactorModalShown(false);
        } else if (action === TwoFactorCodeActions.SUBMIT) {
            submitLoginOverNetwork(data);
        }
    }

    function submitLoginOverNetwork(code) {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let formData = new FormData();
        formData.append("emailAddress", username);
        formData.append("password", password);

        if (code !== undefined) {
            formData.append("code", code);
        }

        const currentToken = Math.random()
        loginRequestToken.current = currentToken;

        clearTimeout(failsafeTimeout.current);
        failsafeTimeout.current = setTimeout(() => {
            setFailsafeTimeoutTrigger(Math.random());
        }, 20000);

        Axios.post(ENDPOINTS.auth.login, formData)
            .then((r) => {
                if (currentToken === loginRequestToken.current) {
                    const resp = API.parse(r);
                    handleLoginResponse(resp);
                    setNetworkInFlight(false);
                }
            })
            .catch((e) => {
                if (currentToken === loginRequestToken.current) {
                    console.log(e);
                    setNetworkInFlight(false);
                    AlertModal.showError(API.defaultError("AUTH1000C"));
                }
            });
    }

    function requestSecureLoginOverNetwork() {
        const secureLoginData = DataManager.getSecureLoginData();
        if (!secureLoginData || !secureLoginData.identifier) return;

        if (networkInFlight) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("identifier", secureLoginData.identifier);

        Axios.post(ENDPOINTS.auth.requestSecureLogin, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    AuthUtil.createSecureLoginResponse(resp.data.options, (result, data) => {
                        if (result) {
                            submitSecureLoginOverNetwork(data);
                        } else {
                            if (data) {
                                AlertModal.showError(data);
                            }
                            setNetworkInFlight(false);
                        }
                    });
                } else {
                    AlertModal.showError(API.formatError(resp));
                    setNetworkInFlight(false);
                }
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("SRL1000C"));
                setNetworkInFlight(false);
            });
    }

    function submitSecureLoginOverNetwork(info) {
        const secureLoginData = DataManager.getSecureLoginData();
        if (!secureLoginData || !secureLoginData.identifier) return;

        const formData = new FormData();
        formData.append("info", info);
        formData.append("identifier", secureLoginData.identifier);

        Axios.post(ENDPOINTS.auth.submitSecureLogin, formData)
            .then((r) => {
                const resp = API.parse(r);
                handleLoginResponse(resp, true);
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("SSL1000C"));
                setNetworkInFlight(false);
            });
    }

    // RENDER

    let submitButton = (<button className={"btn btn-primary full-width"}>{Rosetta.string("login.login")}</button>);
    if (networkInFlight) {
        submitButton = (
            <button className={"btn btn-primary full-width disabled"}>{Rosetta.string("login.login")}</button>
        )
    }

    let secureLoginButton = [];
    if (supportsSecureLogin && showSecureLogin) {
        let secureButton = (
            <button className={"btn btn-outline-light full-width secure-button"} onClick={() => requestSecureLoginOverNetwork()}>
                <span className={"space"} />
                <span className={"icon"} style={{backgroundImage : ImageUtil.background(iconSecure)}} />
                <span>{Rosetta.string("login.webauth_login_submit")}</span>
                <span className={"icon"} />
                <span className={"space"} />
            </button>
        );

        if (networkInFlight) {
            secureButton = (
                <LoadingSpinner inline={true} small={true} />
            );
        }

        secureLoginButton = (
            <div className={"col-12 mt-4"}>
                <div className={"card secure-login-container"}>
                    <div className={"card-body"}>
                        <div className={"row"}>
                            <div className={"col-12 text-center"}>
                                <h5>{Rosetta.string("login.webauth_login_title")}</h5>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 text-center"}>
                                {Rosetta.string("login.webauth_login_description")}
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                {secureButton}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={"login-screen container app-screen animate-screen-content"}>
                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-md-10 col-lg-8 col-xl-6"}>

                        <div className={"row justify-content-center"}>
                            <div className={"col-12 col-md-8"}>
                                <div className={"row justify-content-center"}>
                                    <div className={"col-12"}>
                                        <div className={"ratio ratio-16x9 logo-item"} style={{backgroundImage : ImageUtil.background(QiNotifyLogo)}} />
                                    </div>
                                    <div className={"col-6"}>
                                        <div className={"ratio ratio-16x9 logo-item"} style={{backgroundImage : ImageUtil.background(EmlapLogo)}} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row justify-content-center"}>
                            <div className={"col-12 col-md-8"}>

                                <div className={"card"}>
                                    <div className={"card-body"}>

                                        <div className={"row text-center"}>
                                            <div className={"col-12"}>
                                                <h4>{Rosetta.string("login.welcome_title")}</h4>
                                                {Rosetta.string("login.welcome_subtitle")}
                                            </div>

                                            {secureLoginButton}

                                            <form onSubmit={formDidSubmit}>
                                                <div className={"col-12 mt-2"}>
                                                    <label>{Rosetta.string("login.username")}</label>
                                                    <input type={"text"} className={"form-control"} value={username} onChange={(e) => setUsername(e.target.value)} />
                                                </div>

                                                <div className={"col-12 mt-2"}>
                                                    <label>{Rosetta.string("login.password")}</label>
                                                    <input type={"password"} className={"form-control"} value={password} onChange={(e) => setPassword(e.target.value)} />
                                                </div>

                                                <div className={"col-12 mt-2"}>
                                                    {submitButton}
                                                </div>

                                                <div className={"col-12 mt-2"}>
                                                    <button className={"btn full-width"} onClick={(e) => { EventUtil.cancel(e); showForgottenPasswordModal(); }}>{Rosetta.string("login.forgotten_password")}</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col-12 text-center"}>
                                <span className={"small-logo ratio ratio-16x9"} style={{backgroundImage : ImageUtil.background(DudleyNHSLogo)}} />
                                <div className={"caption"}>{Rosetta.string("common.app_name")}&trade;</div>
                                <div className={"caption"}>{Rosetta.string("login.copyright", { year : Chronos.now().format("yyyy")})}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <ForgottenPasswordModal
                shown={forgottenPasswordShown}
                callback={forgottenPasswordDidCallback} />

            <TwoFactorCodeModal
                shown={twoFactorModalShown}
                networkInFlight={networkInFlight}
                email={username}
                error={twoFactorModalError}
                forceDismiss={twoFactorModalDismiss}
                callback={twoFactorModalDidCallback} />
        </>
    )

}

// <span className={"small-logo ratio ratio-16x9"} style={{backgroundImage : ImageUtil.background(WmahsnLogo)}} />