import {NavigationList} from "./NavigationList";

export const QISideBar = (props) => {

    const {items} = props;

    if (!items || items.length === 0) return [];

    return (
        <NavigationList items={items} />
    );

}