import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import Validator from "../../../util/Validator";
import {Toast} from "../../toast/TokyoToaster";
import WindowUtil from "../../../util/WindowUtil";

export const CaseRecordMissingClinicianModal = (props) => {

    const {shown} = props;
    const {callback} = props;
    const {caseRecordId} = props;

    const [forename, setForename] = useState("");
    const [surname, setSurname] = useState("");
    const [role, setRole] = useState("");

    const [error, setError] = useState(null);
    const [forceDismiss, setForceDismiss] = useState(false);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();

            setForename("");
            setSurname("");
            setRole("");
            setError(null);
            setNetworkInFlight(false);
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function submitClinicianOverNetwork() {
        if (networkInFlight) return;

        const result = Validator.validateCreateFormData({
            caseRecordId, forename, surname, role
        }, [
            Validator.rule("caseRecordId", "string", "", "caseRecordId", true),
            Validator.rule("forename", "string", Rosetta.string("case_record.missing_clinician_forename"), "givenName", false),
            Validator.rule("surname", "string", Rosetta.string("case_record.missing_clinician_surname"), "familyName", false),
            Validator.rule("role", "string", Rosetta.string("case_record.missing_clinician_role"), "role", false)
        ]);

        if (!result.success) {
            setError(result.error);
            return;
        }

        setError(null);
        setNetworkInFlight(true);

        Axios.post(ENDPOINTS.caseRecord.submitMissingClinician, result.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("case_record.missing_clinician_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setForceDismiss(true);
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setError(API.defaultError("MCS1000C"));
                setNetworkInFlight(false);
            });
    }

    // RENDER

    if (!shown) return [];

    let submitButton = (<button className={"btn btn-primary full-width"} onClick={() => submitClinicianOverNetwork()}>{Rosetta.string("common.submit")}</button>);
    if (networkInFlight) {
        submitButton = (<LoadingSpinner inline={true} small={true} />);
    }

    let errorElem = [];
    if (error !== null) {
        errorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>
                        {error}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Offcanvas
            title={Rosetta.string("case_record.missing_clinician_title")}
            shown={true}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    {Rosetta.string("case_record.missing_clinician_message")}
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("case_record.missing_clinician_forename")}</label>
                    <input type={"text"} className={"form-control"} value={forename} onChange={(e) => setForename(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("case_record.missing_clinician_surname")}</label>
                    <input type={"text"} className={"form-control"} value={surname} onChange={(e) => setSurname(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("case_record.missing_clinician_role")}</label>
                    <input type={"text"} className={"form-control"} value={role} onChange={(e) => setRole(e.target.value)} />
                </div>
            </div>

            {errorElem}

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {submitButton}
                </div>
            </div>

        </Offcanvas>
    )

}