import {useEffect, useRef, useState} from "react";
import "./PDFViewerModal.css";
import {PDFDocViewer, PDFDocViewerActions} from "./PDFDocViewer";
import {BaseModalActions} from "../alertmodal/BaseModal";

import actionBack from "../../assets/images/arrow_left.svg";
import actionForward from "../../assets/images/arrow_right.svg";
import actionClose from "../../assets/images/close.svg";
import {ImageUtil} from "../../util/ImageUtil";
import WindowUtil from "../../util/WindowUtil";

export const PDFViewerModal = (props) => {

    const {shown} = props;
    const {title} = props;
    const {url} = props;
    const {callback} = props;

    const [scale, setScale] = useState(1);
    const [initialScale, setInitialScale] = useState(1);
    const [rotation, setRotation] = useState(0);

    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);

    const [forceDismiss, setForceDismiss] = useState(false);

    const dismissTimeout = useRef();

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            setPage(1);
            setForceDismiss(false);
        }
    }, [shown]);

    function dismiss() {
        setForceDismiss(true);
        clearTimeout(dismissTimeout.current);
        dismissTimeout.current = setTimeout(() => {
            clearTimeout(dismissTimeout.current);
            handleCallback(BaseModalActions.CLOSE);
        }, 200);
    }

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function pdfViewerDidCallback(action, data) {
        if (action === PDFDocViewerActions.INITIAL_SCALE) {
            setInitialScale(data);
        } else if (action === PDFDocViewerActions.REPORT_PAGES) {
            setPageCount(data);
        }
    }

    function zoom(direction, amount) {
        if (amount === undefined) {
            amount = 0.2;
        }

        let newScale = scale;
        if (direction === "in") {
            newScale = Math.min(2, newScale + amount);
        } else if (direction === "out") {
            newScale = Math.max(0.2, newScale - amount);
        }
        setScale(newScale);
    }

    function nextPage() {
        let newPage = Math.min(pageCount, page + 1);
        setPage(newPage);
    }

    function prevPage() {
        let newPage = Math.max(1, page - 1);
        setPage(newPage);
    }

    // RENDER

    if (!shown) return [];

    let mainKey = "pdfviewermodal_34883";
    let mainExtraClass = "";
    if (forceDismiss) {
        mainExtraClass = " dismissing";
        mainKey += "_dismissing";
    }

    let useTitle = title;
    if (!title) {
        useTitle = "PDF Viewer";
    }

    return (
        <div className={"pdf-viewer-modal" + mainExtraClass} key={mainKey}>
            <div className={"pdf-viewer-header"}>
                <div className={"pdf-viewer-header-title"}>{useTitle}</div>
                <div className={"pdf-viewer-header-space"} />
                <div className={"pdf-viewer-header-paginator"}>
                    <div className={"paginator-button"} onClick={() => prevPage()} style={{backgroundImage : ImageUtil.background(actionBack)}} />
                    <div className={"paginator-page-display"}>{page}</div>
                    <div className={"paginator-button"} onClick={() => nextPage()} style={{backgroundImage : ImageUtil.background(actionForward)}}/>
                </div>
                <div className={"pdf-viewer-header-space"} />
                <div className={"pdf-viewer-header-action"} style={{backgroundImage : ImageUtil.background(actionClose)}} onClick={() => dismiss()} />
            </div>

            <div className={"pdf-content"}>
                <div className={"pdf-doc-viewer-container"}>
                    <PDFDocViewer
                        url={url}
                        page={page}
                        scale={scale}
                        rotation={rotation}
                        callback={pdfViewerDidCallback} />
                </div>
            </div>
        </div>
    )

}