import "./QINavigationBar.css";
import {ImageUtil} from "../../util/ImageUtil";
import {NavLink} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {NavigationUtil} from "../../util/NavigationUtil";

export const QINavigationBar = (props) => {

    const {items} = props;

    const [sectionId, setSectionId] = useState(null);
    const [badgeCounts, setBadgeCounts] = useState(null);

    const navCallback = useRef();

    useEffect(() => {
        navCallback.current = (action, data) => {
            if (action === NavigationUtil.ACTIONS.SECTION_CHANGE) {
                setSectionId(data);
            } else if (action === NavigationUtil.ACTIONS.BADGE_COUNTS_CHANGE) {
                setBadgeCounts(data);
            }
        };
        NavigationUtil.addCallback(navCallback.current);

        return () => {
            NavigationUtil.removeCallback(navCallback.current);
        }
    }, []);

    function generateItems(items) {
        let out = [];

        // TODO Badge
        items.forEach((item) => {
            let activeClass = "";

            if (item.hasOwnProperty("sectionId")) {
                if (sectionId === parseInt(item.sectionId)) {
                    activeClass = " active";
                }
            }

            let badgeElem = [];
            if (badgeCounts) {
                if (badgeCounts.hasOwnProperty(item.sectionId)) {
                    badgeElem = (
                        <span className={"badge text-bg-danger"}>{badgeCounts[item.sectionId]}</span>
                    )
                }
            }

            out.push(
                <NavLink
                    className={"navigation-item col-4 col-sm-3" + activeClass}
                    to={item.url}>

                    <div className={"spacer"} />
                    <div className={"navigation-item-icon"} style={{backgroundImage : ImageUtil.background(item.icon)}}>
                        {badgeElem}
                    </div>
                    <div className={"navigation-item-title"}>
                        {item.title}
                    </div>
                    <div className={"spacer"} />

                </NavLink>
            );
        });

        return out;
    }

    // RENDER

    let itemElems = [];
    if (items && items.length > 0) {
        if (items[0].hasOwnProperty("items")) {
            itemElems = generateItems(items[0].items);
        }
    }

    return (
        <div className={"qi-navigation-bar d-block d-sm-block d-md-none"}>
            <div className={"container"}>
                <div className={"row align-items-center justify-content-center"}>

                    {itemElems}

                </div>
            </div>
        </div>
    )

}