import React, {useEffect, useState} from "react";
import Axios from "axios";

import indicatorArrow from "../../../../assets/images/icon_list_indicator.svg";
import {API, ENDPOINTS} from "../../../../network/API";
import {EventUtil} from "../../../../util/EventUtil";
import {CommonUtil} from "../../../../util/CommonUtil";
import {ImageUtil} from "../../../../util/ImageUtil";
import {LoadingSpinner} from "../../../loading/LoadingSpinner";
import Rosetta from "../../../../rosetta/Rosetta";

import "./CaseRecordStructure.css"

export const CaseRecordStructureActions = {
    DATA_UPDATED : "caseRecordStructure.dataUpdated",
    SECTION_ITEM_CLICKED : "caseRecordStructure.itemClicked"
};

export const CaseRecordStructure = {
    action : (icon, label, callback) => {
        return {
            icon,
            label,
            callback
        };
    }
}

export const CaseRecordStructureComponent = (props) => {

    const {data} = props;
    const {segmentActions} = props;
    const {sectionActions} = props;
    const {callback} = props;

    const [segments, setSegments] = useState([]);
    const [segmentNetworkInFlight, setSegmentNetworkInFlight] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const [expandedSegmentIds, setExpandedSegmentIds] = useState([]);
    const [expandedSectionIds, setExpandedSectionIds] = useState([]);

    useEffect(() => {
        fetchCaseRecordSegmentFromNetwork();
    }, []);

    useEffect(() => {
        if (data && data !== segments) {
            setSegments(data);
        }
    }, [data]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function toggleSegmentExpanded(segmentId) {
        const expandSids = [...expandedSegmentIds];

        let index = isSegmentExpanded(segmentId, true);
        if (index !== false) {
            expandSids.splice(index, 1);
        } else {
            expandSids.push(segmentId);
        }

        setExpandedSegmentIds(expandSids);
    }

    function isSegmentExpanded(segmentId, returnIndex) {
        if (returnIndex === undefined) {
            returnIndex = false;
        }

        for (let i = 0; i < expandedSegmentIds.length; i++) {
            if (expandedSegmentIds[i] === segmentId) {
                if (returnIndex) {
                    return i;
                }
                return true;
            }
        }

        return false;
    }

    function toggleSectionExpanded(sectionId) {
        const expandSids = [...expandedSectionIds];

        let index = isSectionExpanded(sectionId, true);
        if (index !== false) {
            expandSids.splice(index, 1);
        } else {
            expandSids.push(sectionId);
        }

        setExpandedSectionIds(expandSids);
    }

    function isSectionExpanded(sectionId, returnIndex) {
        if (returnIndex === undefined) {
            returnIndex = false;
        }

        for (let i = 0; i < expandedSectionIds.length; i++) {
            if (expandedSectionIds[i] === sectionId) {
                if (returnIndex) {
                    return i;
                }
                return true;
            }
        }

        return false;
    }

    function fetchCaseRecordSegmentFromNetwork() {
        if (segmentNetworkInFlight) return;
        setSegmentNetworkInFlight(true);

        const formData = new FormData();
        formData.append("templateId", "2");

        Axios.post(ENDPOINTS.caseRecord.getCaseRecordStructure, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSegments(resp.data.segments);

                    handleCallback(
                        CaseRecordStructureActions.DATA_UPDATED,
                        resp.data.segments
                    );
                } else {
                    setErrorMessage(API.formatError(resp));
                }
                setSegmentNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSegmentNetworkInFlight(false);
                setErrorMessage(API.defaultError("CRSF1000"));
            });
    }

    // RENDER

    let mainContent = [];

    if (segmentNetworkInFlight) {
        mainContent = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} small={true} />
                </div>
            </div>
        )
    } else {
        let segmentItems = [];

        if (segments && segments.length > 0) {
            segments.forEach((segment) => {
                let segmentExpanded = isSegmentExpanded(segment.id);

                let sectionItems = [];
                if (segmentExpanded) {
                    if (segment.hasOwnProperty("sections") && segment.sections) {
                        segment.sections.forEach((section) => {
                            if (section.title) {
                                if (section.title.trim() === "@ignore") {
                                    return;
                                }
                            }

                            let sectionExpanded = isSectionExpanded(section.id);

                            let itemElems = [];
                            if (sectionExpanded) {
                                if (section.hasOwnProperty("items") && section.items) {
                                    section.items.forEach((item) => {
                                        let activeBadge = [];
                                        if (item.enabled === 0) {
                                            activeBadge = (
                                                <span className={"badge bg-secondary text-light"}>{Rosetta.string("common.inactive")}</span>
                                            )
                                        }

                                        if (item.title) {
                                            if (item.title.trim() === "@ignore") {
                                                return;
                                            }
                                        }

                                        itemElems.push(
                                            <div className={"list-group-item"} onClick={() => handleCallback(CaseRecordStructureActions.SECTION_ITEM_CLICKED, item)}>
                                                <div className={"segment-content"}>
                                                    <div className={"segment-content-title"}>
                                                        {item.title} {activeBadge}
                                                    </div>

                                                    <div className={"segment-content-actions"}>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    });

                                    if (itemElems.length > 0) {
                                        itemElems = (
                                            <div className={"list-group"}>
                                                {itemElems}
                                            </div>
                                        )
                                    }
                                }
                            }

                            let sectionActionElems = [];
                            if (sectionActions && Array.isArray(sectionActions)) {
                                sectionActions.forEach((action) => {
                                    let icon = CommonUtil.getOrDefault(action, "icon", null);
                                    let label = CommonUtil.getOrDefault(action, "label", "");
                                    let callback = CommonUtil.getOrDefault(action, "callback", null);

                                    let actionStyles = {};
                                    if (icon) {
                                        actionStyles.backgroundImage = ImageUtil.background(icon);
                                    }

                                    sectionActionElems.push(
                                        <button
                                            className={"action"}
                                            style={actionStyles}
                                            onClick={(e) => {
                                                EventUtil.cancel(e);
                                                if (callback) callback(section)
                                            }} />
                                    )
                                });
                            }

                            let activeBadge = [];
                            if (section.enabled === 0) {
                                activeBadge = (
                                    <span className={"badge bg-secondary text-light"}>{Rosetta.string("common.inactive")}</span>
                                )
                            }

                            let extraClass = "";
                            if (sectionExpanded) {
                                extraClass = " expanded";
                            }

                            sectionItems.push(
                                <div className={"list-group-item" + extraClass}>
                                    <div className={"segment-content"}
                                         onClick={() => toggleSectionExpanded(section.id)}>
                                        <div className={"segment-content-title"}>
                                            {section.title} {activeBadge}
                                        </div>

                                        <div className={"segment-content-actions"}>
                                            {sectionActionElems}

                                            <div className={"indicator" + extraClass} style={{backgroundImage : ImageUtil.background(indicatorArrow)}} />
                                        </div>
                                    </div>

                                    {itemElems}
                                </div>
                            );
                        });

                        if (sectionItems.length > 0) {
                            sectionItems = (
                                <div className={"list-group"}>
                                    {sectionItems}
                                </div>
                            );
                        }
                    }
                }

                let segmentActionElems = [];
                if (segmentActions && Array.isArray(segmentActions)) {
                    segmentActions.forEach((action) => {
                        let icon = CommonUtil.getOrDefault(action, "icon", null);
                        let label = CommonUtil.getOrDefault(action, "label", "");
                        let callback = CommonUtil.getOrDefault(action, "callback", null);

                        let actionStyles = {};
                        if (icon) {
                            actionStyles.backgroundImage = ImageUtil.background(icon);
                        }

                        segmentActionElems.push(
                            <button
                                className={"action"}
                                style={actionStyles}
                                onClick={(e) => {
                                    EventUtil.cancel(e);
                                    if (callback) callback(segment)
                                }} />
                        )
                    });
                }

                let activeBadge = [];
                if (segment.enabled === 0) {
                    activeBadge = (
                        <span className={"badge bg-secondary text-light"}>{Rosetta.string("common.inactive")}</span>
                    )
                }

                let segmentExtraClass = "";
                if (segmentExpanded) {
                    segmentExtraClass = " expanded";
                }

                segmentItems.push(
                    <div className={"list-group-item" + segmentExtraClass}>
                        <div className={"segment-content"} onClick={() => toggleSegmentExpanded(segment.id)}>
                            <div className={"segment-content-number"}>
                                {segment.segmentNumber}
                            </div>

                            <div className={"segment-content-title"}>
                                {segment.segmentName} {activeBadge}
                            </div>

                            <div className={"segment-content-actions"}>
                                {segmentActionElems}

                                <div className={"indicator" + segmentExtraClass} style={{backgroundImage : ImageUtil.background(indicatorArrow)}} />
                            </div>
                        </div>

                        {sectionItems}
                    </div>
                )
            });

            if (segmentItems.length > 0) {
                segmentItems = (
                    <div className={"list-group"}>
                        {segmentItems}
                    </div>
                );
            }

            mainContent = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        {segmentItems}
                    </div>
                </div>
            );
        } else {
            mainContent = (
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={"case-record-structure-component"}>
            {mainContent}
        </div>
    )

}