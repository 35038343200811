import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import {useEffect, useRef, useState} from "react";
import WindowUtil from "../../../util/WindowUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import ArrayUtil from "../../../util/ArrayUtil";
import {ImageUtil} from "../../../util/ImageUtil";
import {OrganisationUtil} from "../../../util/OrganisationUtil";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import Rosetta from "../../../rosetta/Rosetta";

import "./OrganisationPickerModal.css";

export const OrganisationPickerModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [organisations, setOrganisations] = useState([]);
    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [shouldFetchMore, setShouldFetchMore] = useState(true);

    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [keyword, setKeyword] = useState("");

    const [forceDismiss, setForceDismiss] = useState(false);

    const callbackData = useRef();

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            fetchOrganisationsFromNetwork();
        } else {
            WindowUtil.unlockBodyScroll();

            setPage(1);
            setOrganisations([]);
            setNetworkInFlight(false);
            setKeyword("");
            setForceDismiss(false);
            setShouldFetchMore(true);
            callbackData.current = undefined;
        }
    }, [shown]);

    useEffect(() => {
        if (shown) {
            fetchOrganisationsFromNetwork();
        }
    }, [page, keyword]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data ? data : callbackData.current);
        }
    }

    function organisationWasSelected(organisation) {
        callbackData.current = organisation;
        setForceDismiss(true);
    }

    function fetchOrganisationsFromNetwork() {
        if (networkInFlight || !shouldFetchMore) return;
        setNetworkInFlight(true);

        const formData = new FormData();
        formData.append("page", page);
        formData.append("pageSize", pageSize);
        if (keyword !== "") {
            formData.append("keyword", keyword);
        }

        Axios.post(ENDPOINTS.auth.getOrganisations, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let newOrganisations = ArrayUtil.appendArray(
                        [...organisations],
                        resp.data.data,
                        true
                    );

                    setOrganisations(newOrganisations);

                    if (resp.data.data.length < pageSize) {
                        setShouldFetchMore(false);
                    }
                } else {
                    console.log(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("OLF1000C"));
            });
    }

    // RENDER

    if (!shown) return[];

    let organisationElems = [];

    if (organisations && organisations.length > 0) {
        organisations.forEach((organisation) => {
            let iconUrl = null;
            let organisationImage = OrganisationUtil.getImage(organisation, OrganisationUtil.IMAGE_TYPES.LOGO);
            if (organisationImage) {
                iconUrl = organisationImage.imagePath;
            }

            organisationElems.push(
                <li className={"list-group-item organisation-item clickable"} onClick={() => organisationWasSelected(organisation)}>
                    <div className={"icon"} style={{ backgroundImage : ImageUtil.background(iconUrl) }} />
                    <div className={"content"}>
                        <strong>{organisation.name}</strong>
                    </div>
                </li>
            )
        });
    }

    if (networkInFlight) {
        organisationElems.push(
            <li className={"list-group-item text-center"}>
                <LoadingSpinner inline={true} small={true} />
            </li>
        )
    } else if (shouldFetchMore) {
        organisationElems.push(
            <li className={"list-group-item text-center"}>
                <button className={"btn btn-primary"} onClick={() => setPage(page + 1)}>
                    {Rosetta.string("common.load_more")}
                </button>
            </li>
        )
    }

    if (organisationElems.length === 0) {
        organisationElems.push(
            <li className={"list-group-item text-center"}>
                <em>{Rosetta.string("common.empty_message")}</em>
            </li>
        )
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("organisation.list_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"organisation-picker-modal"}>

                <div className={"row"}>
                    <div className={"col-12"}>

                        <ul className={"list-group"}>

                            {organisationElems}

                        </ul>

                    </div>
                </div>

            </div>

        </Offcanvas>
    )

}