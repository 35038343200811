import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";

import "../case-record/CaseRecordFilterModal.css";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import WindowUtil from "../../../util/WindowUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {FilterText} from "../../filter/FilterText";
import {ReflectionUtil} from "../../../util/ReflectionUtil";

export const ReflectionFilterModal = (props) => {

    const {shown} = props;
    const {data} = props;
    const {callback} = props;

    const [patients, setPatients] = useState([]);
    const [patientNetworkInFlight, setPatientNetworkInFlight] = useState(false);

    const [patientId, setPatientId] = useState(-1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [typeId, setTypeId] = useState(-1);

    const [patientKeyword, setPatientKeyword] = useState("");

    const outData = useRef();

    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            fetchPatientsFromNetwork();
            outData.current = null;

            if (data) {
                setPatientId(data.patientId);
                setStartDate(data.startDate);
                setEndDate(data.endDate);
            }
        } else {
            WindowUtil.unlockBodyScroll();

            setPatients([]);
            setPatientId(-1);
            setStartDate(null);
            setEndDate(null);
            setTypeId(-1);
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, outData.current);
        }
    }

    function submitForm() {
        const out = {};

        if (patientId !== null && parseInt(patientId) > 0) {
            out.patientId = patientId;
        }

        if (startDate !== null && startDate !== "") {
            out.startDate = startDate;
        }

        if (endDate !== null && endDate !== "") {
            out.endDate = endDate;
        }

        if (typeId && parseInt(typeId) > 0) {
            out.typeId = typeId;
        }

        outData.current = out;

        setForceDismiss(true);
    }

    function fetchPatientsFromNetwork() {
        if (patientNetworkInFlight) return;
        setPatientNetworkInFlight(true);

        const formData = new FormData();
        formData.append("departmentId", 1); // TODO

        Axios.post(ENDPOINTS.patient.getPatients, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPatients(resp.data.patients);
                } else {
                    console.log(API.formatError(resp));
                }
                setPatientNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setPatientNetworkInFlight(false);
            })
    }

    // RENDER
    if (!shown) return [];

    let patientListItems = [];
    if (!patientNetworkInFlight) {
        patients.forEach((patient) => {
            if (!patientKeyword || patientKeyword.trim() === "" || patient.name.toLowerCase().includes(patientKeyword.toLowerCase())) {
                let selectedTag = [];
                if (patient.id === patientId) {
                    selectedTag = (
                        <span className={"badge text-bg-primary"}>{Rosetta.string("common.selected")}</span>
                    )
                }

                patientListItems.push(
                    <li className={"list-group-item clickable d-flex align-items-center"} onClick={() => setPatientId(patient.id)}>
                        <span className={"flex-fill"}>{patient.name}</span>
                        {selectedTag}
                    </li>
                )
            }
        });
    } else {
        patientListItems = (
            <div className={"text-center"}>
                <LoadingSpinner inline={true} small={true} />
            </div>
        )
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("reflection.filter_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"case-record-filter-modal"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("reflection.filter_patient")}</label>
                    </div>

                    <div className={"col-12"}>
                        <FilterText callback={(s) => setPatientKeyword(s)} />
                    </div>

                    <div className={"col-12"}>
                        <ul className={"list-group"}>
                            {patientListItems}
                        </ul>
                    </div>

                    <div className={"col-12"}>
                        <button className={"btn btn-primary full-width mt-2"} onClick={() => setPatientId(-1)}>{Rosetta.string("reflection.filter_patient_clear")}</button>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("reflection.filter_date")}</label>
                    </div>

                    <div className={"col-6"}>
                        <input type={"date"} className={"form-control"} value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>

                    <div className={"col-6"}>
                        <input type={"date"} className={"form-control"} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>

                    <div className={"col-12 mt-2"}>
                        <button className={"btn btn-primary full-width"} onClick={() => { setStartDate(""); setEndDate(""); }}>{Rosetta.string("reflection.filter_date_clear")}</button>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("reflection.filter_type")}</label>
                        <select className={"form-select"} value={"typeId"} onChange={(e) => setTypeId(e.target.value)}>
                            <option value={"-1"}>{Rosetta.string("reflection.filter_type_all")}</option>
                            <option value={ReflectionUtil.reflectionTypes.OPEN}>{Rosetta.string("reflection.detail_title_public")}</option>
                            <option value={ReflectionUtil.reflectionTypes.PRIVATE}>{Rosetta.string("reflection.detail_title_private")}</option>
                        </select>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <button className={"btn btn-primary full-width"} onClick={() => submitForm()}>{Rosetta.string("reflection.filter_apply")}</button>
                    </div>
                </div>

            </div>

        </Offcanvas>
    )

}