export const ColourUtil = {
    /**
     * Determines if a given colour value is considered "light" or not
     * @param colour        Either a hexadecimal or rgb/rgba colour value
     * @returns {boolean}   If the colour is light or not. Returns `false` if undetermined.
     */
    isLight : (colour) => {
        if (colour.startsWith("#")) {
            return ColourUtil.isHexLight(colour);
        } else if (colour.startsWith("rgb")) {
            return ColourUtil.isRGBLight(colour);
        }
        return false;
    },
    /**
     * Determines if a given hexadecimal colour value is considered "light"
     * @param hexValue      Standard 9 digit hex value with or without leading #
     * @returns {boolean}   If the colour is light or not
     */
    isHexLight : (hexValue) => {
        const hex = hexValue.replace('#', '');
        const redComponent = parseInt(hex.substring(0, 2), 16);
        const greenComponent = parseInt(hex.substring(2, 2 + 2), 16);
        const blueComponent = parseInt(hex.substring(4, 4 + 2), 16);
        const brightness = ((redComponent * 299) + (greenComponent * 587) + (blueComponent * 114)) / 1000;
        return brightness > 155;
    },
    /**
     * Determines if a given RGB/RGBA colur value is considered "light".
     *
     * CAUTION: This method does not consider transparency. If you provide an RGBA value, the alpha is discarded.
     * This method CANNOT determine what colour will be behind a given transparent colour.
     *
     * @param rgbValue      Standard RGB or RGBA value with or without enclosure
     * @returns {boolean}   If the colour is light or not
     */
    isRGBLight : (rgbValue) => {
        rgbValue = rgbValue.replace("rgb(", "");
        rgbValue = rgbValue.replace("rgba(", "");
        rgbValue = rgbValue.replace(")", "");

        const values = rgbValue.split(",");

        const brightness = ((values[0] * 299) + (values[1] * 587) + (values[2] * 114)) / 1000;
        return brightness > 155;
    }
}