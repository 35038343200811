import {Chronos} from "../chronos/Chronos";
import {TimeUtil} from "./TimeUtil";

export const DateUtil = {
    secondsToDateString : (seconds) => {
        if (seconds) {
            try {
                return Chronos.withTimestampSeconds(seconds).format("yyyy-MM-dd");
            } catch (e) {
                console.log(e);
            }
        }
        return null;
    },
    secondStringToDate : (string) => {
        let timeSplit = string.split(":");

        let units = [
            TimeUtil.HOUR,
            TimeUtil.MINUTE,
            1
        ];

        let out = 0;

        for (let i = 0; i < timeSplit.length; i++) {
            out += units[i] * timeSplit[i];
        }

        return out;
    }
}