import {ScreenTitle, ScreenTitleAction} from "../../screenTitle/ScreenTitle";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import {CaseRecordUtil} from "../../../util/CaseRecordUtil";
import Rosetta from "../../../rosetta/Rosetta";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {CommonUtil} from "../../../util/CommonUtil";
import {ColourUtil} from "../../../util/ColourUtil";
import {ImageUtil} from "../../../util/ImageUtil";

import "./CaseRecordLandingScreen.css";

import IndicatorIcon from "../../../assets/images/icon_list_indicator.svg";
import IconGood from "../../../assets/images/icon_good.svg";
import IconImprove from "../../../assets/images/icon_improve.svg";
import {Navigator} from "../../../util/Navigator";
import {Toast} from "../../toast/TokyoToaster";
import {DataManager} from "../../../data/DataManager";
import {NavigationUtil} from "../../../util/NavigationUtil";
import {CaseRecordReflectionsModal} from "./CaseRecordReflectionsModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";
import {CaseRecordPublicationHistoryModal} from "./CaseRecordPublicationHistoryModal";

import ReloadIcon from "../../../assets/images/refresh.svg";

export const CaseRecordLandingScreen = (props) => {

    const {departmentId} = useParams();
    const {caseRecordId} = useParams();

    const [caseRecord, setCaseRecord] = useState(null);
    const [caseRecordNetworkInFlight, setCaseRecordNetworkInFlight] = useState(false);

    const [exportNetworkInFlight, setExportNetworkInFlight] = useState(false);

    const [reflectionsShown, setReflectionsShown] = useState(false);

    const [historyModalShown, setHistoryModalShown] = useState(false);

    useEffect(() => {
        NavigationUtil.setSection(NavigationUtil.SECTIONS.PATIENTS);

        Navigator.scrollTop();

        if (caseRecordId) {
            let caseRecordCache = CaseRecordUtil.findCaseRecordInCache(caseRecordId);
            if (caseRecordCache) {
                console.log("Loading Case Record from memory cache...");
                // If we have a cached case record in memory, use that now.
                setCaseRecord(caseRecordCache);
            } else {
                // Otherwise, call the network to fetch the record
                getCaseRecordFromNetwork();
            }
        }
    }, []);

    function moveToSegment(segment) {
        Navigator.navigate("/caseRecordSegment/" + departmentId + "/" + caseRecordId + "/" + segment.id);
    }

    function moveToSummary(outcomeTypeId) {
        Navigator.navigate("/caseRecordOverview/" + departmentId + "/" + caseRecordId + "/" + outcomeTypeId);
    }

    function moveToPeople() {
        Navigator.navigate("/caseRecordPeople/" + departmentId + "/" + caseRecordId);
    }

    function showReflectionsModal() {
        setReflectionsShown(true);
    }

    function reflectionsDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setTimeout(() => {
                    Navigator.navigate("/reflection/" + data.id);
                }, 250);
            }

            setReflectionsShown(false);
        }
    }

    function summonHistoryModal() {
        setHistoryModalShown(true);
    }

    function historyModalDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setHistoryModalShown(false);
        }
    }

    function getCaseRecordFromNetwork() {
        if (caseRecordNetworkInFlight) return;
        setCaseRecordNetworkInFlight(true);

        const formData = new FormData();
        formData.append("departmentId", departmentId);
        formData.append("caseRecordId", caseRecordId);

        Axios.post(ENDPOINTS.caseRecord.getCaseRecord, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    const caseRecord = resp.data.caseRecord;
                    CaseRecordUtil.putCaseRecordIntoCache(caseRecord);
                    setCaseRecord(caseRecord);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setCaseRecordNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setCaseRecordNetworkInFlight(false);
                AlertModal.showError(API.defaultError("CRF1000C"));
            });
    }

    function fetchExportFromNetwork() {
        if (exportNetworkInFlight) return;
        setExportNetworkInFlight(true);

        const formData = new FormData();
        formData.append("caseRecordId", caseRecordId);

        Axios.post(ENDPOINTS.caseRecord.generateCaseRecordExport, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let authToken = DataManager.getSessionToken();
                    window.location.href = resp.data.url + "?at=" + authToken;

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("case_record.export_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setExportNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setExportNetworkInFlight(false);
                AlertModal.showError(API.defaultError("CRE1000C"));
            })
    }

    // RENDER

    let mainContent = [];

    if (!caseRecord || caseRecordNetworkInFlight) {
        mainContent = (
            <div className={"row mt-4 justify-content-center animate-screen-content"}>
                <div className={"col-12 col-md-10 col-lg-8 text-center"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div><strong>{Rosetta.string("common.please_wait")}</strong></div>
                            <div>{Rosetta.string("case_record.downloading_message")}</div>
                            <div>
                                <LoadingSpinner inline={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        let recordDate = caseRecord.recordDate;
        if (caseRecord.datePublished !== null) {
            recordDate = caseRecord.datePublished;
        }

        let caseRecordPublicationsButton = [];
        if (caseRecord.caseRecordPublications !== undefined && caseRecord.caseRecordPublications.length > 0) {
            caseRecordPublicationsButton = (
                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <button className={"btn btn-primary full-width"} onClick={() => summonHistoryModal()}>
                            {Rosetta.string("case_record.detail_publication_history")}
                        </button>
                    </div>
                </div>
            )
        }

        mainContent = (
            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>
                    <div className={"col-12"}>

                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row detail-header"}>
                                    <div className={"col-12 col-lg-6 mb-2"}>
                                        <h3>{CommonUtil.getOrDefault(caseRecord.patient, "name", "---")}</h3>
                                        <div>{CaseRecordUtil.formatTimeDisplay(recordDate, "case_record.list_item_updated")}</div>
                                        <div className={"mt-2"}><strong><small>{Rosetta.string("case_record.case_record_type")}</small></strong></div>
                                        <div className={"badge-row"}>
                                            <div className={"badge badge-status"}>{caseRecord.recordStageId} - {caseRecord.recordStage}</div>
                                        </div>

                                        {caseRecordPublicationsButton}
                                    </div>

                                    <div className={"col-12 col-lg-6"}>

                                        <div className={"card detail-card"}>
                                            <div className={"card-body"}>

                                                <div className={"row"}>
                                                    <div className={"col-6"}>
                                                        <div><strong>{Rosetta.string("case_record.detail_patient_age")}</strong></div>
                                                        <div>{caseRecord.patient.age}</div>
                                                    </div>

                                                    <div className={"col-6"}>
                                                        <div><strong>{Rosetta.string("case_record.detail_patient_sex")}</strong></div>
                                                        <div>{caseRecord.patient.sex}</div>
                                                    </div>
                                                </div>

                                                <div className={"row"}>
                                                    <div className={"col-6"}>
                                                        <div><strong>{Rosetta.string("case_record.detail_patient_nela_id")}</strong></div>
                                                        <div>{caseRecord.recordId}</div>
                                                    </div>

                                                    <div className={"col-6"}>
                                                        <div><strong>{Rosetta.string("case_record.detail_patient_nhs_number")}</strong></div>
                                                        <div>{caseRecord.patient.NHSNumber}</div>
                                                    </div>
                                                </div>

                                                <div className={"row"}>
                                                    <div className={"col-6"}>
                                                        <div><strong>{Rosetta.string("case_record.detail_patient_admission_date")}</strong></div>
                                                        <div>{caseRecord.patient.admissionDate ? caseRecord.patient.admissionDate : "---"}</div>
                                                    </div>

                                                    <div className={"col-6"}>
                                                        <div><strong>{Rosetta.string("case_record.detail_patient_surgery_date")}</strong></div>
                                                        <div>{caseRecord.patient.surgeryDate ? caseRecord.patient.surgeryDate : "---"}</div>
                                                    </div>
                                                </div>

                                                <div className={"row"}>

                                                    <div className={"col-6"}>
                                                        <div><strong>{Rosetta.string("case_record.detail_patient_discharge_date")}</strong></div>
                                                        <div>{caseRecord.patient.dischargeDate ? caseRecord.patient.dischargeDate : "---"}</div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-lg-12 col-xl-8"}>
                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <strong>{Rosetta.string("case_record.detail_summarised_results")}</strong>
                                    </div>
                                </div>

                                <div className={"row mt-2 align-items-center"}>
                                    <div className={"col-12 col-lg-6 mb-2 mb-lg-0"}>
                                        <button className={"btn badge-all-good text-light fw-bold full-width"} onClick={() => moveToSummary(CaseRecordUtil.itemOutcomeTypes.POSITIVE)}>{Rosetta.string("case_record.list_item_all_good", { count : caseRecord.positiveResults })}</button>
                                    </div>
                                    <div className={"col-12 col-lg-6"}>
                                        <button className={"btn badge-improve text-light fw-bold full-width"} onClick={() => moveToSummary(CaseRecordUtil.itemOutcomeTypes.NEGATIVE)}>{Rosetta.string("case_record.list_item_improvement", { count : caseRecord.negativeResults })}</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-lg-6"}>
                        <button className={"btn btn-primary full-width"} onClick={() => moveToPeople()}>
                            {Rosetta.string("case_record.detail_people_involved", { count : (caseRecord.associatedPeople) ? caseRecord.associatedPeople.length : 0 }, (caseRecord.associatedPeople) ? caseRecord.associatedPeople.length : 0)}
                        </button>
                    </div>
                </div>

                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-lg-10 col-xl-8"}>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <strong>{Rosetta.string("case_record.detail_report_segments")}</strong>
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <ul className={"list-group"}>
                                {
                                    (caseRecord.segments ? caseRecord.segments : []).map((segment) => {
                                        let textClass = "";
                                        if (!ColourUtil.isLight(segment.primaryColour)) {
                                            textClass = " dark";
                                        }

                                        let subtitleElem = [];
                                        // if (segment.segmentSubtitle) {
                                        //     subtitleElem = (<div>{segment.segmentSubtitle}</div>);
                                        // }

                                        return (
                                            <li className={"list-group-item clickable case-record-segment"} onClick={() => moveToSegment(segment)}>
                                                <span className={"number-tag"} style={{backgroundColor : segment.primaryColour}}>
                                                    <span className={"text" + textClass}>{segment.segmentNumber}</span>
                                                </span>
                                                <span className={"segment-content"}>
                                                    <div className={"segment-title"}>{segment.segmentName}</div>
                                                    {subtitleElem}

                                                    <div className={"badge-row mt-1"}>
                                                        <span className={"badge badge-all-good me-1"}>
                                                            <span className={"badge-icon"} style={{backgroundImage : ImageUtil.background(IconGood) }} />
                                                            {segment.positiveCount}
                                                        </span>

                                                        <span className={"badge badge-improve"}>
                                                            <span className={"badge-icon"} style={{backgroundImage : ImageUtil.background(IconImprove) }} />
                                                            {segment.negativeCount}
                                                        </span>
                                                    </div>
                                                </span>
                                                <div className={"indicator"} style={{backgroundImage : ImageUtil.background(IndicatorIcon)}} />
                                            </li>
                                        )
                                    })
                                }
                                </ul>
                            </div>
                        </div>

                        <div className={"row mt-4 mb-4 align-items-center"}>
                            <div className={"col-12 mb-2"}>
                                <button className={"btn btn-primary full-width"} onClick={() => fetchExportFromNetwork()}>{Rosetta.string(!exportNetworkInFlight ? "case_record.detail_download_case_record" : "common.please_wait")}</button>
                            </div>

                            <div className={"col-12"}>
                                <button className={"btn btn-primary full-width"} onClick={() => showReflectionsModal()}>{Rosetta.string("case_record.detail_view_reflections")}</button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        )
    }

    return (
        <div className={"case-record-landing-screen app-screen"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle
                        title={Rosetta.string("case_record.detail_title")}
                        action={ScreenTitleAction.create(
                            ReloadIcon,
                            Rosetta.string("common.refresh"),
                            () => {
                                getCaseRecordFromNetwork();
                            }
                        )}/>
                </div>
            </div>

            {mainContent}

            <CaseRecordReflectionsModal
                shown={reflectionsShown}
                caseRecordId={caseRecordId}
                callback={reflectionsDidCallback} />

            <CaseRecordPublicationHistoryModal
                shown={historyModalShown}
                publications={caseRecord ? caseRecord.caseRecordPublications : []}
                callback={historyModalDidCallback} />

        </div>
    )

}